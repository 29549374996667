import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-number-column-control',
  template: `{{ formatted() }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberColumnControlComponent {
  @Input()
  value!: number;

  @Input()
  precision = 2;

  formatted() {
    var numValue = Number(this.value);
    return isNaN(numValue) || Math.trunc(numValue) === numValue
      ? this.value
      : numValue.toFixed(this.precision);
  }
}
