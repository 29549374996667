import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { TextBoxComponent } from '@progress/kendo-angular-inputs';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-text-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <kendo-textbox
        [formControl]="control()"
        autofocus="autofocus ?? false"
        #field
      />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class TextFieldComponent
  extends BaseFieldComponent
  implements AfterViewInit
{
  @ViewChild(TextBoxComponent, { static: false })
  public textBox!: TextBoxComponent;

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.textBox.focus();
    }
  }
}
