<input
  #checkbox
  #inner
  [disabled]="isDisabled()"
  type="checkbox"
  kendoCheckBox
  [checked]="value()"
  (change)="onChange($event)"
/>

<kendo-svg-icon
  class="icon"
  [icon]="icons.warning"
  *ngIf="isDisabled()"
  kendoTooltip
  [title]="disabledTitle()"
  >
</kendo-svg-icon>