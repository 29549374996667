import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  inject,
} from '@angular/core';

import { Store } from '@ngrx/store';

import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { WithEntityStatusCommon } from '@/api';
import { selectCurrentUserLogin } from '@/auth';

import { saveButtonStatus } from './save-button-status';

@Component({
  selector: 'app-save-draft-buttons',
  template: `
    <button
      kendoButton
      class="enable-tooltip"
      themeColor="primary"
      kendoTooltip
      (click)="approve.emit()"
      [disabled]="approveButtonStatus().disabled"
      [title]="approveButtonStatus().title"
      *ngIf="showApproveButton()"
      [iconClass]="loading ? 'k-icon k-i-loading' : ''"
    >
      Approve {{ draftKind() }} draft
    </button>

    <button
      kendoButton
      class="enable-tooltip"
      themeColor="primary"
      kendoTooltip
      (click)="save.emit()"
      [disabled]="saveButtonStatus().disabled"
      [title]="saveButtonStatus().title"
      [iconClass]="loading ? 'k-icon k-i-loading' : ''"
    >
      @if (isDraft()) { Save draft } @else { Create draft }
    </button>
  `,
  styles: `
    :host
      display: flex
      gap: 8px
      flex: 1
      &.with-approve
        flex: 1.5
    
    button
      flex: 1

    .enable-tooltip:disabled
      pointer-events: auto
  `,
})
export class ApproveSaveDraftButtonsComponent {
  @Input()
  entity: WithEntityStatusCommon | undefined;

  @Input()
  loading = true;

  @Output()
  approve = new EventEmitter<void>();

  @Output()
  save = new EventEmitter<void>();

  userLogin = inject(Store).selectSignal(selectCurrentUserLogin);

  formGroup = inject(ControlContainer) as FormGroupDirective;

  form = this.formGroup.form;

  changeDetectorRef = inject(ChangeDetectorRef);
  destroyRef = inject(DestroyRef);

  constructor() {
    const sub = this.form.statusChanges.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    this.destroyRef.onDestroy(() => sub.unsubscribe());
  }

  @HostBinding('class.with-approve') get class() {
    return this.showApproveButton();
  }

  isDraft() {
    return this.entity && !this.entity.common.isApproved;
  }

  draftKind() {
    return (this.form.value as WithEntityStatusCommon).common?.isClosed
      ? 'close (remove)'
      : '';
  }

  showApproveButton() {
    return this.isDraft();
  }

  approveButtonStatus() {
    const author = this.entity?.common.createdBy;

    if (author === this.userLogin()) {
      return {
        disabled: true,
        title: `This draft is created by 
        ${author}
         and can't be approved by author, only by other user`,
      };
    }

    return saveButtonStatus(this.form, false, 'Approving is disabled: ');
  }

  saveButtonStatus() {
    const prefix = this.isDraft()
      ? 'Draft saving is disabled: '
      : 'Draft creation is disabled: ';

    return saveButtonStatus(this.form, true, prefix);
  }
}
