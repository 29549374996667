import { EnumList } from '@/common';

export enum MethodOfSolicitation {
  COMPANY_OWN_MARKETING_ACTIVITIES = 'COMPANY_OWN_MARKETING_ACTIVITIES',
  RELATED_ENTITIES = 'RELATED_ENTITIES',
  TIED_AGENTS = 'TIED_AGENTS',
  BRANCHES = 'BRANCHES',
  AFFILIATES = 'AFFILIATES',
  INTRODUCING_BROKERS = 'INTRODUCING_BROKERS',
  OUTSOURCED_SERVICE_PROVIDERS = 'OUTSOURCED_SERVICE_PROVIDERS',
  OTHER = 'OTHER',
}

export const METHOD_OF_SOLICITATION_LIST: EnumList = [
  {
    title: "Company's own marketing activities",
    value: MethodOfSolicitation.COMPANY_OWN_MARKETING_ACTIVITIES,
  },
  {
    title: 'Related entities',
    value: MethodOfSolicitation.RELATED_ENTITIES,
  },
  {
    title: 'Tied agents',
    value: MethodOfSolicitation.TIED_AGENTS,
  },
  {
    title: 'Branches',
    value: MethodOfSolicitation.BRANCHES,
  },
  {
    title: 'Affiliates',
    value: MethodOfSolicitation.AFFILIATES,
  },
  {
    title: 'Introducing brokers',
    value: MethodOfSolicitation.INTRODUCING_BROKERS,
  },
  {
    title: 'Outsourced service providers',
    value: MethodOfSolicitation.OUTSOURCED_SERVICE_PROVIDERS,
  },
  {
    title: 'Other',
    value: MethodOfSolicitation.OTHER,
  },
];
