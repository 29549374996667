import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';

import { TreeListComponent } from '@progress/kendo-angular-treelist';

import { signal } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import {
  CollectionMutations,
  DraftedCollectionMutations,
  EntityConstraint,
  EntityWithApproveConstraint,
} from '../utils/entity-collection-mutations';
import { assertDefined } from '../utils';

export function injectEditHelper<
  Entity extends EntityConstraint,
  AddVariables,
  UpdateVariables extends EntityConstraint,
  TControl extends {
    [K in keyof TControl]: AbstractControl<any>;
  },
>(params: {
  grid: () => GridComponent | TreeListComponent;
  mutations: CollectionMutations<Entity, AddVariables, UpdateVariables>;
  form: FormGroup<TControl>;
  editMode?: 'inline' | 'dialog' | 'none';
  draftMode?: boolean;
}) {
  assertDefined(params.form);

  const editMode = params.editMode ?? 'inline';
  const inlineEditMode = editMode === 'inline';

  const initialFormValue = { ...params.form.value };

  let editingArgs: EditEvent | AddEvent | undefined = undefined;

  const editDialogShown = signal(false);
  const removeDialogShown = signal(false);
  const removeDialogItem = signal<Entity | undefined>(undefined);

  const clearEditedItem = () => {
    editingArgs = undefined;
  };

  const closeEditor = () => {
    // close the editor
    if (editingArgs) {
      params.grid().closeRow(editingArgs?.rowIndex, editingArgs?.isNew);
    }
    clearEditedItem();
  };

  const addHandler = (args: AddEvent) => {
    if (inlineEditMode) {
      closeEditor();
    }

    editingArgs = {
      ...args,
      isNew: true,
    };

    params.form.reset(initialFormValue);

    if (inlineEditMode) {
      args.sender.addRow(params.form);
    } else {
      openEditDialog();
    }
  };

  const editHandler = (args: EditEvent) => {
    if (inlineEditMode) {
      closeEditor();
    }

    editingArgs = {
      ...args,
      isNew: false,
    };

    params.form.reset(initialFormValue);
    if (!args.isNew) {
      params.form.reset(args.dataItem);
    }

    if (inlineEditMode) {
      args.sender.editRow(args.rowIndex, params.form);
    } else {
      openEditDialog();
    }
  };

  const cancelHandler = (_args: CancelEvent) => {
    closeEditor();
  };

  const saveForm = (formData: any, dataItem: any, isNew: boolean) => {
    const data: any = formData;

    if (isNew) {
      params.mutations.add.mutate(data);
    } else if (params.draftMode) {
      if (dataItem.common.isApproved) {
        params.mutations.add.mutate({
          ...data,
          id: null,
          approveFor: dataItem.id,
          common: {
            ...data.common,
            isApproved: false,
          },
        } as any);
      } else {
        params.mutations.update.mutate({
          ...data,
          approveFor: dataItem.approveFor,
          common: {
            ...data.common,
            isApproved: false,
          },
          id: dataItem.id,
        } as UpdateVariables);
      }
    } else {
      params.mutations.update.mutate({
        ...data,
        id: dataItem.id,
      } as UpdateVariables);
    }
  };

  const saveHandler = ({ formGroup, dataItem, isNew }: SaveEvent) => {
    saveForm(formGroup.value, dataItem, isNew);

    if (inlineEditMode) {
      closeEditor();
    }
  };

  const removeHandler = (args: RemoveEvent) => {
    removeDialogShown.set(true);
    removeDialogItem.set(args.dataItem);
  };

  const openEditDialog = () => {
    editDialogShown.set(true);
  };

  const cancelEditDialog = () => {
    editDialogShown.set(false);
    clearEditedItem();
  };

  const commitEditDialog = (values: any) => {
    saveForm(values, editingArgs?.dataItem, editingArgs?.isNew ?? false);
  };

  const approveEditDialog = (values: any) => {
    //TODO: types
    (
      params.mutations as unknown as DraftedCollectionMutations<EntityWithApproveConstraint>
    ).approve.mutate(values);
  };

  const controls = () => {
    return params.form.controls;
  };

  const control = <K extends keyof TControl>(col: K) => {
    const controls: any = params.form.controls;
    return controls[col];
  };

  const error = (controlName: keyof TControl, errorCode: string) => {
    return control(controlName).errors?.[errorCode] ?? '';
  };

  const errors = (controlName: keyof TControl) => {
    const errs = control(controlName).errors;
    return errs ? Object.values(errs) : errs;
  };

  const firstError = (controlName: keyof TControl) => {
    return errors(controlName)?.[0] ?? '';
  };

  const showRemoveDialog = () => {
    return removeDialogShown() && removeDialogItem() !== undefined;
  };

  const cancelRemoveDialog = () => {
    removeDialogItem.set(undefined);
    removeDialogShown.set(false);
  };

  const commitRemoveDialog = () => {
    const removeItem = removeDialogItem();
    if (removeItem) {
      params.mutations.remove.mutate(removeItem).then(cancelRemoveDialog);
    }
  };

  const formValue = () => {
    return params.form.value;
  };

  const getEditedDataItem = () => {
    return editingArgs?.dataItem;
  };

  const isEditing = (rowIndex: number) => {
    return Boolean(
      rowIndex >= 0 ? editingArgs?.rowIndex === rowIndex : editingArgs?.isNew,
    );
  };

  const showEditDialog = () => {
    return editDialogShown() && editingArgs !== undefined;
  };

  return {
    removeDialogShown,
    addHandler,
    editHandler,
    cancelHandler,
    saveHandler,
    removeHandler,
    cancelEditDialog,
    commitEditDialog,
    approveEditDialog,
    showEditDialog,
    controls,
    control,
    error,
    errors,
    firstError,
    showRemoveDialog,
    cancelRemoveDialog,
    commitRemoveDialog,
    formValue,
    getEditedDataItem,
    isEditing,
  };
}
