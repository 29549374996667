import * as Brace from 'brace';
import 'brace/mode/text';

const define = (Brace as any).define;


function feeRulesMode(_require: unknown, exports: any, _module: unknown) {
  const { TextHighlightRules } = Brace.acequire("ace/mode/text_highlight_rules");
  const { Range } = Brace.acequire("ace/range");
  const { FoldMode: BaseFoldMode } = Brace.acequire("ace/mode/folding/fold_mode");

  const oop = Brace.acequire("ace/lib/oop");
  const textMode = Brace.acequire("ace/mode/text");

  function HighlightRules(this: any) {
    this.$rules = {
      "start": [
        {
          token: "constant.numeric",
          regex: "\\b[+-]?\\d+(?:\\.\\d*)?\\b"
        },
        {
          token: "comment",
          regex: "#.*$"
        },
        {
          token: "keyword",
          regex: "\\b(min|max|clamp|divcent|separate|\\$BLOCK|\\$COND|BLOCK|COND|PARAM)\\b"
        }
      ]
    };
  };


  oop.inherits(HighlightRules, TextHighlightRules);


  const MatchingBraceOutdent = function MatchingBraceOutdent(this: any) {
    this.checkOutdent = function checkOutdent(line: string, input: string) {
      if (! /^\s+$/.test(line))
        return false;

      return /^\s*\}/.test(input);
    };

    this.autoOutdent = function autoOutdent(doc: any, row: number) {
      const line = doc.getLine(row);
      const match = line.match(/^(\s*\})/);

      if (!match) return 0;

      const column = match[1].length;
      const openBracePos = doc.findMatchingBracket({ row: row, column: column });

      if (!openBracePos || openBracePos.row == row) return 0;

      const indent = this.$getIndent(doc.getLine(openBracePos.row));
      doc.replace(new Range(row, 0, row, column - 1), indent);

      return undefined;
    };

    this.$getIndent = function (line: string) {
      return line.match(/^\s*/)?.[0];
    };
  } as unknown as { new(): unknown; };

  const FoldMode = function FoldMode(this: any, commentRegex: any) {
    this.foldingStartMarker = /(?<!\#.+?)([\{\[\(])[^\}\]\)]*$/;
    this.foldingStopMarker = /^[^\#\[\{\(]*([\}\]\)])/;

    this.getFoldWidgetRange = function (session: any, foldStyle: any, row: number) {
      const line = session.getLine(row);
      const match = line.match(this.foldingStartMarker);
      if (match) {
        const i = match.index;

        if (match[1]) {
          return this.openingBracketBlock(session, match[1], row, i);
        }

        const range = session.getCommentFoldRange(row, i + match[0].length);
        range.end.column -= 2;
        return range;
      }
    }
  } as unknown as { new(): unknown; };

  oop.inherits(FoldMode, BaseFoldMode);

  function Mode(this: any) {
    // set everything up
    this.HighlightRules = HighlightRules;
    this.$outdent = new MatchingBraceOutdent();
    this.foldingRules = new FoldMode();
  };

  oop.inherits(Mode, textMode.Mode);

  const proto = Mode.prototype;

  proto.lineCommentStart = "#";
  proto.$id = "ace/mode/fee-rules";

  proto.checkOutdent = function (_state: unknown, line: unknown, input: unknown) {
    return this.$outdent.checkOutdent(line, input);
  };

  proto.autoOutdent = function (_state: unknown, doc: unknown, row: unknown) {
    this.$outdent.autoOutdent(doc, row);
  };

  exports.Mode = Mode;
}

define("ace/mode/fee-rules", feeRulesMode);