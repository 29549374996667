import { toObservable } from '@angular/core/rxjs-interop';

import { lastValueFrom } from 'rxjs';

import { map, switchMap } from 'rxjs/operators';

import { Signal, inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import {
  EntityConstraint,
  GridQueryParams,
  defaultParamsSignal,
  mapSuccessQueryToGridResultWithDrafts,
  pagedEntityCollectionMutations,
} from '@/common';
import { normalizeGridQueryParams } from '@/common/grid-helpers';
import { Page } from '@/api/types';

import { injectPersistedQuery } from './inject-persisted-query';

export function injectSimpleCollection<
  Entity extends EntityConstraint,
>(baseUrl: string, baseKey: unknown[], paramsSignal?: Signal<GridQueryParams>) {
  const http = inject(HttpClient);

  const params = paramsSignal ?? defaultParamsSignal();
  const params$ = toObservable(normalizeGridQueryParams(params));

  const mutations = pagedEntityCollectionMutations<Entity>(
    baseKey,
    baseUrl,
    params,
  );

  const persistedQuery =
    injectPersistedQuery<Page<Entity[]>>(baseUrl);

  const result$ = params$.pipe(
    switchMap((params) =>
      persistedQuery([...baseKey, params], params).result$.pipe(
        map(mapSuccessQueryToGridResultWithDrafts(r => r))
      ),
    ),
  );

  const getAll = () =>
    lastValueFrom(
      http.get<Entity[]>(baseUrl + 'all', {
        params: {
          params: JSON.stringify(params()),
        },
      }),
    );

  return {
    mutations,
    result$,
    getAll,
  };
}
