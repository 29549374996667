import { EnumList } from '@/common';

export enum CrmAuthorizedPersonAction {
  GENERAL_QUERIES = 'GENERAL_QUERIES',

  SETTLEMENTS_INSTRUCTIONS = 'SETTLEMENTS_INSTRUCTIONS',
  DISCLOSURE_COMMUNICATIONS = 'DISCLOSURE_COMMUNICATIONS',
  RECIEVE_REPORTS_STATEMENTS = 'RECIEVE_REPORTS_STATEMENTS',
  GIVE_TRADING_INSTRUCTIONS = 'GIVE_TRADING_INSTRUCTIONS',

  GIVE_CASH_TRANSFERS_INSTRUCTIONS = 'GIVE_CASH_TRANSFERS_INSTRUCTIONS',
}

export const CRM_AUTHORIZED_PERSON_ACTION_TITLES: EnumList = [
  {
    value: CrmAuthorizedPersonAction.GENERAL_QUERIES,
    title: 'General queries',
  },
  {
    value: CrmAuthorizedPersonAction.SETTLEMENTS_INSTRUCTIONS,
    title: 'Settlements instructions',
  },
  {
    value: CrmAuthorizedPersonAction.DISCLOSURE_COMMUNICATIONS,
    title: 'Communication regarding disclosures',
  },
  {
    value: CrmAuthorizedPersonAction.RECIEVE_REPORTS_STATEMENTS,
    title: 'Receiving statements and reports',
  },
  {
    value: CrmAuthorizedPersonAction.GIVE_TRADING_INSTRUCTIONS,
    title: 'Give trading instructions',
  },
  {
    value: CrmAuthorizedPersonAction.GIVE_CASH_TRANSFERS_INSTRUCTIONS,
    title: 'Give cash transfer instructions',
  },
];

export enum CrmContactMethod {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export const CRM_CONTACT_METHOD_TITLES: EnumList = [
  { value: CrmContactMethod.PHONE, title: 'Phone' },
  { value: CrmContactMethod.EMAIL, title: 'Email' },
];

export interface LegalEntityContact {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string | null;

  phone: string;
  codeWord: string | null;
  companyPosition: string | null;
  email: string;

  contactMethods: CrmContactMethod[];
  authorizedActions: CrmAuthorizedPersonAction[];
}
