import { Injectable, inject } from '@angular/core';
import { skip, distinctUntilChanged, tap } from 'rxjs/operators';
import { Actions, createEffect } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { UiSettingsService } from './ui-settings.service';
import { UiSettingsSlice } from './ui-settings.state';
import { selectUiSettingsState } from './ui-settings.selectors';

@Injectable()
export class UiSettingsEffects {
  actions$ = inject(Actions);
  store = inject(Store<UiSettingsSlice>);
  settings$ = this.store.select(selectUiSettingsState);

  saveStateEffect$ = createEffect(
    () =>
      this.settings$.pipe(
        skip(1),
        distinctUntilChanged(),
        tap((settings) => {
          UiSettingsService.saveToLocalStorage(settings);
        }),
      ),
    {
      dispatch: false,
    },
  );
}
