<kendo-grid #grid class="grid" [resizable]="true" [reorderable]="true" [data]="(content$ | async) ?? []"
  [loading]="(loading$ | async) ?? false" [autoSize]="true" (edit)="editHandler($event)">

  <kendo-grid-column field="action" title="Action">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{actionColumn(dataItem)}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="createdByUsername" title="User">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.createdByUsername}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="diff" title="Changed fields">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <div class="changed-fields">
        <div *ngFor="let field of changedFields(dataItem)">
          {{field}}
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="createdTime" title="Time">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{dataItem.createdTime | date:'short' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="50">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <app-grid-commands [readOnly]="true" />
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

@if (viewDataItem(); as entity) {
<app-entity-view-dialog (close)="closeViewDialog()" />
}