import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { TextAreaComponent } from '@progress/kendo-angular-inputs';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-text-area-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <kendo-textarea [formControl]="control()" [rows]="3" #field />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class TextareaFieldComponent
  extends BaseFieldComponent
  implements AfterViewInit
{
  @ViewChild(TextAreaComponent, { static: false })
  public textArea!: TextAreaComponent;

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.textArea.focus();
    }
  }
}
