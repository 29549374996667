import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  forwardRef,
  inject,
  signal,
} from '@angular/core';

import * as debounce from 'debounce';

import { equals, modifyPath } from 'ramda';

import { Store } from '@ngrx/store';

import {
  FetchDataCallback,
  GridComponent,
  RemoveEvent,
} from '@progress/kendo-angular-grid';

import {
  pencilIcon,
  trashIcon,
  saveIcon,
  cancelIcon,
  eyeIcon,
  exclamationCircleIcon,
  fileExcelIcon,
  filePdfIcon,
  filterIcon,
} from '@progress/kendo-svg-icons';

import { FormBuilder } from '@angular/forms';

import { Observable } from 'rxjs';

import { distinctUntilChanged, map } from 'rxjs/operators';

import { FilterExpression } from '@progress/kendo-angular-filter';

import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { Permission, WithEntityStatusCommon } from '@/api';

import {
  includesKey,
  UiSettingsActions,
  selectGridCurrentColumnProfileName,
  assert,
  CollectionMutationStatus,
} from '@/common';

import {
  injectEditHelper,
  injectGridSettings,
  injectQueryParams,
} from '@/common/grid-helpers';
import { selectCurrentPermissions } from '@/auth';

import {
  ColumnMeta,
  FIELD_META_DEFAULT,
  FIELD_META_PROVIDER_TOKEN,
  FieldMeta,
  FieldMetaGroupsFlat,
  FieldMetaProvider,
} from '../form/field-meta-provider';
import {
  FORM_SOURCE_TOKEN,
  FormCompareSourceProvider,
  formatBoolValue,
  formatDateShort,
  formatEnumValue,
  formatLookupValue,
  generateColumnsFieldMeta,
} from '../form';
import { ENTITY_GRID_SETTINGS_TOKEN } from '../entity-grid-common';
import { generateViewCardGroups } from '../form/field-meta-generators';

import { ContentProps } from './edit-dialog';

export interface EditDialogProps {
  close: () => void;
  save: (value: any) => void;
  approve?: (value: any) => void;
  status$: Observable<CollectionMutationStatus>;
  entity: any;
  approveForEntity: any | undefined;
  readOnly?: boolean;
}

@Component({
  selector: 'app-entity-grid',
  templateUrl: './template.html',
  styleUrls: ['./styles.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: FIELD_META_PROVIDER_TOKEN,
      useExisting: forwardRef(() => EntityGridComponent),
    },
    {
      provide: FORM_SOURCE_TOKEN,
      useExisting: forwardRef(() => EntityGridComponent),
    },
  ],
})
export class EntityGridComponent
  implements OnInit, FieldMetaProvider, FormCompareSourceProvider {
  icons = {
    pencilIcon,
    trashIcon,
    saveIcon,
    cancelIcon,
    eyeIcon,
    fileExcelIcon,
    filePdfIcon,
    filterIcon,
    exclamationCircleIcon,
  };

  Permission = Permission;

  @ViewChild('grid') grid!: GridComponent;

  @ViewChild('enumFilterTemplate', { static: true })
  public enumFilterTemplate!: TemplateRef<any>;

  @ViewChild('lookupFilterTemplate', { static: true })
  public lookupFilterTemplate!: TemplateRef<any>;

  @Input()
  editDialogTemplate?: TemplateRef<ContentProps<any>>;

  @Input()
  importExportCustomTemplate?: TemplateRef<any>;

  permissions = inject(Store).selectSignal(selectCurrentPermissions);

  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);

  allColumnMeta = generateColumnsFieldMeta(this.settings.columnMetaGroups);

  allMeta = this.allColumnMeta('full');
  columnMeta = this.allColumnMeta('gridColumns');
  draftColumnMeta = this.allColumnMeta('draftColumns');
  filterColumnMeta = this.allColumnMeta('gridFilters');

  allColumns = this.columnMeta.map(([column]) => column);

  editMode = this.settings.editMode;

  columnProfile = inject(Store).selectSignal(
    selectGridCurrentColumnProfileName(this.settings.gridName),
  );

  store = inject(Store);

  ////////

  defaultFilter: CompositeFilterDescriptor = {
    filters: [],
    logic: 'and',
  };

  dummyForm = inject(FormBuilder).group({});

  gridSettings = injectGridSettings({
    allColumns: this.allColumns,
    gridName: this.settings.gridName,
  });

  columnSettings = this.gridSettings.columnSettings;
  displayedColumns = this.gridSettings.displayedColumns;
  columnWidth = this.gridSettings.columnWidth;
  columnHidden = this.gridSettings.columnHidden;
  columnReorder = this.gridSettings.columnReorder;
  columnResize = this.gridSettings.columnResize;
  columnVisibilityChange = this.gridSettings.columnVisibilityChange;

  ////////

  queryParams = injectQueryParams({
    gridName: this.settings.gridName,
    metaProvider: this,
  });
  params = this.queryParams.params;
  pageChange = this.queryParams.pageChange;
  filterChange = this.queryParams.filterChange;
  filterChangeDebounced = debounce(this.filterChange, 500);
  sortChange = this.queryParams.sortChange;
  setFilterItem = this.queryParams.setFilterItem;

  ////////
  showFilterDialog = signal(false);
  rejectDataItem = signal<any>(undefined);
  removeDraftDataItem = signal<any>(undefined);
  draftsDialogDraftDataItem = signal<any>(undefined);
  auditDialogDataItem = signal<any>(undefined);

  ////////
  service = this.settings.injectQueryService(this.queryParams.queryParams);

  entities$ = this.service.result$;

  mutations = this.service.mutations;

  isUpdating$ = this.mutations.isUpdating$;
  currentMutationStatus$ = this.mutations.currentStatus$.pipe(distinctUntilChanged((s1, s2) => equals(s1, s2)));

  ////////
  editHelpers = injectEditHelper({
    form: this.dummyForm,
    grid: () => this.grid,
    mutations: this.mutations!,
    editMode: this.settings.editMode,
    draftMode: this.settings.draftMode,
  });

  includesKey = includesKey;

  filters: FilterExpression[] = [];

  getColFilter(col: string) {
    const meta = this.getMeta(col);
    return meta.type;
  }

  isColFilterable(col: string) {
    return this.getMeta(col).presence.includes('gridFilters');
  }

  isColSortable(col: string) {
    return this.getMeta(col).presence.includes('sort');
  }

  getEnumFilterValues(col: string) {
    const meta = this.getMeta(col);
    assert(meta.type === 'enum');

    return meta.enumList;
  }

  getLookupFilterValues(col: string) {
    const meta = this.getMeta(col);
    assert(meta.type === 'lookup');

    return meta.lookupData;
  }

  getFilterValue(col: string) {
    const filter = this.params().filter?.filters.find((f): f is FilterDescriptor => 'field' in f && f.field === col);
    return filter?.value;
  }

  getLookupFilterTextField(col: string) {
    const meta = this.getMeta(col);
    assert(meta.type === 'lookup');

    return meta.descriptionField ?? 'description';
  }

  enumFilterItemChanged(col: string, value: unknown) {
    this.setFilterItem(col, value);
  }

  onAdjustAllColumns() {
    const columns = this.displayedColumns();

    this.grid.autoFitColumns(this.grid.columns);
    const settings = this.grid.columns.toArray().map((c, index) => ({
      width: c.width,
      column: columns[index],
    }));

    this.store.dispatch(
      UiSettingsActions.resizeColumns({
        grid: this.settings.gridName,
        settings,
      }),
    );
  }

  isReadOnly() {
    if (this.settings.editMode === 'none') {
      return true;
    }

    const editPermissions = [this.settings.editPermission].flatMap((p) => p);
    const permissions = this.permissions();

    return !editPermissions.some((p) => permissions.includes(p));
  }

  onFetchData: FetchDataCallback = async () => {
    if (this.service.getAll) {
      const data = await this.service.getAll();

      const dataFormatted = await Promise.all(data.map(async (item) =>
        await this.columnMeta.reduce(async (resP, [col, meta]) => {
          const res = await resP;
          const colPath = col.split('.');
          let modifier: (any: any) => unknown;
          switch (meta.type) {
            case 'date':
              modifier = formatDateShort('');
              break;
            case 'enum':
              modifier = formatEnumValue(meta);
              break;
            case 'bool':
              modifier = formatBoolValue;
              break;
            case 'lookup':
              modifier = await formatLookupValue(meta);
              break;

            default:
              modifier = value => value;
          }

          return modifyPath(colPath, modifier, res)
        }, item),
      ));

      return {
        data: dataFormatted,
      };
    } else {
      return {
        data: []
      }
    }
  };

  editHandler = this.editHelpers.editHandler;

  removeHandler = (args: RemoveEvent) => {
    if (this.settings.draftMode) {
      if (this.isDraft(args.dataItem)) {
        this.rejectDataItem.set(args.dataItem);
      } else {
        this.removeDraftDataItem.set(args.dataItem);
      }
    } else {
      this.editHelpers.removeHandler(args);
    }
  };

  openFilterDialog = () => {
    this.showFilterDialog.set(true);
  };

  closeFilterDialog = () => {
    this.showFilterDialog.set(false);
  };

  trackColumn = (_index: number, [col]: ColumnMeta) => {
    return col + '-' + this.columnProfile();
  };

  ngOnInit() {
    this.filters = this.filterColumnMeta.map(([col, meta]) => {
      const editor: FilterExpression['editor'] =
        meta.type === 'bool'
          ? 'boolean'
          : meta.type === 'date'
            ? 'date'
            : meta.type === 'number'
              ? 'number'
              : 'string';

      const isEnum = meta.type === 'enum';
      const isLookup = meta.type === 'lookup';

      const template = isEnum
        ? this.enumFilterTemplate
        : isLookup
          ? this.lookupFilterTemplate
          : undefined;

      const operators: FilterExpression['operators'] =
        isEnum || isLookup ? ['eq', 'neq', 'isnull', 'isnotnull'] : undefined;

      return {
        field: col,
        title: meta.title,
        editor,
        editorTemplate: template,
        operators,
      };
    });
  }

  getDrafts(id: string) {
    return this.entities$.pipe(
      map(
        (v) =>
          v.data?.drafts
            .filter((d: WithEntityStatusCommon) => d.approveFor === id)
            .sort(
              (d1: WithEntityStatusCommon, d2: WithEntityStatusCommon) =>
                d2.common.createdTime - d1.common.createdTime,
            ),
      ),
    );
  }

  isDraft(dataItem: WithEntityStatusCommon) {
    return !dataItem.common.isApproved;
  }

  hasDrafts(dataItem: WithEntityStatusCommon) {
    return dataItem.common.hasDrafts;
  }

  isClosed(dataItem: WithEntityStatusCommon) {
    return dataItem.common.isClosed;
  }

  draftEntityButtonTitle() {
    return (
      'This is a draft for ' +
      this.getEntityDescription() +
      ' visible for editors and approvers only. Click to edit draft or click delete to reject draft.'
    );
  }

  entityAuditButtonTitle() {
    return 'History audit';
  }

  openEntityAuditDialog(ent: any) {
    this.auditDialogDataItem.set(ent);
  }

  closeEntityAuditDialog() {
    this.auditDialogDataItem.set(undefined);
  }

  showEntityAuditDialog() {
    return this.auditDialogDataItem() !== undefined;
  }

  draftsForEntityButtonTitle() {
    return (
      'There are new drafts for this ' +
      this.getEntityDescription() +
      '. Click to review and approve'
    );
  }

  getEntityDescription(ent?: any) {
    const { entityDescription } = this.settings;
    return typeof entityDescription === 'string'
      ? entityDescription
      : entityDescription(ent);
  }

  /// Remove simple ///////////////

  showRemoveDialog = this.editHelpers.showRemoveDialog;

  cancelRemoveDialog = this.editHelpers.cancelRemoveDialog;

  commitRemoveDialog = this.editHelpers.commitRemoveDialog;

  /// Reject draft ///////////////

  showRejectDialog() {
    return Boolean(this.rejectDataItem());
  }

  cancelRejectDialog() {
    this.rejectDataItem.set(undefined);
  }

  commitRejectDialog() {
    this.service.mutations?.remove
      .mutate(this.rejectDataItem())
      .then(() => this.cancelRejectDialog());
  }

  /// Create remove draft ///////////////

  showCreateRemoveDraftDialog() {
    return Boolean(this.removeDraftDataItem());
  }

  cancelCreateRemoveDraftDialog() {
    this.removeDraftDataItem.set(undefined);
  }

  commitCreateRemoveDraftDialog(reason: string) {
    const ent = this.removeDraftDataItem();

    this.service.mutations?.add
      .mutate({
        ...ent,
        id: null,
        approveFor: ent.id,
        common: {
          ...ent.common,
          isApproved: false,
          isClosed: true,
          closureReason: reason,
        },
      } as WithEntityStatusCommon)
      .then(() => this.cancelCreateRemoveDraftDialog());
  }

  /// Drafts list ///////////////

  openDraftsDialog(dataItem: string) {
    this.draftsDialogDraftDataItem.set(dataItem);
  }

  showDraftsListDialog() {
    return Boolean(this.draftsDialogDraftDataItem());
  }

  draftsListDialogTitle() {
    return this.getEntityDescription(this.draftsDialogDraftDataItem());
  }

  draftsListDialogDrafts() {
    return this.getDrafts(this.draftsDialogDraftDataItem().id);
  }

  cancelDraftsListDialog() {
    this.draftsDialogDraftDataItem.set(undefined);
  }

  /// Edit entity, draft, or create new draft

  showEditDialog = this.editHelpers.showEditDialog;

  cancelEditDialog = this.editHelpers.cancelEditDialog;

  getEditedDataItem = this.editHelpers.getEditedDataItem;

  saveDraftEditDialog = this.editHelpers.commitEditDialog;

  approveDraftEditDialog = this.editHelpers.approveEditDialog;

  getCompareSource() {
    return this.draftsDialogDraftDataItem() ?? this.getSource();
  }

  getSource() {
    return this.getEditedDataItem();
  }

  getMeta(path: string): FieldMeta {
    const pathNoIndexes = path.replace(/\.\d+/g, '');
    const meta = this.allMeta.find(([colPath]) => pathNoIndexes === colPath);
    return meta ? meta[1] : FIELD_META_DEFAULT;
  }

  getFlatGroups(value: any): FieldMetaGroupsFlat {
    return generateViewCardGroups(this.settings.columnMetaGroups, value);
  }

  logValue<T>(descr: string, value: T): T {
    console.log(descr + " = ", value);
    return value;
  }
}
