import { Component, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import {
  AuthSlice,
  selectIsAuthorized,
  selectCurrentUserName,
  AuthActions,
} from '@/auth';

import { ApiNotificationInterceptor } from './common';
import { Permission } from './api';
import { injectIsLoginRouteSignal } from './routing';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  apiNotificationInterceptor = inject(ApiNotificationInterceptor);
  store = inject(Store<AuthSlice>);
  Permission = Permission;

  userMenuOpen = false;
  title = 'Nebula';

  items = ['Administration'];
  expandedIndex = 0;

  administrationPermissions = [
    Permission.USERS_EDIT,
    Permission.USERS_VIEW,
    Permission.USER_ROLES_EDIT,
    Permission.USER_ROLES_VIEW,
    Permission.ADMINISTRATION_STATIC_VIEW,
    Permission.ADMINISTRATION_STATIC_EDIT,
  ];

  administrationStaticPermissions = [
    Permission.ADMINISTRATION_STATIC_VIEW,
    Permission.ADMINISTRATION_STATIC_EDIT,
  ];

  crmClientPermissions = [
    Permission.CRM_CLIENTS_EDIT,
    Permission.CRM_CLIENTS_VIEW,
  ];

  crmLegalsPermissions = [
    Permission.CRM_LEGAL_ENTITIES_EDIT,
    Permission.CRM_LEGAL_ENTITIES_VIEW,
  ];

  portfolioPermissions = [
    Permission.ACC_PORTFOLIOS_ACCOUNTS_VIEW,
    Permission.ACC_PORTFOLIOS_ACCOUNTS_EDIT,
  ];

  bankPermissions = [
    Permission.CRM_COUNTERPARTY_BANKS_VIEW,
    Permission.CRM_COUNTERPARTY_BANKS_EDIT,
  ];

  instrumentsPermissions = [
    Permission.ACC_INSTRUMENTS_EDIT,
    Permission.ACC_INSTRUMENTS_VIEW,
  ];

  accountingPermissions = [...this.portfolioPermissions];

  crmPermissions = [...this.crmClientPermissions, ...this.crmLegalsPermissions];

  tradingPermissions = [
    Permission.TRADING_STATIC_VIEW,
    Permission.TRADING_STATIC_EDIT,

    Permission.TRADING_FEE_TYPES_VIEW,
    Permission.TRADING_FEE_TYPES_EDIT,

    Permission.TRADING_TRANSACTION_FEES_VIEW,
    Permission.TRADING_TRANSACTION_FEES_EDIT,

    Permission.TRADING_NON_TRANSACTION_FEES_VIEW,
    Permission.TRADING_NON_TRANSACTION_FEES_EDIT,

    Permission.TRADING_CHARGES_VIEW,
    Permission.TRADING_CHARGES_EDIT,

    Permission.TRADING_MARKET_DATA_VIEW,
    Permission.TRADING_MARKET_DATA_EDIT,

    Permission.TRADING_TRADE_INPUT_VIEW,
    Permission.TRADING_TRADE_INPUT_EDIT,
  ];

  currentUser = this.store.selectSignal(selectCurrentUserName);

  route = inject(ActivatedRoute);

  isAuthorized = this.store.selectSignal(selectIsAuthorized);
  isLoginRoute = injectIsLoginRouteSignal();

  isAuthorizedLayout = computed(
    () => !this.isLoginRoute() && this.isAuthorized(),
  );

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  constructor() {
    const DEBUG = {
      app: this,
      http: inject(HttpClient),
      watchFormErrors: false,
    };
    (window as any).DEBUG = DEBUG;
  }
}
