import { FormBuilder } from '@angular/forms';

import { inject } from '@angular/core';

import { disableControl, enableControl } from '../form-helpers';

const nullStr = null as string | null;

export function injectEntityStatusCommonFormGroup() {
  const formBuilder = inject(FormBuilder);
  const formGroup = formBuilder.group({
    isApproved: [false],
    isActive: [false],
    isBlocked: [false],
    isClosed: [false],
    extraNotes: [nullStr],
    closureReason: [nullStr],

    activatedTime: [null as number | null, []],
    approvedTime: [null as number | null, []],
    closedTime: [null as number | null, []],
    blockedTime: [null as number | null, []],
  });

  const adjustDependentFields = () => {
    const {
      closureReason,
      closedTime,
      approvedTime,
      activatedTime,
      blockedTime,
    } = formGroup.controls;

    [closureReason, closedTime].forEach(
      formGroup.value.isClosed ? enableControl : disableControl,
    );

    [approvedTime].forEach(
      formGroup.value.isApproved ? enableControl : disableControl,
    );

    [activatedTime].forEach(
      formGroup.value.isActive ? enableControl : disableControl,
    );

    [blockedTime].forEach(
      formGroup.value.isBlocked ? enableControl : disableControl,
    );
  };

  formGroup.valueChanges.subscribe(adjustDependentFields);

  return formGroup;
}

export type EntityStatusCommonFormValues = Required<
  ReturnType<typeof injectEntityStatusCommonFormGroup>
>['value'];

export const entityCommonColumnTitles = {
  'common.isApproved': 'Is approved',
  'common.approvedBy.username': 'Approved by',
  'common.approvedTime': 'Approved',

  'common.isActive': 'Is active',
  'common.activatedBy.username': 'Activated by',
  'common.activatedTime': 'Activated',

  'common.isClosed': 'is closed',
  'common.closedBy.username': 'Closed by',
  'common.closedTime': 'Closed at',
  'common.closureReason': 'Closure reason',

  'common.isBlocked': 'is blocked',
  'common.blockedTime': 'Blocked at',
  'common.blockedBy.username': 'Blocked by',

  'common.createdBy.username': 'Created by',
  'common.createdTime': 'Created at',

  'common.updatedBy.username': 'Updated by',
  'common.updatedTime': 'Updated at',

  'common.extraNotes': 'Extra notes',
};

export type ENTITY_COMMON_COLUMN = keyof typeof entityCommonColumnTitles;

export const entityCommonDefVisibleColumns: ENTITY_COMMON_COLUMN[] = [
  'common.isApproved',
  'common.isActive',
  'common.isBlocked',
  'common.isClosed',
];

export const entityCommonBoolColumns: ENTITY_COMMON_COLUMN[] = [
  'common.isApproved',
  'common.isActive',
  'common.isBlocked',
  'common.isClosed',
];

export const entityCommonDateColumns: ENTITY_COMMON_COLUMN[] = [
  'common.createdTime',
  'common.approvedTime',
  'common.activatedTime',
  'common.closedTime',
  'common.updatedTime',
  'common.blockedTime',
];
