@if (meta.compositeType === 'simple') {
@switch (meta.type) {
@case ('date') { <app-date-column-control [value]="getValue()" />}
@case ('number') { <app-number-column-control [value]="getValue()" /> }
@case ('enum') { <app-enum-column-control [value]="getValue()" [titles]="meta.enumList" />}
@case ('lookup') {
<app-lookup-column-control [value]="getValue()" [lookupData]="meta.lookupData"
  [descriptionField]="meta.descriptionField" />
}
@case ('bool') {
@switch (boolDisplay) {
@case ('checkbox') { <app-checkbox-column-control [value]="getValue()" /> }
@case ('text') { {{getValue()}} }
}
}
@default { {{getValue()}} }
}
}