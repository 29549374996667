<kendo-dialog [title]="title()" (close)="closeDialog()" [formGroup]="form" class="k-form-horizontal"
  (click)="onRootClick($event)">
  <div class="edit-container">
    <ng-container *ngTemplateOutlet="contentTemplate; context: { entity, form }" />
  </div>

  <kendo-dialog-actions>
    <button kendoButton id="cancel-button" (click)="closeDialog()">
      Cancel
    </button>
    @if (draftMode()) {
    <app-save-draft-buttons (approve)="commitDialogApprove()" (save)="commitDialogSave()" [entity]="entity"
      [loading]="(loading() | async) ?? false" />
    } @else {
    <button kendoButton class="enable-tooltip" themeColor="primary" kendoTooltip (click)="commitDialogSave()"
      [disabled]="saveButtonStatus(form).disabled" [title]="saveButtonStatus(form).title"
      [iconClass]="(loading() | async) ? 'k-icon k-i-loading' : ''">
      Save
    </button>
    }
  </kendo-dialog-actions>
</kendo-dialog>