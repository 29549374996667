export function capitalize(s: string) {
  return s.length > 1 ? s[0].toUpperCase() + s.slice(1) : s;
}

export function deCapitalize(s: string) {
  let hasLowercase = false;
  for (const c of s) {
    if (c.toLowerCase() === c) {
      hasLowercase = true;
      break;
    }
  }

  return hasLowercase && s.length > 1 ? s[0].toLowerCase() + s.slice(1) : s;
}
