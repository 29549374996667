import { createActionGroup, props } from '@ngrx/store';

import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';

export const UiSettingsActions = createActionGroup({
  source: 'UiSettings',
  events: {
    resizeColumns: props<{
      grid: string;
      settings: {
        column: string;
        width: number | undefined;
      }[];
    }>(),

    sort: props<{
      grid: string;
      sort: SortDescriptor[];
    }>(),

    setColumnsOrder: props<{
      grid: string;
      columns: string[];
    }>(),

    setColumnsVisibility: props<{
      grid: string;
      columns: Record<string, boolean>;
    }>(),

    renameCurrentColumnsProfile: props<{
      grid: string;
      newProfileName: string;
    }>(),

    cloneCurrentColumnsProfile: props<{
      grid: string;
      newProfileName: string;
    }>(),

    removeCurrentColumnsProfile: props<{
      grid: string;
    }>(),

    resetCurrentColumnsProfile: props<{
      grid: string;
    }>(),

    setCurrentColumnsProfile: props<{
      grid: string;
      profileName: string;
    }>(),

    ////
    cloneCurrentFilterProfile: props<{
      grid: string;
      newProfileName: string;
    }>(),

    renameCurrentFilterProfile: props<{
      grid: string;
      newProfileName: string;
    }>(),

    removeCurrentFilterProfile: props<{
      grid: string;
    }>(),

    resetCurrentFilterProfile: props<{
      grid: string;
    }>(),

    setCurrentFilterProfile: props<{
      grid: string;
      profileName: string;
    }>(),

    updateCurrentFilter: props<{
      grid: string;
      filter: CompositeFilterDescriptor;
    }>(),
  },
});
