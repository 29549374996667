import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { EnumList } from '@/common/utils';

@Component({
  selector: 'app-enum-column-control',
  template: `
    <div *ngFor="let title of getTitles()">
      {{ title }}
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnumColumnControlComponent {
  @Input()
  value!: (unknown | null) | (unknown | null)[];

  @Input()
  titles!: EnumList;

  getTitles() {
    const values = Array.isArray(this.value)
      ? this.value
      : this.value != null
      ? [this.value]
      : [];

    return values.map(
      (v) => this.titles?.find((t) => t.value === v)?.title ?? '',
    );
  }
}
