import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';

export function injectIsLoginRoute() {
  return inject(ActivatedRoute).url.pipe(
    map((segments) => segments[0].path === 'login'),
  );
}

export function injectIsLoginRouteSignal() {
  return toSignal(injectIsLoginRoute());
}
