import { Observable } from 'rxjs';

import { InjectionToken, Signal } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { QueryObserverResult } from '@tanstack/query-core';

import { PageWithDrafts, Permission } from '@/api';
import {
  CollectionMutations,
  EntityConstraint,
  GridQueryParams,
  GridResult,
} from '@/common/utils';

import { FieldMetaGroups } from '../form/field-meta-provider';

export interface ViewDialogProps {
  entity: any;
  entityToCompare: unknown | undefined;

  compareKind?: 'diff' | 'entity';
}

type QueryService<Entity extends EntityConstraint> = (paramsSignal?: Signal<GridQueryParams>) => {
  result$: Observable<
    QueryObserverResult<PageWithDrafts<GridResult<Entity>, Entity>>
  >;
  mutations: CollectionMutations<Entity>;
  getAll?: () => Promise<Entity[]>;
};

export interface EntityGridSettings<
  Entity extends EntityConstraint,
  FormControl extends Record<keyof Entity, AbstractControl>,
> {
  readonly gridName: string;

  readonly entityDescription: string | ((ent?: any) => string);

  readonly entityName: string;

  readonly editPermission: Permission | Permission[];

  readonly editMode: 'dialog' | 'none';

  readonly draftMode: boolean;

  readonly columnMetaGroups: FieldMetaGroups;

  readonly formFactory?: (
    initialValue: Entity | undefined,
  ) => FormGroup<FormControl>;

  readonly injectQueryService: QueryService<Entity>;
}

export const ENTITY_GRID_SETTINGS_TOKEN = new InjectionToken<
  EntityGridSettings<any, Record<string, AbstractControl>>
>('ENTITY_GRID_SETTINGS_TOKEN');
