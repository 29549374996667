import { Signal, signal } from '@angular/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  FilterOperator,
  SortDescriptor,
} from '@progress/kendo-data-query';

import { startOfDay, endOfDay, formatISO } from 'date-fns';

import { compose } from 'ramda';

export interface GridQueryParams {
  skip?: number;
  take?: number;
  sort?: SortDescriptor[];
  filter?: CompositeFilterDescriptor;
}

export const defaultParamsSignal: () => Signal<GridQueryParams> = () =>
  signal<GridQueryParams>({
    skip: 0,
    take: 20,
    sort: [],
  });

export function isCompositeFilterDescriptor(
  f: FilterDescriptor | CompositeFilterDescriptor,
): f is CompositeFilterDescriptor {
  return 'logic' in f;
}

export function isFilterDescriptor(
  f: FilterDescriptor | CompositeFilterDescriptor,
): f is FilterDescriptor {
  return 'operator' in f;
}

export function mapFilterItems(
  mapFn: (
    item: FilterDescriptor,
  ) =>
    | (FilterDescriptor | CompositeFilterDescriptor)
    | (FilterDescriptor | CompositeFilterDescriptor)[],
) {
  return (filter: CompositeFilterDescriptor): CompositeFilterDescriptor => {
    const { filters } = filter;
    return {
      ...filter,
      filters: filters.flatMap((f) => {
        if (isCompositeFilterDescriptor(f)) {
          return mapFilterItems(mapFn)(f);
        } else {
          return mapFn(f);
        }
      }),
    };
  };
}

export function convertFilterDatesToStr(f: FilterDescriptor): FilterDescriptor {
  if (f.value instanceof Date) {
    return {
      ...f,
      value: formatISO(f.value, {
        format: 'extended',
        representation: 'complete',
      }),
    };
  } else {
    return f;
  }
}

export function convertFilterDatesToDayRanges(
  f: FilterDescriptor,
): FilterDescriptor | CompositeFilterDescriptor {
  if (f.value instanceof Date) {
    const start = startOfDay(f.value);
    const end = endOfDay(f.value);
    switch (f.operator) {
      case FilterOperator.EqualTo:
        return {
          logic: 'and',
          filters: [
            {
              operator: 'gte',
              field: f.field,
              value: start,
            },
            {
              operator: 'lte',
              field: f.field,
              value: end,
            },
          ] satisfies FilterDescriptor[],
        } satisfies CompositeFilterDescriptor;

      case FilterOperator.NotEqualTo:
        return {
          logic: 'or',
          filters: [
            {
              operator: 'lt',
              field: f.field,
              value: start,
            },
            {
              operator: 'gt',
              field: f.field,
              value: end,
            },
          ] satisfies FilterDescriptor[],
        } satisfies CompositeFilterDescriptor;

      case FilterOperator.LessThanOrEqual:
      case FilterOperator.GreaterThan:
        return {
          ...f,
          value: end,
        } satisfies FilterDescriptor;

      case FilterOperator.LessThan:
      case FilterOperator.GreaterThanOrEqual:
        return {
          ...f,
          value: start,
        } satisfies FilterDescriptor;

      default:
        return f;
    }
  }
  return f;
}

export function removeArrayIndexesFromField(
  f: FilterDescriptor,
): FilterDescriptor {
  const idxDotRe = /\.\d+/g;

  if (typeof f.field === 'string' && f.field.match(idxDotRe)) {
    const field = f.field.replace(idxDotRe, '');
    return {
      ...f,
      field,
    };
  }
  return f;
}

export function removeEqNullFilters(
  f: FilterDescriptor,
): FilterDescriptor[] | FilterDescriptor {
  if (f.operator === FilterOperator.EqualTo && f.value === null) {
    return [];
  }
  return f;
}

export const normalizeFilter = compose(
  mapFilterItems(removeEqNullFilters),
  mapFilterItems(convertFilterDatesToStr),
  mapFilterItems(
    compose(convertFilterDatesToDayRanges, removeArrayIndexesFromField),
  ),
);
