import { createSelector } from '@ngrx/store';

import { ApiErrorCodes } from '@/api';

import { assert } from '@/common';

import { AuthSlice, authFeatureKey } from './auth.state';

export const selectAuthState = (feature: AuthSlice) => feature[authFeatureKey];

export const selectIsAuthInProgress = createSelector(
  selectAuthState,
  (state) => state.status === 'unauthorized' && state.authInProgress,
);

export const selectAuthAccessToken = createSelector(selectAuthState, (state) =>
  state.status === 'authorized' ? state.data.accessToken : undefined,
);

export const selectIsAuthorized = createSelector(
  selectAuthState,
  (state) => state.status === 'authorized',
);

export const selectCurrentRoles = createSelector(selectAuthState, (state) =>
  state.status === 'authorized' ? state.data.user.roles : [],
);

export const selectCurrentPermissions = createSelector(
  selectAuthState,
  (state) =>
    state.status === 'authorized'
      ? state.data.user.roles?.flatMap((r) => r.permissions) ?? []
      : [],
);

export const selectCurrentUser = createSelector(
  selectAuthState,
  (authState) => {
    return authState.status === 'authorized' ? authState.data.user : undefined;
  },
);

export const selectCurrentUserDefined = createSelector(
  selectAuthState,
  (authState) => {
    assert(authState.status === 'authorized');
    return authState.data.user;
  },
);

export const selectCurrentUserLogin = createSelector(
  selectCurrentUser,
  (user) => user?.username ?? '',
);

export const selectCurrentUserName = createSelector(
  selectCurrentUser,
  (user) => user?.name ?? '',
);

export const selectAuthErrorMessage = createSelector(
  selectAuthState,
  (state) =>
    state.status === 'failed'
      ? state.error.status === ApiErrorCodes.Unauthorized
        ? 'Wrong user or password'
        : 'Unknown login error'
      : undefined,
);
