import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  inject,
} from '@angular/core';

import { path, equals } from 'ramda';

import {
  FIELD_META_DEFAULT,
  FIELD_META_PROVIDER_TOKEN,
  FieldMeta,
} from '../field-meta-provider';
import { FORM_SOURCE_TOKEN } from '../form-compare-source';

@Component({
  selector: 'app-value-view-field',
  template: `
    <div class="k-form-field" orientation="horizontal">
      <kendo-label class="label">
        <span [class]="{ changed: isChanged }">
          {{ getTitle() }}
        </span>
      </kendo-label>
      <div class="k-form-field-wrap">
        <app-value-view [path]="path" [boolDisplay]="boolDisplay" />
      </div>
    </div>
  `,
  styles: [
    `
    kendo-label.label
      padding-top: 0

    .changed
      background-color: var(--kendo-color-warning)
  `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueViewFieldComponent implements OnChanges {
  provider = inject(FIELD_META_PROVIDER_TOKEN, {
    optional: true,
  });

  sourceProvider = inject(FORM_SOURCE_TOKEN);

  @Input()
  path = '';

  @Input()
  boolDisplay: 'text' | 'checkbox' = 'checkbox';

  meta: FieldMeta = FIELD_META_DEFAULT;

  isChanged = false;

  ngOnChanges() {
    this.meta = this.provider?.getMeta(this.path) ?? {
      ...FIELD_META_DEFAULT,
      title: this.path,
    };

    const source = this.sourceProvider.getSource();
    const compareSource = this.sourceProvider.getCompareSource();

    const valuePath = path(this.path.split('.'));

    const value = valuePath(source);
    const compareValue = valuePath(compareSource);

    this.isChanged = !equals(value, compareValue);
  }

  getTitle() {
    return this.meta.title;
  }
}
