<div class="filter-wrapper">
  <div class="filter-profiles-wrapper">
    <span>Filter profiles:</span>
    <app-grid-profiles [gridName]="settings.gridName" profileType="filter"> </app-grid-profiles>
    <kendo-button (click)="openFilterDialog()" [svgIcon]="icons.filterIcon" title="Edit filter" fillMode="solid">
      Edit filter
    </kendo-button>
  </div>
</div>

<kendo-dialog
  title="Edit filter"
  (close)="closeFilterDialog()"
  autoFocusedElement="#cancel-button"
  *ngIf="showFilterDialog()">
  <div class="filter-dialog-content">
    <kendo-filter
      #filter
      [filters]="filters"
      [value]="params().filter ?? defaultFilter"
      (valueChange)="filterChangeDebounced($event)"
      class="filter-dialog-filter">
    </kendo-filter>
  </div>
  <kendo-dialog-actions>
    <button kendoButton themeColor="primary" (click)="closeFilterDialog()">Ok</button>
  </kendo-dialog-actions>
</kendo-dialog>

<ng-template #enumFilterTemplate let-currentItem let-clearButton="clearButton">
  <kendo-combobox
    [data]="getEnumFilterValues(currentItem.field)"
    [allowCustom]="false"
    [popupSettings]="{ width: 'auto' }"
    [clearButton]="clearButton ?? false"
    [value]="getFilterValue(currentItem?.field)"
    [valuePrimitive]="true"
    textField="title"
    valueField="value"
    (valueChange)="enumFilterItemChanged(currentItem.field, $event)" />
</ng-template>

<ng-template #lookupFilterTemplate let-currentItem let-clearButton="clearButton">
  <kendo-combobox
    [data]="(getLookupFilterValues(currentItem.field) | async)?.data ?? []"
    [allowCustom]="false"
    [popupSettings]="{ width: 'auto' }"
    [clearButton]="clearButton"
    [value]="getFilterValue(currentItem?.field)"
    [textField]="getLookupFilterTextField(currentItem.field)"
    [valuePrimitive]="true"
    valueField="id"
    (valueChange)="enumFilterItemChanged(currentItem.field, $event)" />
</ng-template>

<kendo-grid
  #grid
  class="grid"
  [pageable]="true"
  [pageSize]="params().take"
  [skip]="params().skip"
  [sort]="params().sort"
  [filter]="params().filter"
  [resizable]="true"
  [reorderable]="true"
  [sortable]="true"
  [filterable]="true"
  [columnMenu]="{ filter: false }"
  [loading]="((entities$ | async)?.isFetching || (isUpdating$ | async)) ?? false"
  [data]="(entities$ | async)?.data?.content ?? []"
  (edit)="editHandler($event)"
  (cancel)="editHelpers.cancelHandler($event)"
  (save)="editHelpers.saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="editHelpers.addHandler($event)"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
  (filterChange)="filterChange($event)"
  (columnResize)="columnResize($event)"
  (columnReorder)="columnReorder($event)"
  (columnVisibilityChange)="columnVisibilityChange($event)">
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridAddCommand *ngIf="!isReadOnly()">{{settings.draftMode ? 'Add new draft' : 'Add new'}}</button>
    <button kendoButton (click)="onAdjustAllColumns()">Adjust column widths</button>

    <app-grid-profiles [gridName]="settings.gridName" profileType="columns"> </app-grid-profiles>

    <button type="button" kendoGridExcelCommand [svgIcon]="icons.fileExcelIcon">Export to Excel</button>
    <button type="button" kendoGridPDFCommand [svgIcon]="icons.filePdfIcon">Export to pdf</button>

    @if (importExportCustomTemplate) {
    <ng-container *ngTemplateOutlet="importExportCustomTemplate" />
    }
  </ng-template>

  <kendo-grid-column
    *ngFor="let meta of columnMeta; trackBy: trackColumn"
    [field]="meta[0]"
    [title]="meta[1].titlePrefixed"
    [width]="columnWidth(meta[0])"
    [hidden]="columnHidden(meta[0])"
    [filterable]="isColFilterable(meta[0])"
    [sortable]="isColSortable(meta[0])">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      @if (meta[1].customTemplates?.filterTemplate; as tpl) {
      <ng-container *ngTemplateOutlet="tpl; context: { filter: filter, meta: meta[1], column: meta[0] }" />
      } @else { @switch (getColFilter(meta[0])) { @case ('date') {
      <kendo-grid-date-filter-cell [column]="column" operator="eq" [filter]="filter" />
      } @case ('bool') {
      <kendo-grid-boolean-filter-cell [column]="column" [filter]="filter" />
      } @case ('number') {
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" />
      } @case ('enum') {
      <ng-container
        *ngTemplateOutlet="enumFilterTemplate; context: { $implicit: { field: meta[0] }, clearButton: true }" />
      } @case ('lookup') {
      <ng-container
        *ngTemplateOutlet="lookupFilterTemplate; context: { $implicit: { field: meta[0] }, clearButton: true }" />
      } @default {
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" />
      } } }
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <app-value-view [value]="dataItem" [path]="meta[0]" />
    </ng-template>

    <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
      <span [title]="meta[1].titlePrefixed">{{meta[1].titlePrefixed}}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [sticky]="true" [width]="40">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <app-grid-commands
        [readOnly]="isReadOnly()"
        editButtonTitle="View item"
        viewButtonTitle="View item"
        deleteButtonTitle="Delete item"
        *ngIf="!settings.draftMode" />

      <div *ngIf="settings.draftMode" class="command-buttons">
        @if (!isReadOnly()) {
        <button
          kendoTooltip
          kendoGridEditCommand
          position="left"
          title="This record is closed and removed from reports. Click to edit it and reset closed status."
          themeColor="warning"
          *ngIf="isClosed(dataItem) && !isDraft(dataItem)">
          C
        </button>

        <kendo-button
          kendoTooltip
          position="left"
          [svgIcon]="icons.exclamationCircleIcon"
          [title]="draftsForEntityButtonTitle()"
          themeColor="warning"
          *ngIf="!isDraft(dataItem) && hasDrafts(dataItem)"
          (click)="openDraftsDialog(dataItem)" />
        }

        <button
          kendoTooltip
          kendoGridEditCommand
          position="left"
          [title]="draftEntityButtonTitle()"
          [svgIcon]="icons.pencilIcon"
          themeColor="warning"
          *ngIf="isDraft(dataItem)"></button>

        <kendo-button
          kendoTooltip
          position="left"
          [title]="entityAuditButtonTitle()"
          [svgIcon]="icons.eyeIcon"
          class="command-button-hover"
          themeColor="warning"
          (click)="openEntityAuditDialog(dataItem)" />

        <button
          [primary]="true"
          kendoGridEditCommand
          kendoTooltip
          [title]="isReadOnly() ? 'View item' : 'Create new draft for this item change'"
          class="command-button-hover"
          [svgIcon]="icons.pencilIcon"
          *ngIf="!isDraft(dataItem) && !isClosed(dataItem)"></button>

        @if (!isReadOnly()) {
        <button
          kendoGridRemoveCommand
          kendoTooltip
          [title]="isDraft(dataItem) ? 'Reject this draft' : 'Create deletion draft'"
          class="command-button-hover"
          [svgIcon]="icons.trashIcon"
          *ngIf="!isReadOnly() && !isClosed(dataItem)"></button>
        }
      </div>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-excel [fileName]="getEntityDescription() + '.xlsx'" [fetchData]="onFetchData"> </kendo-grid-excel>

  <kendo-grid-pdf
    [fileName]="getEntityDescription() + '.pdf'"
    [allPages]="true"
    paperSize="auto"
    [repeatHeaders]="true"
    [landscape]="true"
    [delay]="3000">
    <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
        <div class="header">
          <div style="float: right">Page {{ pageNum }} of {{ totalPages }}</div>
          Multi-page grid with automatic page breaking
        </div>
        <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
</kendo-grid>

<app-drafts-list-dialog
  *ngIf="showDraftsListDialog()"
  [entityName]="draftsListDialogTitle()"
  [readOnly]="isReadOnly()"
  [columnMeta]="draftColumnMeta"
  [drafts]="(draftsListDialogDrafts() | async) ?? []"
  (cancel)="cancelDraftsListDialog()"
  (reject)="removeHandler($event)"
  (edit)="editHandler($event)" />

@if (showEditDialog()) { @if (isReadOnly()) {
<app-entity-view-dialog (close)="cancelEditDialog()" />
} @else if (editDialogTemplate) {
<app-entity-grid-edit-dialog
  (close)="cancelEditDialog()"
  (save)="saveDraftEditDialog($event)"
  (approve)="approveDraftEditDialog($event)"
  [entity]="getEditedDataItem()"
  [status$]="currentMutationStatus$"
  [approveForEntity]="draftsDialogDraftDataItem()"
  [contentTemplate]="editDialogTemplate" />

} @else {
<app-entity-edit-dialog
  (close)="cancelEditDialog()"
  (save)="saveDraftEditDialog($event)"
  [status$]="currentMutationStatus$" />
}}

<app-create-remove-draft-dialog
  *ngIf="showCreateRemoveDraftDialog()"
  [entityName]="getEntityDescription(removeDraftDataItem())"
  [readOnly]="isReadOnly()"
  (cancel)=" cancelCreateRemoveDraftDialog()"
  (commit)="commitCreateRemoveDraftDialog($event)" />

<app-remove-dialog
  *ngIf="showRemoveDialog()"
  [entityName]="getEntityDescription()"
  (cancel)="cancelRemoveDialog()"
  (commit)="commitRemoveDialog()" />

<app-reject-draft-dialog
  *ngIf="showRejectDialog()"
  [entityName]="getEntityDescription(rejectDataItem())"
  (cancel)="cancelRejectDialog()"
  (commit)="commitRejectDialog()" />

@if (showEntityAuditDialog() && auditDialogDataItem()) {
<kendo-dialog
  title="History"
  (close)="closeEntityAuditDialog()"
  autoFocusedElement="#cancel-button"
  class="history-dialog">
  <div>
    <app-entity-grid-audit [entityId]="auditDialogDataItem().id" />
  </div>
  <kendo-dialog-actions>
    <button kendoButton id="cancel-button" (click)="closeEntityAuditDialog()">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
}
