import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { EnumList } from '@/common';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-multiselect-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>

      <kendo-multiselect
        #field
        [valuePrimitive]="true"
        [formControl]="control()"
        [autofocus]="autofocus ?? false"
        [data]="data"
        textField="title"
        valueField="value"
        [kendoMultiSelectSummaryTag]="10"
      ></kendo-multiselect>
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class MultiSelectFieldComponent
  extends BaseFieldComponent
  implements AfterViewInit {
  @ViewChild(MultiSelectComponent, { static: false })
  public select!: MultiSelectComponent;

  @Input()
  public data: EnumList = [];

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.select.focus();
    }
  }
}
