import 'brace';
import 'brace/mode/text';
import 'brace/theme/github';

import {
  NgModule,
  inject,
  isDevMode,
  ENVIRONMENT_INITIALIZER,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { UploadsModule } from "@progress/kendo-angular-upload";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import { GridModule } from '@progress/kendo-angular-grid';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IconsModule } from '@progress/kendo-angular-icons';

import { LabelModule } from '@progress/kendo-angular-label';

import { DialogsModule } from '@progress/kendo-angular-dialog';

import { ButtonsModule, ButtonModule } from '@progress/kendo-angular-buttons';

import { TypographyModule } from '@progress/kendo-angular-typography';

import { QueryClientService, provideQueryClientOptions } from '@ngneat/query';

import { NotificationModule } from '@progress/kendo-angular-notification';

import { NavigationModule } from '@progress/kendo-angular-navigation';

import { MenuModule } from '@progress/kendo-angular-menu';

import { TooltipsModule } from '@progress/kendo-angular-tooltip';

import { PopupModule } from '@progress/kendo-angular-popup';

import { LayoutModule } from '@progress/kendo-angular-layout';

import { NgClickOutsideDirective } from 'ng-click-outside2';

import { FilterModule } from '@progress/kendo-angular-filter';

import { ApiModule } from '@/api';
import { AuthInterceptor, AuthModule, AuthEffects } from '@/auth';
import { RoutingEffects } from '@/routing';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginPageComponent } from './pages/login-page';
import { IndexPageComponent } from './pages/index-page';
import { ApiNotificationInterceptor, CommonUtilsModule } from './common';

@NgModule({
  declarations: [AppComponent, LoginPageComponent, IndexPageComponent],
  imports: [
    ApiModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UploadsModule,
    NgClickOutsideDirective,
    IconsModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {},
    ),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(RoutingEffects, AuthEffects),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EntityDataModule.forRoot({}),
    AuthModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    CdkAccordionModule,
    ReactiveFormsModule,
    LoggerModule.forRoot({
      //serverLoggingUrl: '/api/logs',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR,
    }),
    GridModule,
    LabelModule,
    DialogsModule,
    TypographyModule,
    NotificationModule,
    NavigationModule,
    MenuModule,
    ButtonsModule,
    ButtonModule,
    TooltipsModule,
    PopupModule,
    LayoutModule,
    CommonUtilsModule,
    FilterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiNotificationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        const queryClient = inject(QueryClientService);
        if (!environment.production) {
          import('@ngneat/query-devtools').then((m) => {
            m.ngQueryDevtools({ queryClient });
          });
        }
      },
    },
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          staleTime: 3000,
          retry: false,
          refetchOnWindowFocus: false,
        },
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
