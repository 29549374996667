import 'brace';
import 'brace/mode/text';
import 'brace/mode/drools';
import 'brace/theme/github';
import 'brace/ext/searchbox';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

import { AceConfigInterface, AceComponent } from 'ngx-ace-wrapper';

import { equals } from 'ramda';

import './fee-rules';

export const DEF_CONFIG: AceConfigInterface = {
  minLines: 3,
  maxLines: 30
};


@Component({
  selector: 'app-ace-text-area-control',
  template: `
    <ace 
      [config]="config" 
      [mode]="mode" 
      [theme]="theme" 
      (blur)="onBlur()"
    />
  `,
  styles: [`
    :host
      position: relative
      display: block
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AceTextareaControlComponent,
    },
  ],
})
export class AceTextareaControlComponent implements ControlValueAccessor, OnChanges {
  @ViewChild(AceComponent, { static: true }) inner!: AceComponent;

  @Input()
  mode: string = 'text';

  @Input()
  theme: string = 'github';

  @Input()
  config: AceConfigInterface = DEF_CONFIG;

  @Input()
  errors?: ValidationErrors[];

  initialized = false;

  writeValue(obj: any) {
    this.inner.value = obj;
  }

  onTouchedFn: any;

  onBlur() {
    this.onTouchedFn?.();
  }

  registerOnChange(fn: any) {
    this.inner.valueChange.subscribe(() => {
      if (this.initialized) {
        //HACK: ngx-ace-wrapper calls value change for initial value. 
        //we should ignore this call to prevent form become dirty on open
        fn(this.inner.value);
      }
    });
  }

  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
  }
  setDisabledState?(isDisabled: boolean) {
    this.inner.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    const prevErrors = changes['errors']?.previousValue as ValidationErrors | undefined;
    const currentErrors = changes['errors']?.currentValue as ValidationErrors | undefined;

    if (equals(prevErrors, currentErrors)) {
      const editor = this.inner.directiveRef?.ace();
      if (editor) {
        const checkRe = [/Line\s+(\d+):(\d+)/ig, /line\s+(\d+),\s+column\s+(\d+)/ig];

        const errors = Object.values(currentErrors ?? {}).filter((e: string) => checkRe.some(r => r.test(e)));

        if (errors.length) {
          const annotations = errors.map((e: string) => {
            const re = checkRe.find(r => e.match(r))!;
            const matches = [...e.matchAll(re)];
            const match = matches[matches.length - 1];

            const row = Number(match[1]) - 1;
            const column = Number(match[2]);
            return {
              row,
              column,
              text: e,
              type: 'error'
            };
          })
          editor.getSession().setAnnotations(annotations);
        } else {
          editor.getSession().clearAnnotations();
        }
      }
    }
  }

  ngAfterViewInit() {
    this.initialized = true;
    const ace = this.inner?.directiveRef?.ace();

    //HACK: AceComponent puts first value set operation into undo stack.
    //Initial value set should not be present in stack, to prevent change on CTRL-Z until user will type something
    if (ace) {
      setTimeout(() => {
        ace.session.getUndoManager().reset();
      }, 100);
    }
  }
}
