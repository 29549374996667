import { EnumList } from '@/common';

export enum CounterpartyBankCustodianRole {
  BANK = 'BANK',
  CUSTODIAN = 'CUSTODIAN',
  CUSTODIAN_BANK = 'CUSTODIAN_BANK',
  BROKER = 'BROKER'
}

export const COUNTERPARTY_BANK_CUSTODIAN_LIST: EnumList = [
  {
    title: 'Bank',
    value: CounterpartyBankCustodianRole.BANK,
  },
  {
    title: 'Custodian',
    value: CounterpartyBankCustodianRole.CUSTODIAN,
  },
  {
    title: 'Custodian bank',
    value: CounterpartyBankCustodianRole.CUSTODIAN_BANK,
  },
  {
    title: 'Broker',
    value: CounterpartyBankCustodianRole.BROKER,
  },
];
