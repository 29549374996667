import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-column-control',
  template: ` <div class="center-content">
    <input type="checkbox" kendoCheckBox disabled [checked]="value" />
  </div>`,
  styles: [
    `
      :host,
      .center-content
        display: flex
        flex-direction: column
        align-items: center
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxColumnControlComponent {
  @Input()
  value = false;
}
