import { EnumList } from '@/common';

export enum AmlRisk {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export const AML_RISK_LIST: EnumList = [
  {
    title: 'Medium',
    value: AmlRisk.Medium,
  },
  {
    title: 'High',
    value: AmlRisk.High,
  },
  {
    title: 'Low',
    value: AmlRisk.Low,
  },
];
