import { AuthData, ApiError } from '@/api';

interface AuthUnauthorizedState {
  status: 'unauthorized';
  authInProgress: boolean;
}

interface AuthAuthorizedState {
  status: 'authorized';
  data: AuthData;
}

interface AuthFailedState {
  status: 'failed';
  error: ApiError;
}

export type AuthState =
  | AuthUnauthorizedState
  | AuthAuthorizedState
  | AuthFailedState;

export const authFeatureKey = 'auth';

export type AuthSlice = {
  [authFeatureKey]: AuthState;
};
