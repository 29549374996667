import { ChangeDetectionStrategy, Component, effect } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';

import {
  AuthActions,
  selectIsAuthInProgress,
  selectAuthErrorMessage,
  AuthSlice,
} from '@/auth';
import { AuthLoginData } from '@/api';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LoginPageComponent {
  constructor(
    private fb: FormBuilder,
    private store: Store<AuthSlice>,
  ) {}

  loading = this.store.selectSignal(selectIsAuthInProgress);
  authErrorMessage = this.store.selectSignal(selectAuthErrorMessage);

  loginForm = this.fb.group({
    login: ['', [Validators.required]],
    password: ['', Validators.required],
  });

  loadingEffect = effect(() => {
    const loading = this.loading();
    if (!loading) {
      this.loginForm.markAsPristine();
    }
  });

  onSubmit() {
    this.store.dispatch(
      AuthActions.login(this.loginForm.value as AuthLoginData),
    );
  }
}
