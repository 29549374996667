import { mapValues, isArray } from 'remeda';
import { isPlainObject } from 'ramda-adjunct';

export function normalizeEmptyToNull(value: any): any {
  if (value === '' || value === undefined || value === null) {
    return null;
  }

  return isArray(value)
    ? value.map(normalizeEmptyToNull)
    : isPlainObject(value)
    ? mapValues(value, normalizeEmptyToNull)
    : value;
}
