import { Component, Input, OnInit } from '@angular/core';

import { EnumList } from '@/common';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-enum-dropdown-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <app-enum-dropdown-control
        #field
        [formControl]="control()"
        [placeholder]="getTitle()"
        [enumValues]="getEnumValues()"
      />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class EnumDropdownFieldComponent
  extends BaseFieldComponent
  implements OnInit
{
  @Input()
  enumValues?: EnumList;

  metaEnumList?: EnumList;

  getEnumValues() {
    return this.metaEnumList ?? this.enumValues ?? [];
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.meta) {
      this.metaEnumList =
        this.meta.compositeType === 'simple' && this.meta.type === 'enum'
          ? this.meta.enumList
          : undefined;
    }
  }
}
