import { EnumList } from '@/common';

export enum CrmLeCrsFactaReportingReason {
  CRS_ACTIVE_NFE = 'CRS_ACTIVE_NFE',
  CRS_PASSIVE_NFE = 'CRS_PASSIVE_NFE',
}

export const CRM_LE_CRS_REPORTING_REASON_LIST: EnumList = [
  {
    title: 'Active NFE in CRS participating jurisdiction',
    value: CrmLeCrsFactaReportingReason.CRS_ACTIVE_NFE,
  },
  {
    title:
      'Passive NFE with Controlling persons that are resident in an CRS participating jurisdiction',
    value: CrmLeCrsFactaReportingReason.CRS_PASSIVE_NFE,
  },
];
