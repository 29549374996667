import { Component } from '@angular/core';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-checkbox-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <input #field type="checkbox" kendoCheckBox [formControl]="control()" />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class CheckboxFieldComponent extends BaseFieldComponent {}
