import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthSlice, selectCurrentPermissions } from '@/auth';
import { Permission } from '@/api';

type Logic = 'all' | 'any';

@Directive({ selector: '[appHasPermission]' })
export class HasPermissionDirective {
  templateRef = inject(TemplateRef<any>);
  viewContainer = inject(ViewContainerRef);
  rolesStore: Store<AuthSlice> = inject(Store<AuthSlice>);
  permissions = this.rolesStore.selectSignal(selectCurrentPermissions);

  hasView = false;
  requiredPermissions: Permission[] = [];
  logic: Logic = 'any';
  falseTemplate: TemplateRef<any> | undefined = undefined;

  @Input() set appHasPermission(permission: Permission | Permission[]) {
    this.requiredPermissions = (
      typeof permission === 'string' ? [permission] : permission
    )
      .flatMap((r) => r.split(','))
      .map((r) => r.trim()) as Permission[];

    this.checkPermissions();
  }

  @Input() set appHasPermissionLogic(value: Logic) {
    this.logic = value;
    this.checkPermissions();
  }

  @Input() set appHasPermissionFalseTemplate(value: TemplateRef<any>) {
    this.falseTemplate = value;
    this.checkPermissions();
  }

  checkPermissions() {
    const operation = this.logic === 'all' ? 'every' : 'some';
    const permsOk = this.requiredPermissions[operation]((requiredPermission) =>
      this.permissions().includes(requiredPermission),
    );

    if (permsOk && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!permsOk && this.falseTemplate && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.falseTemplate);
      this.hasView = true;
    } else if (!permsOk && !this.falseTemplate && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
