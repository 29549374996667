import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ReplaySubject } from 'rxjs';

import { Store } from '@ngrx/store';

import { AuthSlice } from '@/auth';

import { User } from '@/api';

import { BaseControlComponent } from './base-control';

@Component({
  selector: 'app-approve-control',
  host: { class: 'k-checkbox-wrap' },
  templateUrl: './template.html',
  styleUrls: ['./styles.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ApproveControlComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveControlComponent extends BaseControlComponent {
  store = inject(Store<AuthSlice>);

  readonly createdBy$ = new ReplaySubject<User>();

  constructor() {
    super();
    this.disabledInternal.set(true);
    this.disabledTitle.set(
      'Approve status can not be changed. It will be reset on draft creation',
    );
  }
}
