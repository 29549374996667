import { HttpClient } from '@angular/common/http';
import { Signal, inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { UseQuery } from '@ngneat/query';
import { map, switchMap } from 'rxjs/operators';

import { Page } from '@/api/types';

import {
  GridQueryParams,
  pagedEntityCollectionMutations,
  defaultParamsSignal,
  mapSuccessQueryToGridResult,
  EntityConstraint,
} from '@/common';

export function injectBaseEntityQueryService<
  Entity extends EntityConstraint,
>(params: { entityName: string }) {
  const http = inject(HttpClient);
  const useQuery = inject(UseQuery);

  const baseKey = [params.entityName];

  const baseUrl = `/api/${params.entityName}/`;

  const injectEntities = (
    paramsSignal: Signal<GridQueryParams> = defaultParamsSignal(),
  ) => {
    const params$ = toObservable(paramsSignal);

    const result$ = params$.pipe(
      switchMap(
        (params) =>
          useQuery(
            [...baseKey, params],
            () => {
              const httpParams: Record<string, any> = {
                skip: params.skip,
                take: params.take,
                filter: params.filter,
              };

              if (params.sort?.length) {
                httpParams['sort'] = params.sort;
              }

              return http.get<Page<Entity[]>>(baseUrl, {
                params: {
                  params: JSON.stringify(httpParams),
                },
              });
            },
            {
              refetchOnMount: false,
              refetchOnWindowFocus: false,
            },
          ).result$,
      ),
      map(mapSuccessQueryToGridResult((r) => r)),
    );

    const mutations = pagedEntityCollectionMutations<Entity>(
      baseKey,
      baseUrl,
      paramsSignal,
    );

    return {
      result$,
      mutations,
    };
  };

  const injectAllEntities = () => {
    return useQuery([...baseKey, 'all'], () =>
      http.get<Entity[]>(baseUrl + 'all'),
    ).result$;
  };

  return {
    injectEntities,
    injectAllEntities,
  };
}
