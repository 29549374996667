import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';

import { path } from 'ramda';

import { EditEvent, RemoveEvent } from '@progress/kendo-angular-grid';

import { WithEntityStatusCommon } from '@/api';

import { includesKey } from '@/common';

import { ENTITY_GRID_SETTINGS_TOKEN } from '../../entity-grid-common';
import { ColumnMeta } from '../../form';

interface Entity extends WithEntityStatusCommon {
  id: string;
}

@Component({
  selector: 'app-drafts-list-dialog',
  template: ` <kendo-dialog
    [title]="'Drafts for ' + entityName"
    (close)="closeDialog()"
    autoFocusedElement="#cancel-button"
  >
    <div class="content">
      <kendo-grid
        #grid
        class="grid"
        [resizable]="true"
        [reorderable]="true"
        [data]="drafts"
        (edit)="editHandler($event)"
        (remove)="rejectHandler($event)"
        [loading]="loading"
      >
        <kendo-grid-column
          *ngFor="let meta of columnMeta"
          [field]="meta[0]"
          [title]="meta[1].titlePrefixed"
          [autoSize]="true"
          [maxResizableWidth]="MAX_COL_WIDTH"
        >
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <div [style]="{ maxWidth: MAX_COL_WIDTH + 'px' }">
              <app-value-view [value]="dataItem" [path]="meta[0]" />
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-command-column [width]="40">
          <ng-template
            kendoGridCellTemplate
            let-dataItem="dataItem"
            let-rowIndex="rowIndex"
          >
            <app-grid-commands
              [viewButtonTitle]="viewDraftTitle(dataItem)"
              [editButtonTitle]="editDraftTitle(dataItem)"
              [deleteButtonTitle]="rejectDraftTitle(dataItem)"
              [readOnly]="readOnly"
            />
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
    <kendo-dialog-actions>
      <button kendoButton id="cancel-button" (click)="closeDialog()">
        Close
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .grid
        max-height: 60vh
    `,
  ],
})
export class DraftsListDialogComponent {
  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);

  MAX_COL_WIDTH = 300;

  @Input()
  entityName = '';

  @Input()
  drafts: Entity[] = [];

  @Input()
  source!: Entity;

  @Input()
  columnMeta: ColumnMeta[] = [];

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<EditEvent>();

  @Output()
  reject = new EventEmitter<RemoveEvent>();

  @Input()
  loading = false;

  @Input()
  readOnly = false;

  includesKey = includesKey;

  getColValue(dataItem: any, col: string): any {
    return path(col.split('.'), dataItem);
  }

  closeDialog() {
    this.cancel.emit();
  }

  editHandler($event: EditEvent) {
    this.edit.emit($event);
  }

  rejectHandler($event: RemoveEvent) {
    this.reject.emit($event);
  }

  isCloseDraft(dataItem: WithEntityStatusCommon) {
    return dataItem.common.isClosed;
  }

  draftKind(dataItem: WithEntityStatusCommon) {
    return this.isCloseDraft(dataItem) ? 'close' : 'change';
  }

  rejectDraftTitle(dataItem: WithEntityStatusCommon) {
    return `Reject ${this.draftKind(dataItem)} draft`;
  }

  editDraftTitle(dataItem: WithEntityStatusCommon) {
    return `Edit ${this.draftKind(dataItem)} draft`;
  }

  viewDraftTitle(dataItem: WithEntityStatusCommon) {
    return `View ${this.draftKind(dataItem)} draft`;
  }
}
