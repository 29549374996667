import { QueryObserverResult } from '@tanstack/query-core';

export const mapSuccessQueryResult =
  <T, R>(fn: (data: T) => R) =>
  (result: QueryObserverResult<T>): QueryObserverResult<R> => {
    const mapped =
      result.status === 'success'
        ? {
            ...result,
            data: fn(result.data),
          }
        : result;

    return mapped as QueryObserverResult<R>;
  };
