import { EnumList } from '@/common';

export enum ServicesProvided {
  BROKERAGE = 'BROKERAGE',
  DISCRETIONARY_PORTFOLIO_MANAGEMENT = 'DISCRETIONARY_PORTFOLIO_MANAGEMENT',
  DEPOSITORY = 'DEPOSITORY',
  INVESTMENT_ADVICE = 'INVESTMENT_ADVICE',
  S_AIFM = 'S_AIFM',
  DVP_TRADING_LINE = 'DVP_TRADING_LINE',
}

export const SERVICES_PROVIDED_LIST: EnumList = [
  {
    title: 'Brokerage',
    value: ServicesProvided.BROKERAGE,
  },
  {
    title: 'Discretionary portfolio management',
    value: ServicesProvided.DISCRETIONARY_PORTFOLIO_MANAGEMENT,
  },
  {
    title: 'Depository',
    value: ServicesProvided.DEPOSITORY,
  },
  {
    title: 'Investment advice',
    value: ServicesProvided.INVESTMENT_ADVICE,
  },
  {
    title: 'sAIFM',
    value: ServicesProvided.S_AIFM,
  },
  {
    title: 'DVP/Trading line',
    value: ServicesProvided.DVP_TRADING_LINE,
  },
];
