<div class="command-buttons-hover" kendoTooltip [title]="disabled ? disabledTooltip : ''">
  <button [primary]="true" kendoGridEditCommand kendoTooltip [title]="(readOnly && viewButtonTitle) || editButtonTitle"
    [disabled]="disabled" [svgIcon]="readOnly ? icons.eyeIcon : icons.pencilIcon">
  </button>
  <button kendoGridRemoveCommand kendoTooltip [title]="deleteButtonTitle" [disabled]="disabled"
    [svgIcon]="icons.trashIcon" *ngIf="!readOnly">
  </button>

  @if (form()) {
  <button kendoGridSaveCommand kendoTooltip class="enable-tooltip" [title]="saveButtonStatus().title"
    [disabled]="saveButtonStatus().disabled" [svgIcon]="icons.saveIcon" *ngIf="!readOnly">
  </button>
  }

  <ng-content select="[custom-edit]" *ngIf="editing"></ng-content>

  <button kendoGridCancelCommand [svgIcon]="icons.cancelIcon" *ngIf="!readOnly">
  </button>
</div>