import type {
  DeletingBoundUserRoleError,
  UserRoleReadonlyError,
} from './user-role.entity';
import type { DeletingBoundUserError, UserReadonlyError } from './user.entity';

import {
  ClientGeneralError,
  DeletingBoundLegalEntityTypeError,
  DeletingBoundLegalFormError,
  DeletingBoundClientGroupEntityError,
  DeletingBoundRegulatoryCategoryIcError,
  DeletingBoundRegulatoryCategoryLeError,
  DeletingBoundPortfolioGroupEntityError,
} from './crm';

import {
  DeletingBoundViewingEntityError,
  DeleteRootViewingEntityError,
} from './viewing-entity.entity';
import { DeletingBoundCurrencyError } from './currency.entity';
import { DeletingBoundRegionError } from './region.entity';
import { DeletingBoundIdentifierTypeError } from './identifier-type.entity';
import { DeletingBoundCountryError } from './country.entity';

export interface ApiError {
  status: number;
  message: string;
}

export enum ApiErrorCodes {
  BadRequest = 400,
  Forbidden = 403,
  Unauthorized = 401,
}

interface ValidationError {
  field: string;
  defaultMessage: string;
}

export interface ServerValidationError {
  error: 'Bad Request';
  status: 400;
  errors: ValidationError[];
}

export interface ApproveRelatedErrors {
  messageType:
  | 'entity.edit.edit-approved'
  | 'entity.approve.bad-draft'
  | 'entity.update.draft.change-approve-for'
  | 'entity.approve.bad-source'
  | 'entity.delete.delete-approved';
}

export type ApiErrorData =
  | ApiError
  | ApproveRelatedErrors
  | DeletingBoundRegionError
  | DeletingBoundCountryError
  | DeletingBoundRegulatoryCategoryIcError
  | DeletingBoundRegulatoryCategoryLeError
  | DeletingBoundIdentifierTypeError
  | DeletingBoundCurrencyError
  | DeletingBoundUserRoleError
  | DeletingBoundUserError
  | DeletingBoundViewingEntityError
  | DeletingBoundLegalFormError
  | DeletingBoundLegalEntityTypeError
  | DeleteRootViewingEntityError
  | DeletingBoundClientGroupEntityError
  | DeletingBoundPortfolioGroupEntityError
  | ServerValidationError
  | UserReadonlyError
  | UserRoleReadonlyError
  | ClientGeneralError;


export function isServerValidationError(error: ApiErrorData): error is ServerValidationError {
  return 'error' in error && error.error === 'Bad Request' && Array.isArray(error.errors);
}