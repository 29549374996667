import { Component, Input, inject } from '@angular/core';

import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { EntityStatusCommon } from '@/api';

@Component({
  selector: 'app-activate-approve-section',
  template: `
    <div class="section" [formGroup]="formGroupDir.form">
      @if (entity && entity.isApproved && entity.approvedBy) {
      <kendo-formfield orientation="horizontal">
        <app-approve-control
          #isApproved
          formControlName="isApproved"
          [entityName]="entityName"
          [form]="this.formGroupDir.form"
          [createdBy]="entity.createdBy"
        />
        <kendo-label
          [for]="isApproved"
          text="Approved"
          class="k-checkbox-label"
        />
        <kendo-formhint *ngIf="entity.approvedBy">
          Approved by
          {{ entity.approvedBy }}:
          {{ entity.approvedTime | date }}
        </kendo-formhint>
      </kendo-formfield>
      }

      <kendo-formfield orientation="horizontal">
        <app-active-approved-control
          #isActive
          [entityName]="entityName"
          formControlName="isActive"
          [form]="this.formGroupDir.form"
        />

        <kendo-label [for]="isActive">
          <app-label-change-tooltip title="Active" field="isActive" />
        </kendo-label>

        @if (entity && entity.activatedBy) {
        <kendo-formhint>
          Activated by
          {{ entity.activatedBy }}:
          {{ entity.activatedTime | date }}
        </kendo-formhint>
        }
      </kendo-formfield>

      <app-date-field
        field="activatedTime"
        title="Activated date"
        inputFormat="number"
        [max]="currentDate"
      />

      <kendo-formfield orientation="horizontal">
        <app-block-approved-control
          #isBlocked
          [entityName]="entityName"
          formControlName="isBlocked"
          [form]="this.formGroupDir.form"
        />

        <kendo-label [for]="isBlocked">
          <app-label-change-tooltip title="Blocked" field="isBlocked" />
        </kendo-label>

        @if (entity && entity.blockedBy) {
        <kendo-formhint *ngIf="entity.blockedBy">
          Blocked by
          {{ entity.blockedBy }}:
          {{ entity.blockedTime | date }}
        </kendo-formhint>
        }
      </kendo-formfield>

      <app-date-field
        field="blockedTime"
        title="Blocked date"
        inputFormat="number"
        [max]="currentDate"
      />

      @if (entity) {
      <kendo-formfield>
        <input
          #isClosed
          type="checkbox"
          kendoCheckBox
          formControlName="isClosed"
        />
        <kendo-label [for]="isClosed">
          <app-label-change-tooltip title="Closed" field="isClosed" />
        </kendo-label>
        <kendo-formhint *ngIf="entity.closedBy">
          Closed by
          {{ entity.closedBy }}:
          {{ entity.closedTime | date }}
        </kendo-formhint>
      </kendo-formfield>

      <kendo-formfield *ngIf="isClosedSet()">
        <kendo-textbox
          #closureReason
          formControlName="closureReason"
        ></kendo-textbox>
        <kendo-label [for]="closureReason">
          <app-label-change-tooltip
            title="Closure reason"
            field="closureReason"
          />
        </kendo-label>
      </kendo-formfield>

      <app-date-field
        field="closedTime"
        title="Closed date"
        inputFormat="number"
        [max]="currentDate"
        *ngIf="isClosedSet()"
      />
      }
    </div>
  `,
  styles: [
    `.section
      display: flex
      flex-direction: column
      gap: 8px
    `,
  ],
})
export class ActivateApproveSectionComponent {
  @Input()
  entity: EntityStatusCommon | undefined;

  @Input()
  entityName = '';

  formGroupDir = inject(ControlContainer) as FormGroupDirective;

  currentDate = new Date();

  controlValue(field: string) {
    return this.formGroupDir.form.controls[field]?.value;
  }

  isClosedSet() {
    return Boolean(this.controlValue('isClosed'));
  }

  isActiveSet() {
    return Boolean(this.controlValue('isActive'));
  }

  isApproveSet() {
    return Boolean(this.controlValue('isApproved'));
  }

  isBlockedSet() {
    return Boolean(this.controlValue('isBlocked'));
  }
}
