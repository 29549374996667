import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  inject,
} from '@angular/core';

import { path, equals } from 'ramda';

import {
  FIELD_META_DEFAULT,
  FIELD_META_PROVIDER_TOKEN,
  FieldMeta,
} from '../field-meta-provider';
import { FORM_SOURCE_TOKEN } from '../form-compare-source';

@Component({
  selector: 'app-value-edit-field',
  template: `
    @if (meta.compositeType === 'simple') {
      @switch (meta.type) {
        @case ('date') { <app-date-field [field]="path" />}
        @case ('number') { <app-text-field [field]="path" /> }
        @case ('enum') { <app-enum-dropdown-field [field]="path" />}
        @case ('lookup') {
          <app-lookup-dropdown-field [field]="path" />
        }
        @case ('text') { <app-text-field [field]="path" /> }
        @case ('bool') {
          @switch (boolDisplay) {
            @case ('checkbox') { <app-checkbox-field [field]="path" /> }
            @case ('text') { <app-text-field [field]="path" /> }
          }
        }
        @default { getValue() }
      }
    }

  `,
  styles: [
    `
    kendo-label.label
      padding-top: 0

    .changed
      background-color: var(--kendo-color-warning)
  `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueEditFieldComponent implements OnChanges {
  provider = inject(FIELD_META_PROVIDER_TOKEN, {
    optional: true,
  });

  sourceProvider = inject(FORM_SOURCE_TOKEN);

  @Input()
  path = '';

  @Input()
  boolDisplay: 'text' | 'checkbox' = 'checkbox';

  meta: FieldMeta = FIELD_META_DEFAULT;

  isChanged = false;

  ngOnChanges() {
    this.meta = this.provider?.getMeta(this.path) ?? {
      ...FIELD_META_DEFAULT,
      title: this.path,
    };

    const source = this.sourceProvider.getSource();
    const compareSource = this.sourceProvider.getCompareSource();

    const valuePath = path(this.path.split('.'));

    const value = valuePath(source);
    const compareValue = valuePath(compareSource);

    this.isChanged = !equals(value, compareValue);
  }

  getTitle() {
    return this.meta.title;
  }

  getValue(): any {
    const source = this.sourceProvider.getSource();

    if (source !== undefined) {
      return path(this.path.split('.'), source);
    } else {
      return undefined;
    }
  }
}
