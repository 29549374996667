import { Component, Input, ViewChild } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

import { EnumList } from '@/common';

@Component({
  selector: 'app-enum-dropdown-control',
  template: `
    <kendo-combobox
      #inner
      [data]="enumValues"
      [allowCustom]="false"
      [popupSettings]="{ width: 'auto' }"
      [clearButton]="true"
      textField="title"
      valueField="value"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      [valuePrimitive]="valuePrimitive"
    >
    </kendo-combobox>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EnumDropdownControlComponent,
    },
  ],
})
export class EnumDropdownControlComponent implements ControlValueAccessor {
  @ViewChild(ComboBoxComponent, { static: true }) inner!: ComboBoxComponent;

  @Input()
  placeholder = '';

  @Input()
  valuePrimitive = true;

  @Input()
  enumValues: EnumList = [];

  onTouchedFn: any;

  onBlur() {
    this.onTouchedFn?.();
  }

  writeValue(obj: any) {
    this.inner.writeValue(obj);
  }
  registerOnChange(fn: any) {
    this.inner.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
    this.inner.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean) {
    this.inner.setDisabledState(isDisabled);
  }
}
