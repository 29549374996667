import { Injectable, inject } from '@angular/core';
import { map, tap, combineLatest, concat } from 'rxjs';
import { catchError, first, mergeMap, switchMap } from 'rxjs/operators';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { QueryClientService } from '@ngneat/query';

import { RoutingActions } from '@/routing';

import { AuthActions, selectIsAuthorized, AuthSlice } from '../store';

import { AuthService } from './auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AuthSlice>,
    private authService: AuthService,
  ) {}

  queryClient = inject(QueryClientService);

  initEffect$ = createEffect(() => {
    const init$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT));
    const authorized$ = this.store.select(selectIsAuthorized).pipe(first());

    const combined$ = combineLatest([authorized$, init$]);
    return combined$.pipe(
      mergeMap(([authorized]) =>
        authorized ? this.authService.getCurrentUser() : [],
      ),
      map((user) => AuthActions.updateCurrentUser({ user })),
    );
  });

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap((data) =>
        this.authService
          .login({
            login: data.login,
            password: data.password,
          })
          .pipe(
            map((response) => AuthActions.loginSuccess(response)),
            catchError((error) => [
              AuthActions.loginFailed({
                status: error.status,
                message: error.message,
              }),
            ]),
          ),
      ),
    ),
  );

  loginSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      tap((response) => {
        AuthService.saveAuthLocalStorage({
          accessToken: response.accessToken,
          user: response.user,
        });
      }),
      map(() => RoutingActions.returnFromLogin()),
    ),
  );

  logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() =>
        concat(
          [RoutingActions.navigateToLogin({})],
          this.authService.logout().pipe(
            tap(() => AuthService.clearAuthLocalStorage()),
            tap(() => this.queryClient.clear()),
            switchMap(() => []),
          ),
        ),
      ),
      catchError((error) => {
        console.warn('Logout error', error);
        return [];
      }),
    ),
  );
}
