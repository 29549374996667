import { Page, PageWithDrafts, PageWithDraftsResponse } from '@/api/types';

import { mapSuccessQueryResult } from './map-success-query-result';

export interface GridResult<T> {
  data: T[];
  total: number;
}

type ArrayMapFn<T> = (arr: T[]) => T[];

export const mapPageWithDraftsData =
  <T>(contentFn: ArrayMapFn<T>, draftsFn: ArrayMapFn<T>) =>
  (
    response: PageWithDraftsResponse<T> | undefined,
  ): PageWithDraftsResponse<T> | undefined =>
    response
      ? {
          ...response,
          drafts: draftsFn(response.drafts),
          page: {
            ...response.page,
            content: contentFn(response.page.content),
          },
        }
      : undefined;

export const mapPageWithDraftsContent = <T>(contentFn: ArrayMapFn<T>) =>
  mapPageWithDraftsData<T>(contentFn, (v) => v);

export const mapPageWithDraftsDrafts = <T>(draftsFn: (content: T[]) => T[]) =>
  mapPageWithDraftsData<T>((v) => v, draftsFn);

export const mapPageContent =
  <T, R>(fn: (content: T[]) => R[]) =>
  (page: Page<T[]> | undefined): Page<R[]> | undefined =>
    page
      ? {
          ...page,
          content: fn(page.content),
        }
      : undefined;

export const findPageContentItem =
  <T>(fn: (item: T) => boolean) =>
  (page: Page<T[]> | undefined): T | undefined =>
    page ? page.content.find(fn) : undefined;

export function mapSuccessQueryToGridResult<T, R>(fn: (data: T) => R) {
  return mapSuccessQueryResult(
    (page: Page<T[]>): Page<GridResult<R>> => ({
      ...page,
      content: {
        data: page.content.map(fn),
        total: page.totalElements,
      },
    }),
  );
}

export function mapSuccessQueryToGridResultWithDrafts<T, R>(
  fn: (data: T) => R,
) {
  return mapSuccessQueryResult(
    (
      page: PageWithDrafts<T[], T> | Page<T[]>,
    ): PageWithDrafts<GridResult<R>, R> =>
      'drafts' in page
        ? {
            ...page,
            drafts: page.drafts.map(fn),
            content: {
              data: page.content.map(fn),
              total: page.totalElements,
            },
          }
        : {
            ...page,
            drafts: [],
            content: {
              data: page.content.map(fn),
              total: page.totalElements,
            },
          },
  );
}

export function mapPageWithDraftsResponseToPageWithDrafts<T>() {
  return mapSuccessQueryResult<
    PageWithDraftsResponse<T>,
    PageWithDrafts<T[], T>
  >(({ drafts, page }) => ({
    ...page,
    drafts,
  }));
}
