import { createFeature, createReducer, on } from '@ngrx/store';
import { setPath } from 'remeda';

import { AuthService } from '../services';

import { AuthState } from './auth.state';
import { AuthActions } from './auth.actions';

const initialState: AuthState = AuthService.getAuthLocalStorage()
  ? ({
      status: 'authorized',
      data: AuthService.getAuthLocalStorage()!,
    } satisfies AuthState)
  : ({
      status: 'unauthorized',
      authInProgress: false,
    } satisfies AuthState);

export const authFeature = createFeature({
  name: 'auth',
  reducer: createReducer<AuthState>(
    initialState,
    on(AuthActions.login, () => ({
      status: 'unauthorized' as const,
      authInProgress: true,
    })),
    on(AuthActions.loginSuccess, (_, payload) => ({
      status: 'authorized' as const,
      data: {
        accessToken: payload.accessToken,
        user: payload.user,
      },
    })),
    on(AuthActions.loginFailed, (_, payload) => ({
      status: 'failed' as const,
      error: {
        status: payload.status,
        message: payload.message,
      },
    })),
    on(AuthActions.logout, () => ({
      status: 'unauthorized' as const,
      authInProgress: false,
    })),
    on(AuthActions.refreshAccessToken, (state, payload) => {
      return state.status === 'authorized'
        ? setPath(state, ['data', 'accessToken'], payload.accessToken)
        : state;
    }),
    on(AuthActions.updateCurrentUser, (state, payload) => {
      return state.status === 'authorized'
        ? setPath(state, ['data', 'user'], payload.user)
        : state;
    }),
  ),
});
