import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { canActivateAuthorized } from '@/auth';

import {
  LoginPageComponent,
  IndexPageComponent,
  ForbiddenPageComponent,
} from './pages';
import { NotFoundPageComponent } from './common';

const routes: Routes = [
  {
    title: 'Nebula',
    path: '',
    component: IndexPageComponent,
    canActivate: [canActivateAuthorized],
  },
  {
    title: 'Login',
    path: 'login',
    component: LoginPageComponent,
  },
  {
    title: 'Administration',
    path: 'administration',
    loadChildren: () =>
      import('@/administration').then((m) => m.AdministrationModule),
    canActivateChild: [canActivateAuthorized],
  },
  {
    title: 'CRM',
    path: 'crm',
    loadChildren: () => import('@/crm').then((m) => m.CrmModule),
    canActivateChild: [canActivateAuthorized],
  },
  {
    title: 'Accounting',
    path: 'accounting',
    loadChildren: () => import('@/accounting').then((m) => m.AccountingModule),
    canActivateChild: [canActivateAuthorized],
  },
  {
    title: 'Instruments',
    path: 'instruments',
    loadChildren: () => import('@/instruments').then((m) => m.InstrumentsModule),
    canActivateChild: [canActivateAuthorized],
  },
  {
    title: 'Trading',
    path: 'trading',
    loadChildren: () => import('@/trading').then((m) => m.TradingModule),
    canActivateChild: [canActivateAuthorized],
  },
  {
    title: 'Forbidden',
    path: 'forbidden',
    component: ForbiddenPageComponent,
  },
  {
    title: 'Not found',
    path: '**',
    pathMatch: 'full',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
