import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

import { FIELD_META_PROVIDER_TOKEN, FieldMeta } from './field-meta-provider';
import { getFieldFullPathStr } from './get-field-full-path';

@Component({
  template: '',
})
export class BaseFieldComponent implements OnInit {
  @Input()
  field?: string;

  @Input()
  title?: string;

  @Input()
  autofocus?: boolean;

  formGroup = inject(ControlContainer) as FormGroupDirective;

  metaProvider = inject(FIELD_META_PROVIDER_TOKEN, {
    optional: true,
  });

  changeDetectorRef = inject(ChangeDetectorRef);

  destroyRef = inject(DestroyRef);

  meta?: FieldMeta;

  defaultField = '';

  defaultTitle = '';

  getTitle() {
    const metaTitle = this.meta?.title;
    return this.title ?? metaTitle ?? this.defaultTitle;
  }

  getField() {
    return this.field ?? this.defaultField;
  }

  getFieldPath() {
    return getFieldFullPathStr(this.getField(), this.formGroup);
  }

  control() {
    return this.formGroup.form.get(this.getField()) as FormControl;
  }

  errors() {
    return Object.values(this.control().errors ?? {});
  }

  ngOnInit() {
    this.meta = this.metaProvider?.getMeta(this.getFieldPath());

    if (!this.getTitle()) {
      console.log('???????????', this.getField(), this.getFieldPath());
    }

    const sub = this.formGroup.form.statusChanges.subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });

    this.destroyRef.onDestroy(() => sub.unsubscribe());
  }
}
