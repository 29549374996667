import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
  inject,
  runInInjectionContext,
} from '@angular/core';

import { FormGroup } from '@angular/forms';

import { clone } from 'ramda';

import { Observable, Subscription, map } from 'rxjs';

import { ActivateFormData, saveButtonStatus } from '@/common/components/form';

import { watchFormErrors } from '@/common/form-helpers';

import { CollectionMutationStatus, EntityWithApproveConstraint } from '@/common';

import { ENTITY_GRID_SETTINGS_TOKEN } from '../../entity-grid-common';
import { formHandleMutationStatus } from '../../form';

export interface ContentProps<T> {
  entity: T | undefined;
  form: FormGroup;
}

@Component({
  selector: 'app-entity-grid-edit-dialog',
  templateUrl: './template.html',
  styleUrls: ['./styles.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EntityGridEditDialogComponent<
  T extends EntityWithApproveConstraint,
  FormValues,
>
  implements OnChanges, OnInit {
  @Input()
  contentTemplate!: TemplateRef<ContentProps<T>>;

  @Input()
  entity: T | undefined;

  @Input()
  approveForEntity: T | undefined;

  @Input()
  status$!: Observable<CollectionMutationStatus>;

  saveButtonStatus = saveButtonStatus;

  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);

  form = this.settings.formFactory!(undefined);

  formSub?: Subscription;
  statusUnsub?: () => void;

  initialValue = clone(this.form.value);

  activateForm = this.form as unknown as FormGroup<ActivateFormData>;

  environmentInjector = inject(EnvironmentInjector);

  destroyRef = inject(DestroyRef);

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  @Output() save = new EventEmitter<FormValues>();

  @Output() approve = new EventEmitter<FormValues>();

  ngOnChanges() {
    this.formSub?.unsubscribe();
    this.statusUnsub?.();

    if (this.entity) {
      this.form = this.settings.formFactory!(this.entity);
    } else {
      this.form = this.settings.formFactory!(undefined);
    }

    runInInjectionContext(this.environmentInjector, () => {
      this.formSub = watchFormErrors(this.form);

      this.statusUnsub = formHandleMutationStatus(this.status$, this.form, () => this.closeDialog());
    });
  }

  ngOnInit() {
    this.destroyRef.onDestroy(() => {
      this.formSub?.unsubscribe();
      this.statusUnsub?.();
    });
  }

  closeDialog() {
    this.close.emit();
  }

  commitDialogSave() {
    this.save.emit(this.form.value as FormValues);
  }

  commitDialogApprove() {
    this.approve.emit(this.form.value as FormValues);
  }

  controls() {
    return this.form.controls;
  }

  controlErrors(controlName: string) {
    const errors = this.controls()[controlName].errors ?? {};
    return Object.values(errors);
  }

  onRootClick($event: MouseEvent) {
    $event.stopPropagation();
  }

  isNew() {
    return !this.entity?.id;
  }

  isDraft() {
    return this.draftMode() ? !this.entity || !this.entity.common.isApproved : false;
  }

  draftMode() {
    return this.settings.draftMode;
  }

  title() {
    const value = this.form.value as T;

    const isCloseDraft = value.common?.isClosed;
    const closeTitle = isCloseDraft ? 'close (remove)' : '';

    const { entityDescription } = this.settings;

    const entityDescr =
      typeof entityDescription === 'function'
        ? entityDescription(this.approveForEntity)
        : entityDescription;

    const entityDescrNoCode =
      typeof entityDescription === 'function'
        ? entityDescription()
        : entityDescription;

    if (this.draftMode()) {
      return this.approveForEntity
        ? `Edit ${closeTitle} draft for ${entityDescr}`
        : this.entity?.common.isApproved
          ? `Edit new ${closeTitle} draft for this ${entityDescr}`
          : `Edit ${closeTitle} draft for new ${entityDescrNoCode}`;
    } else {
      return this.entity?.id
        ? `Edit ${entityDescr}`
        : `Edit new ${entityDescrNoCode}`;
    }
  }

  loading() {
    return this.status$?.pipe(map(s => s.state === 'loading'));
  }
}
