import { reject, map } from 'remeda';

export function removeEntity<T extends { id: string }>(ent: T) {
  return (arr: T[]) => reject<T>(arr, (e) => e.id === ent.id);
}

export function addEntity<T extends { id: string }>(ent: Omit<T, 'id'>) {
  return (arr: T[]): T[] => [...arr, ent as T];
}

export function updateEntity<T extends { id: string }>(merge: boolean) {
  return (ent: T) => (arr: T[]) =>
    map<T, T>(arr, (e) =>
      e.id === ent.id ? (merge ? { ...e, ...ent } : ent) : e,
    );
}

export function noopEntity<T extends { id: string }>() {
  return (arr: T[]) => arr;
}
