import { Component, ViewEncapsulation } from '@angular/core';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-form-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label>
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <ng-content></ng-content>
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldComponent extends BaseFieldComponent {}
