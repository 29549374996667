import { Component, EnvironmentInjector, Input, inject } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroupDirective,
} from '@angular/forms';

import { equals, path, memoizeWith } from 'ramda';

import { FORM_SOURCE_TOKEN } from './form-compare-source';
import { getFieldFullPath } from './get-field-full-path';

@Component({
  selector: 'app-label-change-tooltip',
  template: `
    <kendo-popover #popover position="top">
      <ng-template kendoPopoverBodyTemplate>
        Source value:
        <app-value-view
          [path]="getPathText()"
          [value]="getSourceRoot()"
          boolDisplay="text"
        />
      </ng-template>
    </kendo-popover>

    @if (isChangedFromSource()) {
    <span class="changed" kendoPopoverAnchor [popover]="popover" showOn="hover">
      {{ getTitle() }}
    </span>
    } @else {
    {{ getTitle() }}
    }
  `,
  styles: [
    `
      :host
        position: relative

      .changed
        background-color: var(--kendo-color-warning)
        padding: 0 4px
    `,
  ],
})
export class LabelChangeComponent {
  injector = inject(EnvironmentInjector);

  formGroup = inject(ControlContainer) as FormGroupDirective;

  formSource = inject(FORM_SOURCE_TOKEN, {
    optional: true,
  });

  @Input()
  title = '';

  @Input()
  field = '';

  control() {
    return this.formGroup.form.get(this.field) as FormControl;
  }

  getPathText = memoizeWith(
    () => this.field,
    () => this.getPath().join('.'),
  );

  getPath = memoizeWith(
    () => this.field,
    () => getFieldFullPath(this.field, this.formGroup),
  );

  getSourceRoot() {
    return this.formSource?.getCompareSource();
  }

  getSourceValue() {
    const root = this.getSourceRoot();
    return root ? path(this.getPath(), root) : undefined;
  }

  isChangedFromSource() {
    return (
      this.getSourceRoot() &&
      !equals(this.getSourceValue() ?? '', this.control().value ?? '')
    );
  }

  getTitle() {
    const validator = this.control().validator;
    const isRequired =
      validator && validator({} as AbstractControl)?.['required'];

    const requiredPrefix = isRequired ? '* ' : '';

    return requiredPrefix + this.title;
  }
}
