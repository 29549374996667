import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, inject } from '@angular/core';
import { UseQuery, UsePersistedQuery } from '@ngneat/query';

import { toObservable } from '@angular/core/rxjs-interop';

import { filter, map, switchMap } from 'rxjs/operators';

import { Observable, combineLatest } from 'rxjs';

import {
  GridQueryParams,
  defaultParamsSignal,
  mapSuccessQueryToGridResult,
} from '@/common/utils';
import { ApiError, EntitiesAuditEntity, Page } from '@/api';

import { normalizeGridQueryParams } from '@/common/grid-helpers';

const baseKey = ['audit', 'entities-audit-entity'];
const baseGetUrl = 'api/audit';

interface Params extends GridQueryParams {
  entityName: string;
  entityId: string;
}

@Injectable()
export class EntitiesAuditQueryService {
  http = inject(HttpClient);
  useQuery = inject(UseQuery);
  usePersistedQuery = inject(UsePersistedQuery);
  defaultParamsSignal = defaultParamsSignal();

  entitiesAuditQuery = this.usePersistedQuery(
    (key, params: Params | undefined) => {
      return {
        queryKey: key,
        queryFn: () => {
          const httpParams: Record<string, any> = {
            skip: params?.skip,
            take: params?.take,
            filter: params?.filter,
          };

          if (params?.sort?.length) {
            httpParams['sort'] = params.sort;
          }

          return this.http.get<Page<EntitiesAuditEntity[]>>(
            `/${baseGetUrl}/${params?.entityName}/${params?.entityId}`,
            {
              params: {
                params: JSON.stringify(httpParams),
              },
            },
          );
        },
        keepPreviousData: true,
        onError: (_error: ApiError) => undefined,
      };
    },
  );

  injectEntities(args: {
    entityName: string;
    id$: Observable<string>;
    paramsSignal: Signal<GridQueryParams>;
  }) {
    const { entityName, id$, paramsSignal = this.defaultParamsSignal } = args;

    const params$ = toObservable(normalizeGridQueryParams(paramsSignal));

    const idValidated$ = id$.pipe(filter(Boolean));

    return combineLatest([params$, idValidated$]).pipe(
      switchMap(([params, id]) => {
        const allParams: Params = {
          ...params,
          entityId: id,
          entityName,
        };
        return this.entitiesAuditQuery(
          [...baseKey, allParams],
          allParams,
        ).result$.pipe(map(mapSuccessQueryToGridResult((r) => r)));
      }),
    );
  }
}
