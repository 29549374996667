import { EnumList } from '@/common';

export enum ServicesProvisionBasis {
  FREEDOM_PROVIDE = 'FREEDOM_PROVIDE',
  FREEDOM_ESTABLISHMENT = 'FREEDOM_ESTABLISHMENT',
  IN_THIRD_COUNTRY = 'IN_THIRD_COUNTRY',
  N_A = 'N_A',
}

export const SERVICES_PROVISION_BASIS_LIST: EnumList = [
  {
    title: 'Freedom to provide services',
    value: ServicesProvisionBasis.FREEDOM_PROVIDE,
  },
  {
    title: 'Freedom of establishment',
    value: ServicesProvisionBasis.FREEDOM_ESTABLISHMENT,
  },
  {
    title: 'Provision of services in Third Country',
    value: ServicesProvisionBasis.IN_THIRD_COUNTRY,
  },
  {
    title: 'N/A',
    value: ServicesProvisionBasis.N_A,
  },
];
