import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  inject,
} from '@angular/core';

import { path } from 'ramda';

import {
  FIELD_META_DEFAULT,
  FIELD_META_PROVIDER_TOKEN,
  FieldMeta,
} from '../field-meta-provider';
import { FORM_SOURCE_TOKEN } from '../form-compare-source';

@Component({
  selector: 'app-value-view',
  templateUrl: './template.html',
  styles: [`
    :host
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 3
      overflow: hidden
      text-overflow: ellipsis
      word-wrap: break-word
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueViewComponent implements OnChanges {
  metaProvider = inject(FIELD_META_PROVIDER_TOKEN, {
    optional: true,
  });

  sourceProvider = inject(FORM_SOURCE_TOKEN, {
    optional: true,
  });

  @Input()
  value: any;

  @Input()
  path = '';

  @Input()
  boolDisplay: 'text' | 'checkbox' = 'checkbox';

  meta: FieldMeta = FIELD_META_DEFAULT;

  ngOnChanges() {
    this.meta = this.metaProvider?.getMeta(this.path) ?? {
      ...FIELD_META_DEFAULT,
      title: this.path,
    };
  }

  getValue(): any {
    const source =
      this.value !== undefined ? this.value : this.sourceProvider?.getSource();

    if (source !== undefined) {
      return path(this.path.split('.'), source);
    } else {
      return undefined;
    }
  }
}
