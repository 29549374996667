import { DestroyRef, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

export interface FieldError {
  formGroupName: string;
  fieldName: string;
  errorCode: string;
}

export function getFormErrors(
  control: AbstractControl,
  formGroupName: string,
  fieldName: string,
): FieldError[] {
  if (control instanceof FormGroup) {
    return Object.keys(control.controls).flatMap((controlName) => {
      const formControl = control.get(controlName);
      if (formControl) {
        const fGroupName = formGroupName + '-' + controlName;
        return getFormErrors(formControl, fGroupName, controlName);
      }
      return [];
    });
  }

  if (control instanceof FormArray) {
    return control.controls.flatMap((fControl: AbstractControl, index) => {
      const fGroupName = formGroupName + '-' + index;
      return getFormErrors(fControl, fGroupName, 'Array');
    });
  }

  if (control instanceof FormControl) {
    const controlErrors: ValidationErrors | null = control.errors;
    if (controlErrors) {
      return Object.entries(controlErrors).map(([errorCode, error]) => ({
        formGroupName,
        fieldName,
        errorCode,
        error
      }));
    }
  }
  return [];
}

export function watchFormErrors(form: FormGroup, destroyRefArg?: DestroyRef) {
  const destroyRef = destroyRefArg ?? inject(DestroyRef, { optional: true });

  const sub = form.statusChanges.subscribe(() => {
    if ((window as any).DEBUG.watchFormErrors) {
      console.log(
        'Form errors',
        getFormErrors(form, 'root', 'root'),
        form.value,
      );
    }
  });

  destroyRef?.onDestroy(() => sub.unsubscribe());

  return sub;
}
