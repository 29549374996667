import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-remove-dialog',
  template: `
    <kendo-dialog
      [title]="'Remove ' + entityNameUpperCased() + '?'"
      (close)="closeDialog()"
      autoFocusedElement="#cancel-button"
    >
      <div class="content">
        {{ 'Are you sure to remove this ' + entityNameUpperCased() + '?' }}
      </div>
      <kendo-dialog-actions>
        <button kendoButton id="cancel-button" (click)="closeDialog()">
          Cancel
        </button>
        <button kendoButton themeColor="primary" (click)="commitDialog()">
          Remove
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveDialogComponent {
  @Input()
  entityName = '';

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  commit = new EventEmitter<void>();

  closeDialog() {
    this.cancel.emit();
  }

  commitDialog() {
    this.commit.emit();
  }

  entityNameUpperCased() {
    const first = this.entityName[0] ?? '';
    const rest = this.entityName.slice(1);

    return first.toLocaleUpperCase() + rest;
  }
}
