import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule, ButtonModule } from '@progress/kendo-angular-buttons';

import { IconsModule } from '@progress/kendo-angular-icons';
import {
  GridModule,
  ExcelModule,
  PDFModule,
} from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LabelModule } from '@progress/kendo-angular-label';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { TooltipsModule, PopoverModule } from '@progress/kendo-angular-tooltip';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadModule } from '@progress/kendo-angular-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { FilterModule } from '@progress/kendo-angular-filter';
import { TypographyModule } from '@progress/kendo-angular-typography';

import { LayoutModule } from '@progress/kendo-angular-layout';

import { RxLet } from '@rx-angular/template/let';
import { AceModule, ACE_CONFIG, AceConfigInterface } from 'ngx-ace-wrapper';


const DEFAULT_ACE_CONFIG: AceConfigInterface = {
};

export * from './utils';
export * from './services';
export * from './store';

export {
  EntityStatusCommonFormValues,
  injectEntityStatusCommonFormGroup,
} from './forms';

export { NotFoundPageComponent } from '../pages/not-found-page';

import { GridCommandsComponent } from './components/grid-commands';
import { TreelistCommandsComponent } from './components/treelist-commands';
import { RemoveDialogComponent } from './components/remove-dialog';
import { HasRoleDirective } from './components/has-role-directive';
import { HasPermissionDirective } from './components/has-permission-directive';
import { formComponents } from './components/form';
import { gridColumnControls } from './components/grid';
import { GridProfilesComponent } from './components/grid-profiles';
import { entityGridComponents } from './components/entity-grid';
import { EntityViewDialogComponent } from './components/entity-view-dialog';
import { EntityEditDialogComponent } from './components/entity-edit-dialog';
import { gridFormComponents } from './components/grid-form';
import { BasicEntityGridComponent } from './components/basic-entity-grid';
import { FileUploaderComponent } from './components/file-uploader';

import { uiSettingsFeature, UiSettingsEffects } from './store';

import { EntitiesAuditQueryService } from './services';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    GridCommandsComponent,
    RemoveDialogComponent,
    TreelistCommandsComponent,
    HasRoleDirective,
    HasPermissionDirective,
    BasicEntityGridComponent,
    EntityViewDialogComponent,
    EntityEditDialogComponent,
    GridProfilesComponent,
    FileUploaderComponent,
    ...entityGridComponents,
    ...formComponents,
    ...gridFormComponents,
    ...gridColumnControls,
  ],
  providers: [
    EntitiesAuditQueryService,
    {
      provide: ACE_CONFIG,
      useValue: DEFAULT_ACE_CONFIG
    },
  ],
  imports: [
    AceModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    InputsModule,
    ButtonModule,
    ButtonsModule,
    IconsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    DialogsModule,
    LabelModule,
    TreeListModule,
    DropDownsModule,
    TooltipsModule,
    PopoverModule,
    PopupModule,
    DateInputsModule,
    LayoutModule,
    FilterModule,
    TypographyModule,
    UploadModule,
    RxLet,
    StoreModule.forFeature(uiSettingsFeature),
    EffectsModule.forFeature(UiSettingsEffects),
  ],
  exports: [
    GridCommandsComponent,
    RemoveDialogComponent,
    TreelistCommandsComponent,
    HasRoleDirective,
    HasPermissionDirective,
    BasicEntityGridComponent,
    EntityViewDialogComponent,
    GridProfilesComponent,
    FileUploaderComponent,
    ...entityGridComponents,
    ...formComponents,
    ...gridFormComponents,
    ...gridColumnControls,
  ],
})
export class CommonUtilsModule { }
