import { inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Country } from '@/api';

import { requiredValidatorMsg } from '../utils';

export function injectAddressFormGroup(required = true) {
  const fb = inject(FormBuilder);
  return fb.group({
    addressLine: ['', []],
    city: ['', []],
    province: ['', []],
    state: ['', []],
    country: [
      null as Country | null,
      required ? [requiredValidatorMsg('Country is required')] : [],
    ],
  });
}
