import { Component, Input, OnInit } from '@angular/core';

import { BaseFieldComponent } from '../base-field';
import {
  LookupColumnData,
  emptyLookupColumnData,
} from '../field-meta-provider';

@Component({
  selector: 'app-lookup-dropdown-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip
          [title]="getTitle()"
          [field]="getField()"
        />
      </kendo-label>
      <app-lookup-dropdown-control
        #field
        [formControl]="control()"
        [valuePrimitive]="valuePrimitive"
        [placeholder]="getTitle()"
        [lookupData]="metaLookupData ?? lookupData ?? emptyLookupColumnData"
      />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class LookupDropdownFieldComponent
  extends BaseFieldComponent
  implements OnInit {
  @Input()
  lookupData?: LookupColumnData;

  @Input()
  valuePrimitive = true;

  metaLookupData: LookupColumnData | undefined;

  emptyLookupColumnData = emptyLookupColumnData;

  getLookupData() {
    return this.metaLookupData ?? this.lookupData ?? emptyLookupColumnData;
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.meta) {
      this.metaLookupData = this.meta.compositeType === 'simple' && this.meta.type === 'lookup'
        ? this.meta.lookupData
        : undefined;
    }
  }
}
