<kendo-combobox
  [data]="profiles()"
  [allowCustom]="false"
  [popupSettings]="{ width: 'auto' }"
  [clearButton]="false"
  textField="name"
  valueField="name"
  placeholder="Select profile"
  (valueChange)="changeProfile($event)"
  [value]="currentProfile()"
  [valuePrimitive]="true"
>
</kendo-combobox>

<button 
  kendoButton 
  (click)="onRenameClick()"
>
  Rename profile
</button>

<button 
  kendoButton 
  (click)="onCloneClick()"
>
  Clone profile
</button>

<button 
  kendoButton 
  (click)="onRemoveClick()"
>
  Remove profile
</button>

<kendo-dialog
  *ngIf="showRemoveDialog()"
  title="Remove profile?"
  (close)="closeRemoveDialog()"
  autoFocusedElement="#cancel-button"
>
  <div class="content">
    Are you sure to remove profile '{{ currentProfile() }}'?
  </div>
  <kendo-dialog-actions>
      <button 
        kendoButton 
        id="cancel-button" 
        (click)="closeRemoveDialog()"
      >
        Cancel
      </button>
      <button
        kendoButton 
        themeColor="primary"
        (click)="commitRemoveDialog()"
      >
        Remove
      </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  *ngIf="showCloneDialog()"
  title="Clone profile"
  (close)="closeCloneDialog()"
  autoFocusedElement="#cancel-button"
>
  <div class="clone-content">
    Please enter new profile name
  </div>
  <div>
    <kendo-textbox 
      placeholder="Profile name" 
      [(value)]="newProfileName"
      [class]="'profile-text-box'"
    >
    </kendo-textbox>
  </div>
  <kendo-dialog-actions>
      <button 
        kendoButton 
        id="cancel-button" 
        (click)="closeCloneDialog()"
      >
        Cancel
      </button>
      <button 
        kendoButton 
        themeColor="primary"
        (click)="commitCloneDialog()"
        [disabled]="!newProfileName || newProfileName === currentProfile()"
      >
        Clone
      </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  *ngIf="showRenameDialog()"
  title="Rename profile"
  (close)="closeRenameDialog()"
  autoFocusedElement="#cancel-button"
>
  <div class="clone-content">
    Please enter new name of profile
  </div>
  <div>
    <kendo-textbox 
      placeholder="Profile name" 
      [(value)]="newProfileName"
      [class]="'profile-text-box'"
    >
    </kendo-textbox>
  </div>
  <kendo-dialog-actions>
      <button 
        kendoButton 
        id="cancel-button" 
        (click)="closeRenameDialog()"
      >
        Cancel
      </button>
      <button 
        kendoButton 
        themeColor="primary"
        (click)="commitRenameDialog()"
        [disabled]="!newProfileName || newProfileName === currentProfile()"
      >
        Rename
      </button>
  </kendo-dialog-actions>
</kendo-dialog>