import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { exhaustMap, catchError, tap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';

import { AccessToken, ApiErrorCodes } from '@/api';
import { RoutingActions } from '@/routing';

import { AuthSlice, selectAuthAccessToken, AuthActions } from '../store';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AuthSlice>,
    private authService: AuthService,
    private logger: NGXLogger,
  ) {}

  isUnauthorizedError(error: unknown) {
    return (
      error instanceof HttpErrorResponse &&
      error.status === ApiErrorCodes.Unauthorized
    );
  }

  isRefreshLogoutError(error: unknown) {
    return this.isUnauthorizedError(error);
  }

  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const cloneRequestWithAccessToken = (accessToken: AccessToken) =>
      httpRequest.clone({
        setHeaders: { Authentication: 'Bearer ' + accessToken.token },
      });

    const accessToken = this.store.selectSignal(selectAuthAccessToken)();
    const request =
      accessToken && !this.authService.isPublicUrl(httpRequest.url)
        ? cloneRequestWithAccessToken(accessToken)
        : httpRequest;

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          this.isUnauthorizedError(error) &&
          this.authService.isRefreshApplicable(httpRequest.url)
        ) {
          this.logger.debug(
            'Error Unauthorized: access token needs to be refreshed',
            httpRequest.url,
          );
          return this.authService.refreshToken().pipe(
            map((response) => response.accessToken),
            tap((accessToken) => {
              this.logger.debug(
                'Access token updated, retry request',
                httpRequest.url,
                accessToken,
              );
              this.store.dispatch(
                AuthActions.refreshAccessToken({ accessToken }),
              );

              AuthService.saveAuthLocalStorage({
                accessToken,
              });
            }),
            exhaustMap((accessToken) =>
              next.handle(cloneRequestWithAccessToken(accessToken)),
            ),
            catchError((error) => {
              if (this.isRefreshLogoutError(error)) {
                AuthService.clearAuthLocalStorage();
                this.store.dispatch(AuthActions.logout());
                this.store.dispatch(RoutingActions.navigateToLogin({}));
              }
              return throwError(() => error);
            }),
          );
        }
        this.logger.warn('Token refresh not required');
        return throwError(() => error);
      }),
    );
  }
}
