import { GridsState } from './grids.state';

export interface UiSettingsState {
  grids: GridsState;
}

export const uiSettingsFeatureKey = 'ui-settings';

export type UiSettingsSlice = {
  [uiSettingsFeatureKey]: UiSettingsState;
};
