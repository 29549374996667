import {
  ChangeDetectionStrategy,
  Component,
  EnvironmentInjector,
  Input,
  OnInit,
  ViewChild,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import {
  pencilIcon,
  trashIcon,
  saveIcon,
  cancelIcon,
} from '@progress/kendo-svg-icons';

import { FormBuilder, FormControl } from '@angular/forms';

import { BaseEntity, Permission } from '@/api';

import { injectBaseEntityQueryService, requiredValidatorMsg } from '@/common';

import { injectEditHelper, injectQueryParams } from '@/common/grid-helpers';
import { formHandleMutationStatus } from '../form';

@Component({
  selector: 'app-basic-entity-grid',
  templateUrl: './template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicEntityGridComponent implements OnInit {
  icons = {
    pencilIcon,
    trashIcon,
    saveIcon,
    cancelIcon,
  };

  Permission = Permission;

  @ViewChild('grid') grid!: GridComponent;

  columns = [
    {
      field: 'code',
      title: 'Code',
    },
    {
      field: 'description',
      title: 'Description',
    },
  ] as const;

  @Input()
  entityName = '';

  @Input()
  entityDescription = '';

  @Input()
  permission: Permission | Permission[] = [];

  ////////
  service!: ReturnType<typeof injectBaseEntityQueryService>;

  entities$!: ReturnType<typeof this.service.injectEntities>['result$'];

  mutations!: ReturnType<typeof this.service.injectEntities>['mutations'];

  isUpdating$!: (typeof this.mutations)['isUpdating$'];

  formBuilder = inject(FormBuilder);
  form = this.formBuilder.group({
    code: ['', [requiredValidatorMsg('Code is required')]],
    description: ['', [requiredValidatorMsg('Description is required')]],
  });

  injector = inject(EnvironmentInjector);

  queryParams = injectQueryParams({
    initialSort: [
      {
        field: 'code',
        dir: 'asc',
      },
    ],
  });
  params = this.queryParams.params;
  pageChange = this.queryParams.pageChange;
  filterChange = this.queryParams.filterChange;
  sortChange = this.queryParams.sortChange;

  ////////
  editHelpers!: ReturnType<
    typeof injectEditHelper<
      BaseEntity,
      object,
      BaseEntity,
      Record<keyof Omit<BaseEntity, 'id'>, FormControl<any>>
    >
  >;

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
      this.service = injectBaseEntityQueryService({
        entityName: this.entityName,
      });

      const entities = this.service.injectEntities(this.queryParams.params);

      this.entities$ = entities.result$;
      this.mutations = entities.mutations;

      formHandleMutationStatus(this.mutations.currentStatus$, this.form);

      this.isUpdating$ = this.mutations.isUpdating$;

      this.editHelpers = injectEditHelper({
        form: this.form,
        grid: () => this.grid,
        mutations: this.mutations,
      });
    });
  }
}
