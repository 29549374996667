import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { map } from 'rxjs/operators';

import { LookupColumnData } from '../form/field-meta-provider';
import { EnumList } from '../../utils';

@Component({
  selector: 'app-lookup-column-control',
  template: `
    <app-enum-column-control
      [value]="value"
      [titles]="(getTitles() | async) ?? []"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupColumnControlComponent {
  @Input()
  value!: any | null;

  @Input()
  lookupData!: LookupColumnData;

  @Input()
  descriptionField?: string;

  getTitles() {
    return this.lookupData.pipe(
      map((r) => r.data ?? []),
      map(
        (ents): EnumList =>
          ents.map((e: any) => ({
            title: this.descriptionField
              ? e[this.descriptionField]
              : (e.description ?? e.code),
            value: e.id,
          })),
      ),
    );
  }
}
