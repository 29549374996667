import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '@progress/kendo-angular-notification';

import { ApiErrorCodes, ApiErrorData } from '@/api';
import { isServerValidationError } from '@/api/types/api-error';

function translateApiErrorData(data: ApiErrorData | string) {
  if (typeof data === 'string') {
    return data;
  } else if ('messageType' in data) {
    switch (data.messageType) {
      case 'user.deleting.bound-error':
        return 'User is referenced by some users and can\'t be removed';

      case 'user-role.deleting.bound-error':
        return `Role ${data.userRole.name} is referenced by some users and can't be removed`;

      case 'currency.deleting.bound-error':
        return `Currency ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'region.deleting.bound-error':
        return `Region ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'crm-client-group.deleting.bound-error':
        return `Client group ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'identifier-type.deleting.bound-error':
        return `Identifier type ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'viewing-entity.deleting.bound-error':
        return `Viewing entity ${data.viewingEntity.code} is referenced by some clients, users or other entities and can't be removed`;

      case 'viewing-entity.deleting.root':
        return `Viewing entity ${data.viewingEntity.code} is root and can't be removed`;

      case 'user.readonly':
        return `User '${data.user.name}' is read only`;

      case 'user-role.readonly':
        return `User role '${data.userRole.name}' is read only`;

      case 'client.activate-closed':
        return "Can't activate closed client";

      case 'client.activate-not-approved':
        return "Can't activate non-approved client";

      case 'client.approve-closed':
        return "Can't approve closed client";

      case 'client.approve-own':
        return "Client author can't approve a client. Another user should approve.";

      case 'country.deleting.bound-error':
        return `Country ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'crm-legal-entity-type.deleting.bound-error':
        return `Legal entity type ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'crm-legal-form.deleting.bound-error':
        return `Legal form ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'crm-portfolio-group.deleting.bound-error':
        return `Portfolio group ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'crm-regulatory-category-le.deleting.bound-error':
      case 'crm-regulatory-category-ic.deleting.bound-error':
        return `Regulatory category ${data.entity.code} is referenced by some entities and can't be removed`;

      case 'entity.edit.edit-approved':
        return 'Approved entity can not be edited. Create edit draft instead.';

      case 'entity.delete.delete-approved':
        return 'Approved entity can not be deleted. Create delete draft instead.';

      case 'entity.approve.bad-draft':
        return 'Wrong state (approved set) of draft entity';

      case 'entity.update.draft.change-approve-for':
        return 'Can\'t change draft target on update';

      case 'entity.approve.bad-source':
        return 'Wrong state (approved not set) of source entity';

      default: {
        const data_: never = data;
        console.log('Unknown error', data_);
        return 'Unknown error';
      }
    }
  } else if (isServerValidationError(data)) {
    return [];
  } else if ('status' in data) {
    switch (data.status) {
      case ApiErrorCodes.Unauthorized:
        return 'Session expired. Please relogin';
      case ApiErrorCodes.Forbidden:
        return 'Resource is forbidden for current user';
      case ApiErrorCodes.BadRequest:
        return data.message;
    }
  }
  return 'Unknown error';
}

@Injectable({ providedIn: 'root' })
export class ApiNotificationInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) { }

  lastMessage = '';
  lastMessageTime = 0;

  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(httpRequest).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        const loginFailed =
          errorResponse.error.status === ApiErrorCodes.Unauthorized &&
          errorResponse.url?.endsWith('login');

        if (!loginFailed) {
          const content = errorResponse.error
            ? translateApiErrorData(errorResponse.error)
            : 'Unknown error';

          const contentArr = Array.isArray(content) ? content : [content];

          const now = Date.now();

          contentArr.forEach((str) => {
            if (str !== this.lastMessage || now - this.lastMessageTime > 2000) {
              this.notificationService.show({
                content: str,
                type: {
                  style: 'error',
                  icon: false,
                },
                hideAfter: 1000,
                closable: true,
              });
            }
            this.lastMessage = str;
            this.lastMessageTime = now;
          });
        }
        return throwError(() => errorResponse);
      }),
    );
  }
}
