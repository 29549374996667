import { Component, ElementRef, Input, ViewChild, signal } from '@angular/core';

import { ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';

import { warningTriangleIcon } from '@progress/kendo-svg-icons';
import { ReplaySubject } from 'rxjs';

import { User } from '@/api';

export interface ActivateFormData {
  isApproved: FormControl<boolean>;
  isActive: FormControl<boolean>;
  isClosed: FormControl<boolean>;
  createdBy: FormControl<User>;
}

@Component({
  templateUrl: `./template.html`,
  styleUrls: ['./styles.sass'],
})
export class BaseControlComponent implements ControlValueAccessor {
  @ViewChild('inner', { static: true }) inner!: ElementRef<HTMLInputElement>;

  onChangeFn: any = null;
  onTouchedFn: any = null;

  value = signal(false);
  disabled = signal(false);
  disabledInternal = signal(false);
  disabledTitle = signal('');

  public icons = {
    warning: warningTriangleIcon,
  };

  readonly form$ = new ReplaySubject<FormGroup<ActivateFormData>>();

  @Input()
  entityName = 'item';

  @Input()
  set form(value: FormGroup<ActivateFormData>) {
    this.form$.next(value);
  }

  isDisabled() {
    return this.disabledInternal() || this.disabled();
  }

  onChange($event: Event) {
    const input = $event.target as HTMLInputElement;
    this.value.set(input.checked);
    this.onChangeFn(this.value());
  }

  writeValue(obj: any): void {
    this.value.set(obj);
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  focus() {
    if (this.inner) {
      this.inner.nativeElement.focus();
      this.inner.nativeElement.click();
    }
  }
}
