import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import {
  moreHorizontalIcon,
  pencilIcon,
  trashIcon,
  saveIcon,
  cancelIcon,
  eyeIcon,
} from '@progress/kendo-svg-icons';

import { watchFormErrors } from '@/common/form-helpers';
import { saveButtonStatus } from '../form';

@Component({
  selector: 'app-grid-commands',
  templateUrl: './grid-commands.component.html',
  styleUrls: ['./grid-commands.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCommandsComponent implements OnInit {
  icons = {
    moreHorizontalIcon,
    pencilIcon,
    trashIcon,
    saveIcon,
    cancelIcon,
    eyeIcon,
  };

  formGroup = inject(ControlContainer, {
    optional: true,
  }) as FormGroupDirective;

  changeDetectorRef = inject(ChangeDetectorRef);
  destroyRef = inject(DestroyRef);

  @Input()
  disabled = false;

  @Input()
  disabledTooltip = '';

  @Input()
  editing = false;

  @Input()
  readOnly = false;

  @Input()
  editButtonTitle: string | null = '';

  @Input()
  viewButtonTitle: string | null = '';

  @Input()
  deleteButtonTitle: string | null = '';

  form() {
    return this.formGroup?.form;
  }

  saveButtonStatus() {
    const form = this.form();

    return saveButtonStatus(form);
  }

  ngOnInit() {
    const form = this.form();
    if (form) {
      const sub = form.statusChanges.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });

      this.destroyRef.onDestroy(() => sub.unsubscribe());

      watchFormErrors(form, this.destroyRef);
    }
  }
}
