import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { UsePersistedQuery } from '@ngneat/query';

import { ApiError } from '@/api';

import { GridQueryParams } from '../utils';

export function injectPersistedQuery<Response>(baseUrl: string) {
  const http = inject(HttpClient);
  const usePersistedQuery = inject(UsePersistedQuery);

  return usePersistedQuery((key, params: GridQueryParams | undefined) => {
    return {
      queryKey: key,
      queryFn: () => {
        const httpParams: Record<string, any> = {
          skip: params?.skip,
          take: params?.take,
          filter: params?.filter,
        };

        if (params?.sort?.length) {
          httpParams['sort'] = params.sort;
        }

        return http.get<Response>(baseUrl, {
          params: {
            params: JSON.stringify(httpParams),
          },
        });
      },
      keepPreviousData: true,
      refetchOnMount: false,
      onError: (_error: ApiError) => {
        //
      },
    };
  });
}
