import { createActionGroup, emptyProps, props } from '@ngrx/store';

interface NavigateRoute {
  path: [string, ...string[]];
  state?: object;
}

interface NavigateToLogin {
  returnPath?: string;
}

export const RoutingActions = createActionGroup({
  source: 'Routing',
  events: {
    Navigate: props<NavigateRoute>(),

    NavigateToLogin: props<NavigateToLogin>(),

    ReturnFromLogin: emptyProps(),
  },
});
