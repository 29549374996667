import { computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  ColumnComponent,
  ColumnReorderEvent,
  ColumnResizeArgs,
  ColumnVisibilityChangeEvent,
} from '@progress/kendo-angular-grid';

import {
  ColumnResizeArgs as TreeListColumnResizeArgs,
  ColumnVisibilityChangeEvent as TreeListColumnVisibilityChangeEvent,
  ColumnReorderEvent as TreeListColumnReorderEvent,
} from '@progress/kendo-angular-treelist';

import { zipObj, uniq } from 'remeda';

import {
  UiSettingsActions,
  selectGridColumnSettings,
  selectGridColumns,
} from '../store';

export function injectGridSettings<ColumnName extends string>(settings: {
  gridName: string;
  allColumns: ColumnName[];
  defaultColumnsVisible?: ColumnName[];
}) {
  const { gridName, allColumns, defaultColumnsVisible } = settings;

  const store = inject(Store);

  const removeLegacyColumns = (columns: string[]): ColumnName[] =>
    uniq(
      columns.filter((col) =>
        (allColumns as string[]).includes(col),
      ) as ColumnName[],
    );

  const columnSettingsSelected = store.selectSignal(
    selectGridColumnSettings(gridName),
  );

  const gridColumnsSelected = store.selectSignal(selectGridColumns(gridName));

  const columnSettings = computed(() => {
    const settings = columnSettingsSelected();
    const columns = removeLegacyColumns(Object.keys(settings));

    return columns.reduce(
      (result, col) => ({
        ...result,
        [col]: settings[col],
      }),
      {} as typeof settings,
    );
  });

  const displayedColumns = computed(() => {
    const selected = gridColumnsSelected();

    if (selected) {
      const filtered = removeLegacyColumns(selected);
      const newColumns = allColumns.filter((col) => !filtered.includes(col));
      return [...filtered, ...newColumns];
    } else {
      return allColumns;
    }
  });

  const columnWidth = (name: ColumnName) =>
    columnSettings()[name]?.width ?? 100;

  const columnHidden = (name: ColumnName) => {
    const defaultVisible = defaultColumnsVisible
      ? defaultColumnsVisible.includes(name)
      : true;
    const visible = columnSettings()[name]?.visible ?? defaultVisible;
    return !visible;
  };

  const columnReorder = (
    args: ColumnReorderEvent | TreeListColumnReorderEvent,
  ) => {
    const columns = displayedColumns();
    const newColumns = [...columns];
    newColumns[args.oldIndex] = columns[args.newIndex];
    newColumns[args.newIndex] = columns[args.oldIndex];

    store.dispatch(
      UiSettingsActions.setColumnsOrder({
        grid: gridName,
        columns: newColumns,
      }),
    );
  };

  const columnResize = (
    args: (ColumnResizeArgs | TreeListColumnResizeArgs)[],
  ) => {
    args.forEach((arg) => {
      const column = arg.column as ColumnComponent;
      if (column.field) {
        const width = arg.newWidth;
        store.dispatch(
          UiSettingsActions.resizeColumns({
            grid: gridName,
            settings: [
              {
                width,
                column: column.field,
              },
            ],
          }),
        );
      }
    });
  };

  const columnVisibilityChange = ({
    columns,
  }: ColumnVisibilityChangeEvent | TreeListColumnVisibilityChangeEvent) => {
    const names = columns.map((col) => (col as ColumnComponent).field);
    const visibles = columns.map((col) => !col.hidden);

    store.dispatch(
      UiSettingsActions.setColumnsVisibility({
        grid: gridName,
        columns: zipObj(names, visibles),
      }),
    );
  };

  return {
    columnSettings,
    displayedColumns,
    columnWidth,
    columnHidden,
    columnReorder,
    columnResize,
    columnVisibilityChange,
  };
}
