import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { capitalize } from '@/common';

@Component({
  selector: 'app-reject-draft-dialog',
  template: ` <kendo-dialog
    [title]="'Reject a draft: ' + entityNameUpperCased() + '?'"
    (close)="closeDialog()"
    autoFocusedElement="#cancel-button"
  >
    <div class="content">
      {{ 'Are you sure to reject ' + entityNameUpperCased() + '?' }}
    </div>
    <kendo-dialog-actions>
      <button kendoButton id="cancel-button" (click)="closeDialog()">
        Cancel
      </button>
      <button kendoButton themeColor="primary" (click)="commitDialog()">
        Reject
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectDraftDialogComponent {
  @Input()
  entityName = '';

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  commit = new EventEmitter<void>();

  closeDialog() {
    this.cancel.emit();
  }

  commitDialog() {
    this.commit.emit();
  }

  entityNameUpperCased() {
    return capitalize(this.entityName);
  }
}
