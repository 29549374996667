import { path } from 'ramda';

import { assert, deCapitalize } from "@/common/utils";

import { 
  FieldMetaGroupComposite, 
  FieldMetaGroupSingle, 
  FieldMetaGroups, 
  FieldMetaObject,
  FieldPresenceType, 
  ColumnMeta,
  isFieldMetaGroupComposite, 
  isFieldMetaGroupSingle, 
  FieldMetaGroupsFlat,
  FieldMetaGroup
} from "./field-meta-provider";


const isPresent = (presence: FieldPresenceType[], forPresence: FieldPresenceType) => presence.includes(forPresence);

const generateMetaObjectColumns =  (forPresence: FieldPresenceType) => 
  (prefixField: string, metaObject: FieldMetaObject): ColumnMeta[] => 
{
  if  (!isPresent(metaObject.presence, forPresence)) {
    return [];
  }
  
  const objMeta: ColumnMeta[] = forPresence === 'full' ? [[prefixField, metaObject]] : [];

  const colMetas = Object.entries(metaObject.fields).flatMap(([field, meta]): ColumnMeta[] => {
    if (!isPresent(meta.presence, forPresence)) {
      return [];
    }

    const fullField = prefixField + '.' + field;

    const withShortPrefx = (title: string) => metaObject.shortPrefix 
      ? metaObject.shortPrefix + ' ' + deCapitalize(title) 
      : title;

    switch (meta.compositeType) {
      case 'simple': return [[
        fullField,
        {
          ...meta,
          titlePrefixed: withShortPrefx(meta.title)
        }
      ]];

      case 'object': 
        return generateMetaObjectColumns(forPresence)(fullField, meta).map(([field, meta]) => ([
          field, 
          {
            ...meta,
            titlePrefixed: withShortPrefx(meta.title)
          }
        ]));
    }
    return [];
  });

  return objMeta.concat(colMetas);
}


export const generateColumnsFieldMeta = (groups: FieldMetaGroups) => (forPresence: FieldPresenceType) => {
  const genMetaObjectColumns = generateMetaObjectColumns(forPresence);
  const getGroupMeta = (group: FieldMetaGroupSingle | FieldMetaGroupComposite): ColumnMeta[] => {
    if (isFieldMetaGroupSingle(group)) {
      const [compositeField, compositeMeta] = group;
      if (compositeMeta.compositeType === 'object') {
        return genMetaObjectColumns(compositeField, compositeMeta);
      } else if (compositeMeta.compositeType === 'array') {
        return isPresent(compositeMeta.presence, forPresence) 
          ? genMetaObjectColumns(compositeField, compositeMeta.elementMeta) 
          : [];
      } else {
        throw new Error('Wrong composite type');
      }
    } else if (isFieldMetaGroupComposite(group)) {
      return Object.entries(group).flatMap(([field, meta]) => {
        if (!isPresent(meta.presence, forPresence) ) {
          return [];
        }
        switch (meta.compositeType) {
          case 'simple': return [[field, meta]];
          case 'object': return genMetaObjectColumns(field, meta);
          default: 
            throw new Error('Wrong composite type');
        }
      });
    } else {
      throw new Error('Unknown group');
    }
  };

  return Object.values(groups).flatMap(getGroupMeta);
};

export const generateViewCardGroups = (groups: FieldMetaGroups, value: any): FieldMetaGroupsFlat => {
  const genMetaObjectColumns = generateMetaObjectColumns('viewCard');

  const getGroupMeta = ([groupName, group]: [groupName: string, group: FieldMetaGroup]): FieldMetaGroupsFlat => {
    if (isFieldMetaGroupSingle(group)) {
      const [compositeField, compositeMeta] = group;
      if (compositeMeta.compositeType === 'object') {
        return [[groupName, genMetaObjectColumns(compositeField, compositeMeta)]];
      } else if (compositeMeta.compositeType === 'array') {
        const arrayValue = path(compositeField.split('.'), value);
        assert(Array.isArray(arrayValue));

        const elementMeta = compositeMeta.elementMeta;
        
        return arrayValue.flatMap(
          (_v, index) => {
            const groupName = elementMeta.title + ' ' + (compositeMeta.prefixStartIndex + index);
            const groupMeta: FieldMetaObject = {
              ...elementMeta,
              title: groupName
            };
            return [[groupName, genMetaObjectColumns(compositeField + '.' + index, groupMeta)]];
          }
        );
      } else {
        throw new Error('Wrong composite type');
      }
    } else if (isFieldMetaGroupComposite(group)) {
      const columnMetas: ColumnMeta[] = Object.entries(group).flatMap(([field, meta]) => {
        switch (meta.compositeType) {
          case 'simple': return [[field, meta]];
          case 'object': return genMetaObjectColumns(field, meta);
          default: 
            throw new Error('Wrong composite type');
        }
      });

      return [[groupName, columnMetas]];
    } else {
      throw new Error('Unknown group');
    }
  };

  return Object.entries(groups).flatMap(getGroupMeta);
};
