import {
  Component,
  EnvironmentInjector,
  Input,
  QueryList,
  TemplateRef,
  ViewChildren,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';

import { capitalize } from '@/common';

type FormArrayType = FormGroup<any>;

interface ItemTemplateContext {
  itemForm: FormArrayType;
  index: number;
  isLast: boolean;
  isFirst: boolean;
}

@Component({
  selector: 'app-form-array-sections',
  template: `
    <kendo-expansionpanel
      *ngFor="
        let form of formArray.controls;
        index as i;
        last as isLast;
        first as isFirst
      "
      [subtitle]="capitalize(itemName) + ' ' + (startNumber + i + 1)"
      [expanded]="true"
    >
      <div class="section">
        <ng-container
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{
            itemForm: form,
            index: i,
            isLast: isLast,
            isFirst: isFirst
          }"
        >
        </ng-container>

        <div [class]="'buttons'" *ngIf="!isDisabled()">
          <button
            kendoButton
            (click)="onRemoveClick(i)"
            *ngIf="minLength === 0 || !(isLast && isFirst)"
          >
            Remove
          </button>
          <button kendoButton (click)="onAddClick()" *ngIf="isLast">
            Add new {{ itemName.toLowerCase() }}
          </button>
        </div>
      </div>
    </kendo-expansionpanel>
    <kendo-expansionpanel
      [subtitle]="capitalize(itemName) + 's'"
      [expanded]="true"
      *ngIf="!isDisabled() && formArray.controls.length === 0"
    >
      <div class="add-first-section">
        <button kendoButton (click)="onAddClick()">
          Add new {{ itemName.toLowerCase() }}
        </button>
      </div>
    </kendo-expansionpanel>
  `,
  styleUrls: ['./styles.sass'],
  host: {
    '[class.expanded-last]': 'isExpandedLast()',
  },
})
export class FormArraySectionsComponent {
  @Input()
  formArray!: FormArray<FormArrayType>;

  @ViewChildren(ExpansionPanelComponent)
  panels!: QueryList<ExpansionPanelComponent>;

  @Input()
  itemName = 'Item';

  @Input()
  itemTemplate!: TemplateRef<ItemTemplateContext>;

  @Input()
  formFactory!: () => FormArrayType;

  @Input()
  minLength: 0 | 1 = 1;

  @Input()
  startNumber = 0;

  injector = inject(EnvironmentInjector);

  capitalize = capitalize;

  isExpandedLast() {
    return this.panels?.last?.expanded ?? false;
  }

  isDisabled() {
    return this.formArray.root.disabled;
  }

  onRemoveClick(i: number) {
    this.formArray.removeAt(i);
    this.formArray.markAsDirty();
  }

  onAddClick() {
    runInInjectionContext(this.injector, () => {
      this.formArray.push(this.formFactory());
      this.formArray.markAsDirty();
    });
  }
}
