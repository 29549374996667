import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthLoginData, AuthData, RefreshTokenData, User } from '@/api';

const loginUrl = '/api/auth/login';
const logoutUrl = '/api/auth/logout';
const refreshUrl = '/api/auth/refresh-token';
const currentUserUrl = '/api/user/current';

export type LoginResponse = AuthData;

@Injectable()
export class AuthService {
  constructor(private httpClient: HttpClient) {}

  login(loginData: AuthLoginData): Observable<LoginResponse> {
    return this.httpClient.post<AuthData>(loginUrl, loginData);
  }

  isRefreshApplicable(url: string) {
    return !this.isPublicUrl(url);
  }

  isPublicUrl(url: string) {
    return url === loginUrl || url === refreshUrl;
  }

  logout() {
    return this.httpClient.post(logoutUrl, {});
  }

  refreshToken() {
    return this.httpClient.post<RefreshTokenData>(refreshUrl, {});
  }

  getCurrentUser() {
    return this.httpClient.get<User>(currentUserUrl);
  }

  static saveAuthLocalStorage(data: Partial<AuthData>) {
    const prevData = AuthService.getAuthLocalStorage();
    localStorage.setItem(
      'auth',
      JSON.stringify({
        ...prevData,
        ...data,
      }),
    );
  }

  static getAuthLocalStorage() {
    const data = localStorage.getItem('auth');
    return data ? (JSON.parse(data) as AuthData) : undefined;
  }

  static clearAuthLocalStorage() {
    localStorage.removeItem('auth');
  }
}
