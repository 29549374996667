import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';

export interface ColumnSettings {
  width?: number;
  visible?: boolean;
}

export interface SortSettings {
  column: string;
  direction: 'asc' | 'desc';
}

export interface GridColumnSettings {
  columnSettings: Record<string, ColumnSettings | undefined>;
  columns?: string[];
  sort?: SortDescriptor[];
}

export interface GridColumnProfile {
  settings: GridColumnSettings;
  name: string;
}

export interface GridFilterProfile {
  filter: CompositeFilterDescriptor;
  name: string;
}

export interface GridState {
  columnProfiles: GridColumnProfile[];
  currentColumnProfile: string;

  filterProfiles: GridFilterProfile[];
  currentFilterProfile: string;
}

export interface GridsState {
  data: Record<string, GridState | undefined>;
  version: number;
}

export const initialColumnSettings: ColumnSettings = {
  width: undefined,
  visible: true,
};

export const initialGridColumnsSettings: GridColumnSettings = {
  columnSettings: {},
};

export const defaultColumnsProfile: GridColumnProfile = {
  settings: initialGridColumnsSettings,
  name: 'default',
};

export const defaultFilterProfile: GridFilterProfile = {
  filter: {
    filters: [],
    logic: 'and',
  },
  name: 'default',
};

export const initialGridState: GridState = {
  columnProfiles: [defaultColumnsProfile],
  currentColumnProfile: 'default',

  filterProfiles: [defaultFilterProfile],
  currentFilterProfile: 'default',
};

export const initialGridsState: GridsState = {
  data: {},
  version: 2,
};
