import { UiSettingsState, uiSettingsFeatureKey } from './ui-settings.state';

export class UiSettingsService {
  static getFromLocalStorage() {
    const data = localStorage.getItem(uiSettingsFeatureKey);
    return data ? (JSON.parse(data) as UiSettingsState) : undefined;
  }

  static saveToLocalStorage(state: UiSettingsState) {
    localStorage.setItem(uiSettingsFeatureKey, JSON.stringify(state));
  }
}
