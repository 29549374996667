import {
  ChangeDetectionStrategy,
  Component,
  EnvironmentInjector,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  runInInjectionContext,
} from '@angular/core';

import { path } from 'ramda';

import { Observable } from 'rxjs';

import { CollectionMutationStatus } from '@/common/utils';

import { injectGeneratedForm } from '@/common/form-helpers';

import { ENTITY_GRID_SETTINGS_TOKEN } from '../entity-grid-common';
import {
  FIELD_META_PROVIDER_TOKEN,
  FieldMetaGroupsFlat,
} from '../form/field-meta-provider';
import { FORM_SOURCE_TOKEN, saveButtonStatus, formHandleMutationStatus } from '../form';

@Component({
  selector: 'app-entity-edit-dialog',
  template: `
    <kendo-dialog
      title="View"
      (close)="close.emit()"
      autoFocusedElement="#cancel-button"
      class="history-dialog"
      [formGroup]="form" 
    >
      <div class="k-form-horizontal content">
        @for (meta of groupMeta; track meta[0]) {
        <kendo-expansionpanel [subtitle]="meta[0]" [expanded]="true">
          <div class="section">
            @for (colMeta of meta[1]; track colMeta[0]) {
            <app-value-edit-field [path]="colMeta[0]" />
            }
          </div>
        </kendo-expansionpanel>
        }
      </div>
      <kendo-dialog-actions>
        <button kendoButton id="cancel-button" (click)="close.emit()">
          Cancel
        </button>
        <button kendoButton class="enable-tooltip" themeColor="primary" kendoTooltip (click)="save.emit(form.value)"
          [disabled]="saveButtonStatus(form).disabled" [title]="saveButtonStatus(form).title">
          Save
        </button>        
      </kendo-dialog-actions>
    </kendo-dialog>
  `,
  styles: [
    `
      .content
        max-height: 70vh
        width: 700px
        overflow: auto

      .section
        display: flex
        flex-direction: column
        gap: 8px
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityEditDialogComponent implements OnInit {
  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);
  metaProvider = inject(FIELD_META_PROVIDER_TOKEN);
  sourceProvider = inject(FORM_SOURCE_TOKEN);

  @Output() close = new EventEmitter<void>();

  @Output() save = new EventEmitter<any>();

  @Input()
  status$!: Observable<CollectionMutationStatus>;

  groupMeta: FieldMetaGroupsFlat = [];

  form = injectGeneratedForm(this.settings.columnMetaGroups);

  injector = inject(EnvironmentInjector);

  getColValue(dataItem: any, col: string): any {
    return path(col.split('.'), dataItem);
  }

  getEntityDescription(ent?: any) {
    const { entityDescription } = this.settings;
    return typeof entityDescription === 'string'
      ? entityDescription
      : entityDescription(ent);
  }

  ngOnInit() {
    const value = this.sourceProvider.getSource();
    this.groupMeta = this.metaProvider.getFlatGroups(value);
    if (value) {
      this.form.setValue(value);
    } else {
      this.form.reset();
    }

    runInInjectionContext(this.injector, () => {
      formHandleMutationStatus(this.status$, this.form, () => {
        this.close.emit();
      });
    });
  }

  saveButtonStatus = saveButtonStatus;
}
