import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { RoutingActions } from './routing.actions';

@Injectable()
export class RoutingEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  navigateRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoutingActions.navigate),
        tap((action) => {
          return this.router.navigate(action.path);
        }),
      ),
    { dispatch: false },
  );

  navigateLoginRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoutingActions.navigateToLogin),
        tap((action) => {
          return this.router.navigate(['/login'], {
            queryParams: action.returnPath
              ? {
                  returnPath: action.returnPath,
                }
              : undefined,
          });
        }),
      ),
    { dispatch: false },
  );

  returnFromLoginRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoutingActions.returnFromLogin),
        tap(() => {
          const returnPath =
            this.router.routerState.snapshot.root.queryParams?.['returnPath'];
          return this.router.navigate([returnPath || '/']);
        }),
      ),
    { dispatch: false },
  );
}
