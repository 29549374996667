import { EnumList } from "@/common";

import { User } from "./user.entity";

export enum EntitesAuditAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export const ENTITES_AUDIT_ACTION_LIST: EnumList = [
  {
    title: 'Create',
    value: EntitesAuditAction.CREATE,
  },
  {
    title: 'Update',
    value: EntitesAuditAction.UPDATE,
  },
  {
    title: 'Delete',
    value: EntitesAuditAction.DELETE,
  },
];

export interface EntitiesAuditEntity {
  id: string;
  action: EntitesAuditAction;
  createdBy: User | null;
  createdByUsername: string;
  createdTime: number;
  entityName: string;
  entityId: string;
  snapshot: object | null;
  diff: object | null;
}
