import { Component, Input } from '@angular/core';

import { memoizeWith } from 'ramda';

import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-date-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <app-date-control-wrapper
        [formControl]="control()"
        [inputFormat]="inputFormat"
      >
        <kendo-datepicker
          [placeholder]="getTitle()"
          format="MMM dd, yyyy"
          [max]="max!"
          [min]="min!"
          [focusedDate]="focusedDate()"
          #field
        ></kendo-datepicker>
      </app-date-control-wrapper>
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
})
export class DateFieldComponent extends BaseFieldComponent {
  @Input()
  inputFormat: 'datestring' | 'string' | 'number' | 'date' = 'datestring';

  @Input()
  max?: Date;

  @Input()
  min?: Date;

  currentDate = new Date();

  convertDate = memoizeWith(String, (value: any) => {
    return value ? new Date(value) : this.currentDate;
  });

  focusedDate() {
    const dateValue = this.convertDate(this.control().value);

    if (this.max && Number(this.max) < Number(dateValue)) {
      return this.max;
    }

    if (this.min && Number(this.min) > Number(dateValue)) {
      return this.min;
    }

    return dateValue;
  }
}
