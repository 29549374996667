import { Component, ContentChild, Input } from '@angular/core';

import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';

import { format } from 'date-fns';

@Component({
  selector: 'app-date-control-wrapper',
  template: ` <ng-content /> `,
  styleUrls: ['./styles.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateControlWrapperComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateControlWrapperComponent,
      multi: true,
    },
  ],
})
export class DateControlWrapperComponent
  implements ControlValueAccessor, Validator
{
  @ContentChild(DatePickerComponent, { static: true })
  inner!: DatePickerComponent;

  @Input()
  inputFormat: 'datestring' | 'string' | 'number' | 'date' = 'datestring';

  onTouchedFn: any;

  onBlur() {
    this.onTouchedFn?.();
  }

  writeValue(obj: any) {
    if (obj) {
      let value: any;
      switch (this.inputFormat) {
        case 'datestring':
        case 'string':
        case 'number':
          value = new Date(obj);
          break;

        default:
          value = obj;
      }

      this.inner.writeValue(value);
    } else {
      this.inner.writeValue(obj);
    }
  }

  registerOnChange(fn: any) {
    this.inner.registerOnChange((date: Date | null) => {
      if (date) {
        let value: any;

        switch (this.inputFormat) {
          case 'string':
            value = date.toISOString();
            break;

          case 'datestring':
            value = format(date, 'yyyy-MM-dd');
            break;

          case 'number':
            value = date.getTime();
            break;

          default:
            value = date;
        }

        fn(value);
      } else {
        fn(date);
      }
    });
  }

  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
    this.inner.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean) {
    this.inner.setDisabledState(isDisabled);
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return this.inner.validate(control);
  }
  registerOnValidatorChange?(fn: () => void): void {
    this.inner.registerOnValidatorChange(fn);
  }
}
