import { Component, Input, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

import { map } from 'rxjs/operators';

import { LookupColumnData } from '../field-meta-provider';
@Component({
  selector: 'app-lookup-dropdown-control',
  template: `
    <kendo-combobox
      #inner
      [data]="(getData() | async) ?? []"
      [allowCustom]="false"
      [popupSettings]="{ width: 'auto' }"
      [clearButton]="true"
      textField="description"
      valueField="id"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      [valuePrimitive]="valuePrimitive"
    >
    </kendo-combobox>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LookupDropdownControlComponent,
    },
  ],
})
export class LookupDropdownControlComponent {
  @ViewChild(ComboBoxComponent, { static: true }) inner!: ComboBoxComponent;

  @Input()
  placeholder = '';

  @Input()
  valuePrimitive = true;

  @Input()
  lookupData!: LookupColumnData;

  onTouchedFn: any;

  getData() {
    return this.lookupData.pipe(map((r) => r.data ?? []));
  }

  onBlur() {
    this.onTouchedFn?.();
  }

  writeValue(obj: any) {
    this.inner.writeValue(obj);
  }
  registerOnChange(fn: any) {
    this.inner.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
    this.inner.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean) {
    this.inner.setDisabledState?.(isDisabled);
  }
}
