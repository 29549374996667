import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Signal,
  inject,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  moreHorizontalIcon,
  pencilIcon,
  trashIcon,
  saveIcon,
  cancelIcon,
} from '@progress/kendo-svg-icons';

import {
  UiSettingsSlice,
  selectGridCurrentColumnProfileName,
  selectGridColumnProfiles,
  selectGridFilterProfiles,
  UiSettingsActions,
  selectCurrentFilterProfileName,
} from '@/common/store';

@Component({
  selector: 'app-grid-profiles',
  templateUrl: './template.html',
  styleUrls: ['./styles.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridProfilesComponent implements OnInit {
  icons = {
    moreHorizontalIcon,
    pencilIcon,
    trashIcon,
    saveIcon,
    cancelIcon,
  };

  @Input()
  gridName = '';

  @Input()
  profileType: 'columns' | 'filter' = 'columns';

  store = inject(Store<UiSettingsSlice>);

  profiles: Signal<{ name: string }[]> = signal([]);
  currentProfile: Signal<string> = signal('');

  showRemoveDialog = signal(false);
  showCloneDialog = signal(false);
  showRenameDialog = signal(false);

  newProfileName = '';

  ngOnInit() {
    if (this.profileType === 'columns') {
      this.profiles = this.store.selectSignal(
        selectGridColumnProfiles(this.gridName),
      );
      this.currentProfile = this.store.selectSignal(
        selectGridCurrentColumnProfileName(this.gridName),
      );
    } else {
      this.profiles = this.store.selectSignal(
        selectGridFilterProfiles(this.gridName),
      );
      this.currentProfile = this.store.selectSignal(
        selectCurrentFilterProfileName(this.gridName),
      );
    }
  }

  changeProfile(newProfileName: string) {
    const action =
      this.profileType === 'columns'
        ? UiSettingsActions.setCurrentColumnsProfile({
            grid: this.gridName,
            profileName: newProfileName,
          })
        : UiSettingsActions.setCurrentFilterProfile({
            grid: this.gridName,
            profileName: newProfileName,
          });

    this.store.dispatch(action);
  }

  onCloneClick() {
    this.newProfileName = this.currentProfile() + ' copy';
    this.showCloneDialog.set(true);
  }

  onRemoveClick() {
    this.showRemoveDialog.set(true);
  }

  closeRemoveDialog() {
    this.showRemoveDialog.set(false);
  }

  commitRemoveDialog() {
    this.closeRemoveDialog();

    const action =
      this.profileType === 'columns'
        ? UiSettingsActions.removeCurrentColumnsProfile({
            grid: this.gridName,
          })
        : UiSettingsActions.removeCurrentFilterProfile({
            grid: this.gridName,
          });

    this.store.dispatch(action);
  }

  closeCloneDialog() {
    this.newProfileName = '';
    this.showCloneDialog.set(false);
  }

  commitCloneDialog() {
    const newProfileName = this.newProfileName;
    this.closeCloneDialog();

    const params = {
      grid: this.gridName,
      newProfileName,
    };

    const action =
      this.profileType === 'columns'
        ? UiSettingsActions.cloneCurrentColumnsProfile(params)
        : UiSettingsActions.cloneCurrentFilterProfile(params);

    this.store.dispatch(action);
  }

  onRenameClick() {
    this.newProfileName = this.currentProfile();
    this.showRenameDialog.set(true);
  }

  closeRenameDialog() {
    this.newProfileName = '';
    this.showRenameDialog.set(false);
  }

  commitRenameDialog() {
    const newProfileName = this.newProfileName;
    this.closeRenameDialog();

    const params = {
      grid: this.gridName,
      newProfileName,
    };

    const action =
      this.profileType === 'columns'
        ? UiSettingsActions.renameCurrentColumnsProfile(params)
        : UiSettingsActions.renameCurrentFilterProfile(params);

    this.store.dispatch(action);
  }
}
