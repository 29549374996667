import { ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isObjectLike } from 'ramda-adjunct';
import { mergeDeepRight } from 'ramda';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { 
  ChangeDetectionStrategy, Component, 
  Input, NgZone, OnInit, TemplateRef, ViewChild, forwardRef, inject, signal, computed
} from "@angular/core";

import { EditEvent, GridComponent } from "@progress/kendo-angular-grid";

import { EntitiesAuditQueryService } from "@/common/services";
import { injectQueryParams } from "@/common/grid-helpers";
import { ENTITES_AUDIT_ACTION_LIST, EntitiesAuditEntity } from "@/api";


import { ENTITY_GRID_SETTINGS_TOKEN, ViewDialogProps } from "../entity-grid-common";

import { FIELD_META_PROVIDER_TOKEN } from "../form/field-meta-provider";
import { FORM_SOURCE_TOKEN, FormCompareSourceProvider } from '../form';

@Component({
  selector: 'app-entity-grid-audit',
  templateUrl: './template.html',
  styleUrl: './styles.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: FORM_SOURCE_TOKEN,
      useExisting: forwardRef(() => EntityGridAuditComponent),
    },
  ],
})
export class EntityGridAuditComponent implements OnInit, FormCompareSourceProvider {
  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);
  fieldProvider = inject(FIELD_META_PROVIDER_TOKEN);

  entityName = this.settings.entityName;

  auditQueryService = inject(EntitiesAuditQueryService);
  queryParams = injectQueryParams({ gridName: this.entityName });

  ngZone = inject(NgZone);
  
  @ViewChild('grid') grid!: GridComponent;
  
  @Input()
  entityId!: string;

  @Input()
  viewDialogTemplate?: TemplateRef<ViewDialogProps>;

  entityId$ = new ReplaySubject<string>(1);

  result$ = this.auditQueryService.injectEntities({
    entityName: this.entityName,
    id$: this.entityId$,
    paramsSignal: this.queryParams.params
  });

  content$ = this.result$.pipe(map(r => r.data?.content));
  loading$ = this.result$.pipe(map(r => r.isFetching));

  ////
  viewDataItem = signal<EntitiesAuditEntity | undefined>(undefined);

  compareSource = computed(() => {
    const item = this.viewDataItem();
    if (item) {
      const { diff, snapshot } = item;
      return diff && snapshot ? mergeDeepRight(snapshot, diff) : snapshot;
    }
    return undefined;
  });

  constructor() {
    this.content$.pipe(takeUntilDestroyed()).subscribe((data) => {
      if (data) {
        this.fitColumns();
      }
    });
  }

  ngOnInit() {
    this.entityId$.next(this.entityId);
  }

  fitColumns() {
    this.ngZone.onStable
      .asObservable()
      .pipe(take(1))
      .subscribe(() => {
        this.grid.autoFitColumns();
      });
  }

  changedFields(dataItem: EntitiesAuditEntity) {
    const exceptions = [
      'common.updatedTime'
    ];

    const diffKeys = dataItem.diff ? Object.entries(dataItem.diff).flatMap(
      ([key, value]) => {
        const title = this.fieldProvider.getMeta(key).title;
        if (title) {
          return key;
        } else if (isObjectLike(value)) {
          return Object.keys(value).map(k => key + '.' + k);
        } else {
          return [];
        }
      }
    ).filter(key => !exceptions.includes(key)) : [];

    const getMetaTitle = (k: string) => this.fieldProvider.getMeta(k).title;

    const diff = dataItem.diff ? diffKeys.map(getMetaTitle).sort((k1, k2) => k1.localeCompare(k2)) : [];
    return diff;
  }

  actionColumn(dataItem: EntitiesAuditEntity) {
    return ENTITES_AUDIT_ACTION_LIST.find(item => dataItem.action === item.value)?.title 
      ?? dataItem.action;
  }

  showViewDialog() {
    return Boolean(this.viewDataItem());
  }

  closeViewDialog = () => {
    this.viewDataItem.set(undefined);
  }

  editHandler(args: EditEvent) {
    const ent = args.dataItem as EntitiesAuditEntity;
    this.viewDataItem.set(ent);
  }

  getCompareSource() {    
    return this.compareSource();
  }

  getSource() {
    return this.viewDataItem()?.snapshot;
  }
 }