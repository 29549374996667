import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { combineLatest } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';

import { BaseControlComponent, ActivateFormData } from './base-control';

@Component({
  selector: 'app-block-approved-control',
  host: { class: 'k-checkbox-wrap' },
  templateUrl: './template.html',
  styleUrls: ['./styles.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BlockApprovedControlComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockApprovedControlComponent extends BaseControlComponent {
  unsub = this.form$
    .pipe(
      takeUntilDestroyed(),
      filter((form): form is FormGroup<ActivateFormData> => Boolean(form)),
      switchMap((form) => {
        const controls = form.controls;
        const { isClosed } = controls;

        const isClosed$ = isClosed.valueChanges.pipe(startWith(isClosed.value));
        return combineLatest([isClosed$]);
      }),
      map(([isClosed]) => ({ isClosed })),
    )
    .subscribe(({ isClosed }) => {
      const disable = isClosed;

      this.disabledInternal.set(disable);

      this.disabledTitle.set(
        isClosed ? `Can't block closed ${this.entityName}` : '',
      );

      if (disable && this.onChangeFn && this.onTouchedFn) {
        this.value.set(false);
        this.onChangeFn(false);
        this.onTouchedFn(true);
      }
    });
}
