<div class="root">
  <form [formGroup]="loginForm" class="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline">
      <mat-label>User id or email</mat-label>
      <input matInput placeholder="Placeholder" formControlName="login" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        placeholder="Placeholder"
        formControlName="password"
        type="password"
      />
    </mat-form-field>

    <button
      mat-raised-button
      [class]="{ 'login-button': true, loading: loading() }"
      [disabled]="loginForm.invalid || loading()"
    >
      <span *ngIf="!loading()">Sign in</span>
      <span *ngIf="loading()">Signing in...</span>

      <mat-progress-spinner
        [diameter]="20"
        mode="indeterminate"
        *ngIf="loading()"
      >
      </mat-progress-spinner>
    </button>

    <mat-error
      *ngIf="authErrorMessage() && !loginForm.dirty"
      class="auth-error-message"
    >
      {{ authErrorMessage() }}
    </mat-error>
  </form>
</div>
