import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AuthLoginData, RefreshTokenData, ApiError, User } from '@/api';

import { LoginResponse } from '../services/auth.service';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    Logout: emptyProps(),

    Login: props<AuthLoginData>(),

    LoginSuccess: props<LoginResponse>(),

    LoginFailed: props<ApiError>(),

    RefreshAccessToken: props<RefreshTokenData>(),

    UpdateCurrentUser: props<{ user: User }>(),
  },
});
