import { ValidatorFn, Validators } from '@angular/forms';
import { mapValues } from 'remeda';

export const validatorWithMessage =
  (valdator: ValidatorFn) => (message: string | Record<string, string>) => {
    const result: ValidatorFn = (control) => {
      const errors = valdator(control);
      return errors
        ? mapValues(errors, (_, key) =>
          typeof message === 'string' ? message : message[key],
        )
        : errors;
    };
    return result;
  };

export const numberValidator: ValidatorFn = control => {
  if (isNaN(control?.value)) {
    return {
      number: true
    }
  }
  return null;
}

export const integerValidator: ValidatorFn = control => {
  const value = Number(control?.value);
  if (isNaN(value) || value !== Math.trunc(value)) {
    return {
      number: true
    }
  }
  return null;
}


export const requiredValidatorMsg = validatorWithMessage(Validators.required);
export const emailValidatorMsg = validatorWithMessage(Validators.email);
export const numberValidatorMsg = validatorWithMessage(numberValidator);
export const integerValidatorMsg = validatorWithMessage(integerValidator);
