import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-date-column-control',
  template: `{{ value | date }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateColumnControlComponent {
  @Input()
  value!: Date | number;
}
