<kendo-grid #grid *rxLet="entities$; let entities;" [loading]="entities.isLoading || (isUpdating$ | async) ?? false"
  [data]="entities.data?.content ?? []" (edit)="editHelpers.editHandler($event)"
  (cancel)="editHelpers.cancelHandler($event)" (save)="editHelpers.saveHandler($event)"
  (remove)="editHelpers.removeHandler($event)" (add)="editHelpers.addHandler($event)" [resizable]="true"
  [sortable]="true" [filterable]="true" [pageable]="true" [pageSize]="params().take" [skip]="params().skip"
  [sort]="params().sort" [filter]="params().filter" [formGroup]="form" (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)" (filterChange)="filterChange($event)">
  <ng-template kendoGridToolbarTemplate *appHasPermission="permission">
    <button kendoGridAddCommand>Add new</button>
  </ng-template>

  <kendo-grid-column *ngFor="let col of columns" [field]="col.field" [title]="col.title">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <kendo-textbox [formControl]="editHelpers.controls()[col.field]" [title]="editHelpers.firstError(col.field)"
        [name]="col.field" kendoTooltip></kendo-textbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="" [width]="50" *appHasPermission="permission">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <app-grid-commands />
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<app-remove-dialog *ngIf="editHelpers.showRemoveDialog()" [entityName]="entityDescription || entityName"
  (cancel)="editHelpers.cancelRemoveDialog()" (commit)="editHelpers.commitRemoveDialog()" />