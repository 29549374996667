import { Signal, computed, inject, signal } from '@angular/core';

import { PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  FilterOperator,
  SortDescriptor,
} from '@progress/kendo-data-query';

import { Store } from '@ngrx/store';

import { GridQueryParams, isFilterDescriptor, normalizeFilter } from '../utils';
import {
  UiSettingsActions,
  selectCurrentFilter,
  selectGridSort,
} from '../store';
import { defaultFilterProfile } from '../store/grids.state';
import { FieldMetaProvider } from '../components/form/field-meta-provider';

export function injectQueryParams(args: {
  metaProvider?: FieldMetaProvider,
  gridName?: string;
  initialSort?: SortDescriptor[];
}) {
  const { gridName, initialSort, metaProvider } = args;

  const store = inject(Store);

  const localSortSignal = signal<SortDescriptor[]>(initialSort ?? []);
  const localFilterSignal = signal<CompositeFilterDescriptor>(
    defaultFilterProfile.filter,
  );

  ///
  const skipSignal = signal(0);
  const takeSignal = signal(10);

  const sortSignal = gridName
    ? store.selectSignal(selectGridSort(gridName))
    : localSortSignal;

  const querySortParam = computed(() => {
    const sort = sortSignal();
    if (metaProvider) {
      return sort.map(s => {
        const { field } = s;
        const meta = metaProvider.getMeta(field);
        if (meta.compositeType === 'simple' && meta.type === 'lookup') {
          const sortField = meta.descriptionField;
          return {
            ...s,
            field: field + '.' + sortField
          };
        }
        return s;
      });
    }
    else {
      return sort;
    }
  });

  const filterSignal = gridName
    ? store.selectSignal(selectCurrentFilter(gridName))
    : localFilterSignal;

  ///
  const pageChange = ($event: PageChangeEvent) => {
    skipSignal.set($event.skip);
    takeSignal.set($event.take);
  };

  const sortChange = ($event: SortDescriptor[]) => {
    //HACK: grid does not removes SortDescriptor if sort is canceled (third click), it only removed dir field
    const eventSort = $event.filter((e) => e.dir);

    if (gridName) {
      store.dispatch(
        UiSettingsActions.sort({
          grid: gridName,
          sort: eventSort,
        }),
      );
    } else {
      localSortSignal.set(eventSort);
    }
  };

  const filterChange = ($event: CompositeFilterDescriptor) => {
    if (gridName) {
      store.dispatch(
        UiSettingsActions.updateCurrentFilter({
          grid: gridName,
          filter: $event,
        }),
      );
    } else {
      localFilterSignal.set($event);
    }
  };

  const setFilterItem = (field: string, value: unknown | null | undefined) => {
    const item: FilterDescriptor | null =
      value !== null && value !== undefined
        ? {
            value,
            field,
            operator: FilterOperator.EqualTo,
          }
        : null;

    const filter: CompositeFilterDescriptor = filterSignal() ?? {
      logic: 'and',
      filters: [],
    };

    const { filters } = filter;
    const idx = filters.findIndex(
      (f) => isFilterDescriptor(f) && f.field === field,
    );

    if (item !== null) {
      const newFilter: CompositeFilterDescriptor =
        idx === -1
          ? {
              ...filter,
              filters: [...filters, item],
            }
          : {
              ...filter,
              filters: [
                ...filters.slice(0, idx),
                item,
                ...filters.slice(idx + 1),
              ],
            };

      filterChange(newFilter);
    } else {
      const newFilter: CompositeFilterDescriptor = {
        ...filter,
        filters: filters.filter(
          (f) => !isFilterDescriptor(f) || f.field !== field,
        ),
      };

      filterChange(newFilter);
    }
  };

  const computedParams = computed<GridQueryParams>(() => ({
    skip: skipSignal(),
    take: takeSignal(),
    filter: filterSignal(),
    sort: sortSignal(),
  }));

  
  const queryParams = computed<GridQueryParams>(() => ({
    ...computedParams(),
    sort: querySortParam(),
  }));

  return {
    params: computedParams,
    queryParams: queryParams,
    pageChange,
    sortChange,
    filterChange,
    setFilterItem,
  };
}

function normalizeSort(sort: SortDescriptor[]): SortDescriptor[] {
  const idxDotRe = /\.\d+/g;
  return sort.map((s) => ({
    ...s,
    field: s.field.replace(idxDotRe, ''),
  }));
}

export function normalizeGridQueryParams(
  paramsSignal: Signal<GridQueryParams>,
) {
  return computed(() => {
    const params = paramsSignal();
    return {
      ...params,
      sort: params.sort !== undefined ? normalizeSort(params.sort) : undefined,
      filter:
        params.filter !== undefined
          ? normalizeFilter(params.filter)
          : undefined,
    };
  });
}
