import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { authFeature } from './store';
import { AuthEffects, AuthService } from './services';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(authFeature),
    EffectsModule.forFeature(AuthEffects),
  ],
  providers: [AuthService],
})
export class AuthModule {}
