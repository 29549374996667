import { EnumList } from '@/common';

export enum ResidentType {
  BANKS = 'BANKS',
  INVESTMENT_FUNDS = 'INVESTMENT_FUNDS',
  PENSION_FUNDS = 'PENSION_FUNDS',
  INSURANCE_COMPANIES = 'INSURANCE_COMPANIES',
  OTHER_FINANCIAL_INTERMEDIARIES = 'OTHER_FINANCIAL_INTERMEDIARIES',
  NON_FINANCIAL_CORPORATIONS = 'NON_FINANCIAL_CORPORATIONS',
  PHYSICAL_PERSONS = 'PHYSICAL_PERSONS',
}

export const RESIDENT_TYPE_LIST: EnumList = [
  {
    title: 'Banks',
    value: ResidentType.BANKS,
  },
  {
    title: 'Investment funds',
    value: ResidentType.INVESTMENT_FUNDS,
  },
  {
    title: 'Pension funds',
    value: ResidentType.PENSION_FUNDS,
  },
  {
    title: 'Insurance companies',
    value: ResidentType.INSURANCE_COMPANIES,
  },
  {
    title: 'Other financial intermediaries',
    value: ResidentType.OTHER_FINANCIAL_INTERMEDIARIES,
  },
  {
    title: 'Non financial corporations',
    value: ResidentType.NON_FINANCIAL_CORPORATIONS,
  },
  {
    title: 'Physical persons',
    value: ResidentType.PHYSICAL_PERSONS,
  },
];
