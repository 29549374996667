import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function disableControl(control: AbstractControl) {
  if (!control.disabled) {
    control.disable({
      emitEvent: false,
    });
  }
  if (control.value !== null) {
    control.setValue(null);
  }
}

export function enableControl(control: AbstractControl) {
  if (!control.enabled) {
    control.enable({
      emitEvent: false,
    });
    control.markAsTouched();
  }
}

export function disableForm(form: FormGroup) {
  if (form.enabled) {
    form.disable({
      emitEvent: false,
    });

    Object.values(form.controls).forEach((control) => {
      if (control instanceof FormArray) {
        control.disable({
          emitEvent: false,
        });
      }
    });
  }
}

export function enableForm(form: FormGroup) {
  if (form.disabled) {
    form.enable({
      emitEvent: false,
    });

    Object.values(form.controls).forEach((control) => {
      if (control instanceof FormArray) {
        control.enable({
          emitEvent: false,
        });
      }
    });
  }
}
