import { EnumList } from '@/common';

export enum LicenseType {
  BANK_OTHER_CREDIT = 'BANK_OTHER_CREDIT',
  INVESTMENT_FIRM = 'INVESTMENT_FIRM',
  OTHER_AUTHORIZED_REGULATED_FINANCIAL = 'OTHER_AUTHORIZED_REGULATED_FINANCIAL',
  INSURANCE_COMPANY = 'INSURANCE_COMPANY',
  COLLECTIVE_INVESTMENT = 'COLLECTIVE_INVESTMENT',
  PENSION_FUND_OR_MANAGEMENT_COMPANY = 'PENSION_FUND_OR_MANAGEMENT_COMPANY',
  COMMODITY_DEALER = 'COMMODITY_DEALER',
  OTHER = 'OTHER',
}

export const LICENSE_TYPE_LIST: EnumList = [
  {
    title: 'Bank or other Credit Institution',
    value: LicenseType.BANK_OTHER_CREDIT,
  },
  {
    title: 'Investment Firm',
    value: LicenseType.INVESTMENT_FIRM,
  },
  {
    title: 'Other authorized or regulated financial institution',
    value: LicenseType.OTHER_AUTHORIZED_REGULATED_FINANCIAL,
  },
  {
    title: 'Insurance Company',
    value: LicenseType.INSURANCE_COMPANY,
  },
  {
    title: 'Collective investment scheme and/or management company of such',
    value: LicenseType.COLLECTIVE_INVESTMENT,
  },
  {
    title: 'Pension fund and/or management company of such fund',
    value: LicenseType.PENSION_FUND_OR_MANAGEMENT_COMPANY,
  },
  {
    title: 'Commodity/commodity derivatives dealer',
    value: LicenseType.COMMODITY_DEALER,
  },
  {
    title: 'Other',
    value: LicenseType.OTHER,
  },
];
