import { Observable, of } from 'rxjs';
import { omit, compose } from 'ramda';

import { QueryObserverResult } from '@tanstack/query-core';

import { InjectionToken, TemplateRef } from '@angular/core';
import { FilterDescriptor } from '@progress/kendo-data-query';

import { BaseEntity } from '@/api';
import { injectBaseEntityQueryService } from '@/common/services';

import { EnumList } from '../../utils';
import { ValidatorFn } from '@angular/forms';

export type LookupColumnData = Observable<QueryObserverResult<BaseEntity[]>>;

export const emptyLookupColumnData = of({
  status: 'success',
  data: [] as BaseEntity[],
} as QueryObserverResult<BaseEntity[]>);

export type FieldPresenceType = 'gridColumns' | 'draftColumns' | 'gridFilters' | 'viewCard' | 'sort' | 'full';

export type CustomFilterRenderContext = {
  filter: FilterDescriptor;
  meta: FieldMeta;
  column: string;
};


export type CustomViewRenderContext = {
  value: unknown;
  meta: FieldMeta;
  column: string;
};

export type CustomFieldTemplates = {
  filterTemplate?: TemplateRef<CustomFilterRenderContext>;
  viewTemplate?: TemplateRef<CustomViewRenderContext>;
};

export interface FieldMetaCommon {
  title: string;
  titlePrefixed: string;
  presence: FieldPresenceType[];
  required?: boolean;
  customTemplates?: CustomFieldTemplates;
  validators?: ValidatorFn[];
}

export interface FieldMetaSimpleCommon extends FieldMetaCommon {
  compositeType: 'simple';
  type: 'bool' | 'date' | 'number';
}

export interface FieldMetaSimpleText extends FieldMetaCommon {
  compositeType: 'simple';
  type: 'text';
  textType: 'text' | 'drl';
}

export type FieldMetaSimple = FieldMetaSimpleCommon | FieldMetaSimpleText;

export interface FieldMetaEnum extends FieldMetaCommon {
  compositeType: 'simple';
  type: 'enum';
  enumList: EnumList;
}

export interface FieldMetaLookup extends FieldMetaCommon {
  compositeType: 'simple';
  type: 'lookup';
  descriptionField: string;
  valueField: string;
  lookupData: LookupColumnData;
}

export interface FieldMetaObject extends FieldMetaCommon {
  compositeType: 'object';
  type: 'composite';
  shortPrefix: string;
  longPrefix: string;
  fields: Record<string, FieldMetaTerminal | FieldMetaObject>;
}

export interface FieldMetaArray extends FieldMetaCommon {
  compositeType: 'array';
  type: 'composite';
  prefixStartIndex: number;
  elementMeta: FieldMetaObject;
}

export type FieldMetaTerminal = FieldMetaSimple | FieldMetaEnum | FieldMetaLookup;

export type FieldMetaComposite = FieldMetaArray | FieldMetaObject;

export type FieldMeta = FieldMetaTerminal | FieldMetaComposite;


export type FieldMetaGroupSingle = [field: string, fieldMeta: FieldMetaComposite];

export type FieldMetaGroupComposite = {
  [fieldName: string]: FieldMetaTerminal | FieldMetaObject
};

export type FieldMetaGroup = FieldMetaGroupSingle | FieldMetaGroupComposite;

export interface FieldMetaGroups {
  [groupName: string]: FieldMetaGroup;
}

export type FieldMetaGroupsFlat = [group: string, metas: ColumnMeta[]][];

export interface FieldMetaProvider {
  //get meta for deep path: column.subColumn.1.subColumn: for terminal element to display a value by path
  getMeta(path: string): FieldMeta;

  getFlatGroups(value: any): FieldMetaGroupsFlat;
}

export type ColumnMeta = [field: string, meta: FieldMeta];

export const FIELD_META_PROVIDER_TOKEN = new InjectionToken<FieldMetaProvider>(
  'FIELD_META_PROVIDER_TOKEN',
);

export function isFieldMetaGroupSingle(fieldMetaGroup: FieldMetaGroup): fieldMetaGroup is FieldMetaGroupSingle {
  return Array.isArray(fieldMetaGroup);
}

export function isFieldMetaGroupComposite(fieldMetaGroup: FieldMetaGroupComposite): fieldMetaGroup is FieldMetaGroupComposite {
  return !isFieldMetaGroupSingle(fieldMetaGroup);
}

export function isFieldMetaLookup(fieldMeta: FieldMeta): fieldMeta is FieldMetaLookup {
  return fieldMeta.compositeType === 'simple' && fieldMeta.type === 'lookup';
}

export function isFieldMetaTerminal(fieldMeta: FieldMeta): fieldMeta is FieldMetaTerminal {
  return fieldMeta.compositeType === 'simple';
}

export function simpleMeta(title: string, type: FieldMetaSimple['type'] = 'text'): FieldMetaSimple {
  const common = {
    compositeType: 'simple' as const,
    presence: ['gridColumns', 'gridFilters', 'viewCard', 'full', 'sort'] as FieldPresenceType[],
    title,
    titlePrefixed: title,
  };

  if (type === 'text') {
    return {
      ...common,
      type,
      textType: 'text'
    };
  } else {
    return {
      ...common,
      type
    };
  }
}

export function simpleMetaText(title: string, textType: FieldMetaSimpleText['textType'] = 'text'): FieldMetaSimpleText {
  const common = simpleMeta(title, 'text') as FieldMetaSimpleText;

  return {
    ...common,
    textType
  };
}

export function lookupMeta(title: string, lookupEntity: string | LookupColumnData): FieldMetaLookup {
  return {
    compositeType: 'simple',
    type: 'lookup',
    title,
    titlePrefixed: title,
    descriptionField: 'description',
    valueField: 'id',
    presence: ['gridColumns', 'gridFilters', 'viewCard', 'full', 'sort'],
    lookupData: typeof lookupEntity === 'string'
      ? injectBaseEntityQueryService<BaseEntity>({
        entityName: lookupEntity,
      }).injectAllEntities()
      : lookupEntity
  };
}

export function omitPresence<T extends FieldMetaCommon>(meta: T, omit: FieldPresenceType[]): T {
  return {
    ...meta,
    presence: meta.presence.filter(p => !omit.includes(p))
  }
}

export function enumMeta(title: string, enumList: EnumList): FieldMetaEnum {
  return {
    compositeType: 'simple',
    type: 'enum',
    enumList,
    title,
    titlePrefixed: title,
    presence: ['gridColumns', 'gridFilters', 'viewCard', 'full', 'sort'],
  };
}

export const objectMeta = (fields: FieldMetaObject['fields']) => (title: string, shortPrefix: string = ''): FieldMetaObject => {
  return {
    compositeType: 'object',
    type: 'composite',
    shortPrefix,
    longPrefix: '',
    fields,
    presence: ['gridColumns', 'gridFilters', 'viewCard', 'full', 'sort'],
    title,
    titlePrefixed: title,
  };
};

export const arrayMeta = (elementMeta: FieldMetaObject, prefixStartIndex = 0): FieldMetaArray => {
  return {
    compositeType: 'array',
    type: 'composite',
    elementMeta,
    presence: ['viewCard', 'full'],
    title: '',
    titlePrefixed: '',
    prefixStartIndex
  };
};

export const withDraft = <T extends FieldMetaCommon>(meta: T): T => ({
  ...meta,
  presence: meta.presence.concat('draftColumns')
});

export const required = <T extends FieldMetaCommon>(meta: T): T => ({
  ...meta,
  required: true
});

export const withDraftF = <Args extends any[], R extends FieldMetaCommon>(fn: (...args: Args) => R) => compose(withDraft, fn);

export const requiredF = <Args extends any[], R extends FieldMetaCommon>(fn: (...args: Args) => R) => compose(required, fn);

export const withoutFull = <T extends FieldMetaCommon>(meta: T): T => ({
  ...meta,
  presence: meta.presence.filter(p => p !== 'full')
});

export const withValidators = <T extends FieldMetaCommon>(meta: T, validators: ValidatorFn[]): T => ({
  ...meta,
  validators
});

const sm = simpleMeta;
const om = objectMeta;
const smD = compose(withDraft, sm);
const omD = compose(withDraftF, objectMeta);

const approvedBy = 'Approved by';
const activatedBy = 'Activated by';
const blockedBy = 'Blocked by';
const createdBy = 'Created by';
const closedBy = 'Closed by';

export const COMMON_META_FIELDS = {
  'isApproved': sm('Approved', 'bool'),
  'approvedBy': sm(approvedBy),
  'approvedTime': sm('Approved time', 'date'),

  'isActive': sm('Active', 'bool'),
  'activatedBy': sm(activatedBy),
  'activatedTime': sm('Activated time', 'date'),

  'isBlocked': sm('Blocked', 'bool'),
  'blockedBy': sm(blockedBy),
  'blockedTime': sm('Blocked time', 'date'),

  'createdBy': smD(createdBy),
  'createdTime': smD('Created time', 'date'),

  'updatedBy': sm('Updated by'),
  'updatedTime': sm('Updated time', 'date'),

  'isClosed': smD('Closed', 'bool'),
  'closedBy': smD(closedBy),
  'closedTime': smD('Closed time', 'date'),
  'closureReason': smD('Closure reason'),

  'extraNotes': sm('Extra notes'),
};

export const APPROVE_ACTIVATE_SECTION = {
  'Approval and activation': [
    'common',
    omD(omit(['extraNotes'], COMMON_META_FIELDS))('')
  ] as FieldMetaGroupSingle
};

export const FIELD_META_DEFAULT: FieldMetaSimple = {
  compositeType: 'simple',
  presence: ['gridColumns'],
  title: '',
  titlePrefixed: '',
  type: 'text',
  textType: 'text'
};