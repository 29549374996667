import { FormGroup } from "@angular/forms";

export function saveButtonStatus(form: FormGroup, checkPristine = true, prefix?: string) {
  const pristine = checkPristine && form.pristine;
  const disabled = form.invalid || pristine || form.pending;
  if (disabled) {
    const prefixDef = 'Saving is disabled: ';

    const title =
      (prefix ?? prefixDef) +
      (form.pristine
        ? 'form has not changed'
        : form.invalid
          ? 'form has errors. Check all required form fields.'
          : form.pending ?
            'form is saving'
            : '');

    return {
      disabled,
      title,
    };
  } else {
    return {
      disabled: false,
      title: '',
    };
  }
}