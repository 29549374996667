import { format } from 'date-fns';
import { lastValueFrom, take } from 'rxjs';

import { FieldMeta, FieldMetaLookup } from "./field-meta-provider";

export const formatEnumValue = (meta: FieldMeta) => (value: unknown) => {
  const enumList = meta.type === 'enum' ? meta.enumList : [];

  const valueArray = Array.isArray(value) ? value : [value];

  return valueArray
    .map((v) => {
      const title = enumList.find((item) => item.value === v)?.title;
      return title ?? v;
    })
    .join(', ');
}

export const formatLookupValue = async (meta: FieldMetaLookup) => {
  const lookupResult = await lastValueFrom(meta.lookupData.pipe(take(1)));
  return (value: unknown) => {
    const data = lookupResult.data ?? [];
    const idValue = value;
    const idValues = Array.isArray(idValue) ? idValue : [idValue];
    const descrValues = idValues.map(
      idValue => {
        const valueData: any = idValue ? data.find((d: any) => d[meta.valueField] === idValue) : null;

        return valueData ? valueData[meta.descriptionField] : null;
      }
    ).join(', ');

    return descrValues;
  }
}

export const formatDateShort = (nullFormat: string | null = null) => (
  date: string | number | Date | null
) => {
  return date !== null ? format(new Date(date), 'yyyy-MM-dd') : nullFormat;
};

export const formatBoolValue = (value: unknown) =>
  value == null ? '' : value ? 'true' : 'false';
