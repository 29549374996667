import 'brace';
import 'brace/mode/text';
import 'brace/mode/drools';
import 'brace/theme/github';

import { Component, Input } from '@angular/core';

import { BaseFieldComponent } from '../base-field';

import { DEF_CONFIG } from '../ace-textarea-control';

@Component({
  selector: 'app-ace-text-area-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>
      <app-ace-text-area-control 
        [formControl]="control()" 
        [mode]="mode" 
        [theme]="theme" 
        [config]="config"
        [errors]="errors()" 
        #field 
      />
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `
})
export class AceTextareaFieldComponent extends BaseFieldComponent {
  @Input()
  mode: string = 'text';

  @Input()
  theme: string = 'github';

  @Input()
  config = DEF_CONFIG;
}
