import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';

import { path } from 'ramda';

import { ENTITY_GRID_SETTINGS_TOKEN } from '../entity-grid-common';
import {
  FIELD_META_PROVIDER_TOKEN,
  FieldMetaGroupsFlat,
} from '../form/field-meta-provider';
import { FORM_SOURCE_TOKEN } from '../form';

@Component({
  selector: 'app-entity-view-dialog',
  template: `
    <kendo-dialog
      title="View"
      (close)="close.emit()"
      autoFocusedElement="#cancel-button"
      class="history-dialog"
    >
      <div class="k-form-horizontal content">
        @for (meta of groupMeta; track meta[0]) {
        <kendo-expansionpanel [subtitle]="meta[0]" [expanded]="true">
          <div class="section">
            @for (colMeta of meta[1]; track colMeta[0]) {
            <app-value-view-field [path]="colMeta[0]" />
            }
          </div>
        </kendo-expansionpanel>
        }
      </div>
      <kendo-dialog-actions>
        <button kendoButton id="cancel-button" (click)="close.emit()">
          Cancel
        </button>
      </kendo-dialog-actions>
    </kendo-dialog>
  `,
  styles: [
    `
      .content
        max-height: 70vh
        width: 700px
        overflow: auto

      .section
        display: flex
        flex-direction: column
        gap: 8px
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityViewDialogComponent implements OnInit {
  settings = inject(ENTITY_GRID_SETTINGS_TOKEN);
  metaProvider = inject(FIELD_META_PROVIDER_TOKEN);
  sourceProvider = inject(FORM_SOURCE_TOKEN);

  @Output() close = new EventEmitter<void>();

  groupMeta: FieldMetaGroupsFlat = [];

  getColValue(dataItem: any, col: string): any {
    return path(col.split('.'), dataItem);
  }

  getEntityDescription(ent?: any) {
    const { entityDescription } = this.settings;
    return typeof entityDescription === 'string'
      ? entityDescription
      : entityDescription(ent);
  }

  ngOnInit() {
    const value = this.sourceProvider.getSource();
    this.groupMeta = this.metaProvider.getFlatGroups(value);
  }
}
