export enum Permission {
  USERS_VIEW = 'USERS_VIEW',
  USERS_EDIT = 'USERS_EDIT',

  USER_ROLES_VIEW = 'USER_ROLES_VIEW',
  USER_ROLES_EDIT = 'USER_ROLES_EDIT',

  ADMINISTRATION_STATIC_VIEW = 'ADMINISTRATION_STATIC_VIEW',
  ADMINISTRATION_STATIC_EDIT = 'ADMINISTRATION_STATIC_EDIT',

  CRM_CLIENTS_VIEW = 'CRM_CLIENTS_VIEW',
  CRM_CLIENTS_EDIT = 'CRM_CLIENTS_EDIT',

  CRM_LEGAL_ENTITIES_VIEW = 'CRM_LEGAL_ENTITIES_VIEW',
  CRM_LEGAL_ENTITIES_EDIT = 'CRM_LEGAL_ENTITIES_EDIT',

  CRM_STATIC_VIEW = 'CRM_STATIC_VIEW',
  CRM_STATIC_EDIT = 'CRM_STATIC_EDIT',

  CRM_COUNTERPARTY_BANKS_VIEW = 'CRM_COUNTERPARTY_BANKS_VIEW',
  CRM_COUNTERPARTY_BANKS_EDIT = 'CRM_COUNTERPARTY_BANKS_EDIT',

  CRM_COUNTERPARTY_STATIC_VIEW = 'CRM_COUNTERPARTY_STATIC_VIEW',
  CRM_COUNTERPARTY_STATIC_EDIT = 'CRM_COUNTERPARTY_STATIC_EDIT',

  ACC_GROUPINGS_VIEW = 'ACC_GROUPINGS_VIEW',
  ACC_GROUPINGS_EDIT = 'ACC_GROUPINGS_EDIT',

  ACC_PORTFOLIOS_ACCOUNTS_VIEW = 'ACC_PORTFOLIOS_ACCOUNTS_VIEW',
  ACC_PORTFOLIOS_ACCOUNTS_EDIT = 'ACC_PORTFOLIOS_ACCOUNTS_EDIT',

  ACC_ISSUERS_VIEW = 'ACC_ISSUERS_VIEW',
  ACC_ISSUERS_EDIT = 'ACC_ISSUERS_EDIT',

  ACC_INSTRUMENTS_VIEW = 'ACC_INSTRUMENTS_VIEW',
  ACC_INSTRUMENTS_EDIT = 'ACC_INSTRUMENTS_EDIT',

  TRADING_STATIC_VIEW = 'TRADING_STATIC_VIEW',
  TRADING_STATIC_EDIT = 'TRADING_STATIC_EDIT',


  TRADING_FEE_TYPES_VIEW = 'TRADING_FEE_TYPES_VIEW',
  TRADING_FEE_TYPES_EDIT = 'TRADING_FEE_TYPES_EDIT',

  TRADING_TRANSACTION_FEES_VIEW = 'TRADING_TRANSACTION_FEES_VIEW',
  TRADING_TRANSACTION_FEES_EDIT = 'TRADING_TRANSACTION_FEES_EDIT',

  TRADING_NON_TRANSACTION_FEES_VIEW = 'TRADING_NON_TRANSACTION_FEES_VIEW',
  TRADING_NON_TRANSACTION_FEES_EDIT = 'TRADING_NON_TRANSACTION_FEES_EDIT',

  TRADING_CHARGES_VIEW = 'TRADING_CHARGES_VIEW',
  TRADING_CHARGES_EDIT = 'TRADING_CHARGES_EDIT',

  TRADING_MARKET_DATA_VIEW = 'TRADING_MARKET_DATA_VIEW',
  TRADING_MARKET_DATA_EDIT = 'TRADING_MARKET_DATA_EDIT',

  TRADING_TRADE_INPUT_VIEW = 'TRADING_TRADE_INPUT_VIEW',
  TRADING_TRADE_INPUT_EDIT = 'TRADING_TRADE_INPUT_EDIT',
}

export const TRADING_PERMISSION_ANY = [
  Permission.TRADING_STATIC_VIEW,
  Permission.TRADING_STATIC_EDIT,

  Permission.TRADING_FEE_TYPES_VIEW,
  Permission.TRADING_FEE_TYPES_EDIT,

  Permission.TRADING_TRANSACTION_FEES_VIEW,
  Permission.TRADING_TRANSACTION_FEES_EDIT,

  Permission.TRADING_NON_TRANSACTION_FEES_VIEW,
  Permission.TRADING_NON_TRANSACTION_FEES_EDIT,

  Permission.TRADING_CHARGES_VIEW,
  Permission.TRADING_CHARGES_EDIT,

  Permission.TRADING_MARKET_DATA_VIEW,
  Permission.TRADING_MARKET_DATA_EDIT,

  Permission.TRADING_TRADE_INPUT_VIEW,
  Permission.TRADING_TRADE_INPUT_EDIT,
];

export interface UserRole {
  id: string;
  name: string;
  permissions: Permission[];
  readonly: boolean;
}

export interface DeletingBoundUserRoleError {
  messageType: 'user-role.deleting.bound-error';
  userRole: UserRole;
}

export interface UserRoleReadonlyError {
  messageType: 'user-role.readonly';
  userRole: UserRole;
}
