import { ValidatorFn } from "@angular/forms";
import { inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { FieldMetaGroups } from "../components/form";
import {
  isFieldMetaGroupComposite,
  isFieldMetaGroupSingle,
  isFieldMetaTerminal
} from "../components/form/field-meta-provider";
import { requiredValidatorMsg, numberValidatorMsg } from "../utils";

export function injectGeneratedForm(meta: FieldMetaGroups) {
  const groups = Object.values(meta);
  const formFields = groups.flatMap(group => {
    if (isFieldMetaGroupSingle(group)) {
      return [];
    } else if (isFieldMetaGroupComposite(group)) {
      return Object.entries(group).flatMap(
        ([field, meta]): [string, [any, ValidatorFn[]]][] => {
          const metaValidation = meta.validators ?? [];
          const validation = metaValidation.concat(
            meta.required
              ? [requiredValidatorMsg(meta.title + ' is required')]
              : []
          );
          const numberValidation = numberValidatorMsg(meta.title + " should be number");

          if (isFieldMetaTerminal(meta)) {
            switch (meta.type) {
              case 'text': return [[field, [null, validation]]];
              case 'bool': return [[field, [false, validation]]];
              case 'date': return [[field, [null, validation]]];
              case 'enum': return [[field, [null, validation]]];
              case 'number': return [[field, [null, validation.concat(numberValidation)]]];
              case 'lookup': return [[field, [null, validation]]];
            }
          }
          return [];
        }
      );
    }
    return [];
  });

  const formArgs = formFields.reduce((res, [field, [initial, validators]]) => {
    res[field] = validators ? [initial, validators] : [initial];
    return res;
  }, {} as Record<string, any>);

  formArgs['id'] = [null as string | null];

  const fb = inject(FormBuilder);

  return fb.group(formArgs);
}