import { EnumList } from '@/common';

export enum CrmLeFactaReportingReason {
  FACTA_US_ENTITY_ACCOUNT = 'FACTA_US_ENTITY_ACCOUNT',
  FACTA_PASSIVE_NFE = 'FACTA_PASSIVE_NFE',
}

export const CRM_LE_FACTA_REPORTING_REASON_LIST: EnumList = [
  {
    title: 'US Entity Account',
    value: CrmLeFactaReportingReason.FACTA_US_ENTITY_ACCOUNT,
  },
  {
    title: 'Passive NFFE accounts held by substantial US owner',
    value: CrmLeFactaReportingReason.FACTA_PASSIVE_NFE,
  },
];
