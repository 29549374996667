import { inject } from '@angular/core';
import { CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap, map } from 'rxjs/operators';

import { RoutingActions } from '@/routing';

import { Permission } from '@/api';

import {
  selectCurrentPermissions,
  selectIsAuthorized,
} from '../store/auth.selectors';
import { AuthSlice } from '../store/auth.state';

export const canActivateAuthorized: CanActivateFn = (
  _,
  state: RouterStateSnapshot,
) => {
  const store = inject(Store<AuthSlice>);

  return store.select(selectIsAuthorized).pipe(
    tap((isAuthorized) => {
      const returnPath = state.url;
      if (!isAuthorized) {
        store.dispatch(
          RoutingActions.navigateToLogin({
            returnPath,
          }),
        );
      }
    }),
  );
};

type Logic = 'all' | 'any';

export const canActivatePermissions =
  (permissions: Permission[], logic: Logic) =>
  (_: unknown, _state: RouterStateSnapshot) => {
    const store = inject(Store<AuthSlice>);

    const method = logic === 'all' ? 'every' : 'some';
    return store.select(selectCurrentPermissions).pipe(
      map((currentPermissions) =>
        permissions[method]((p) => currentPermissions.includes(p)),
      ),
      tap((result) => {
        if (!result) {
          store.dispatch(
            RoutingActions.navigate({
              path: ['forbidden'],
            }),
          );
        }
      }),
    );
  };
