import { HttpClient } from '@angular/common/http';
import { Signal, inject } from '@angular/core';
import { QueryClientService } from '@ngneat/query';
import {
  Observable,
  combineLatest,
  merge,
  firstValueFrom,
  Subject,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { createPipe } from 'remeda';

import {
  ApiError,
  ApiErrorData,
  Page,
  PageWithDraftsResponse,
  WithEntityStatusCommon,
} from '@/api';

import { addEntity, removeEntity, updateEntity } from './entity-utils';
import {
  findPageContentItem,
  mapPageContent,
  mapPageWithDraftsData,
  mapPageWithDraftsContent,
  mapPageWithDraftsDrafts,
} from './page';
import { normalizeEmptyToNull } from './normalize-empty-to-null';

interface MutationOptions<Entity, Variables = Entity, Context = unknown> {
  onSuccess?: (
    data: Entity,
    vars: Variables,
    context: Context | undefined,
  ) => void;
  onMutate?: (variables: Variables) => Context;
  onError?: (
    err: ApiError,
    vars: Variables,
    context: Context | undefined,
  ) => void;
}

export type CollectionMutationStatus<Data = unknown> = {
  state: 'idle';
} | {
  state: 'loading';
} | {
  state: 'error';
  error: ApiErrorData;
} | {
  state: 'result';
  data: Data;
};

export interface CollectionMutation<Entity, Variables = Entity> {
  mutate: (variables: Variables) => Promise<Entity | undefined>;
  result$: Observable<CollectionMutationStatus>;
}

function createMutation<Entity, Variables = Entity, Context = unknown>(
  mutateFn: (value: Variables) => Observable<Entity>,
  options?: MutationOptions<Entity, Variables, Context>,
): CollectionMutation<Entity, Variables> {
  const result$ = new Subject<CollectionMutationStatus>();

  return {
    mutate: async (variables: Variables) => {
      const normalized = normalizeEmptyToNull(variables);

      result$.next({
        state: 'loading'
      });

      const context = options?.onMutate?.(normalized);
      try {

        const value = await firstValueFrom(mutateFn(normalized));
        result$.next({
          state: 'result',
          data: value
        });

        options?.onSuccess?.(value, normalized, context);

        return value;
      } catch (error: any) {
        result$.next({
          state: 'error',
          error: error.error,
        });
        options?.onError?.(error, normalized, context);
      }
      return undefined;
    },
    result$: result$.asObservable(),
  };
}

export interface EntityConstraint {
  id: string;
}

export interface EntityWithApproveConstraint
  extends EntityConstraint,
  WithEntityStatusCommon { }

export interface CollectionMutations<
  Entity extends EntityConstraint,
  AddVariables = Omit<Entity, 'id'>,
  UpdateVariables extends EntityConstraint = Entity,
> {
  add: CollectionMutation<Entity, AddVariables>;
  update: CollectionMutation<Entity, UpdateVariables>;
  remove: CollectionMutation<unknown, Entity>;
  isUpdating$: Observable<boolean>;
  currentStatus$: Observable<CollectionMutationStatus>;
}

export interface DraftedCollectionMutations<
  Entity extends EntityWithApproveConstraint,
  AddVariables = Omit<Entity, 'id'>,
  UpdateVariables extends EntityConstraint = Entity,
> {
  add: CollectionMutation<Entity, AddVariables>;
  update: CollectionMutation<Entity, UpdateVariables>;
  approve: CollectionMutation<unknown, Entity>;
  remove: CollectionMutation<unknown, Entity>;
  isUpdating$: Observable<boolean>;
  currentStatus$: Observable<CollectionMutationStatus>;
}

export function pagedWithDraftsEntityCollectionMutations<
  Entity extends EntityWithApproveConstraint,
  AddVariables = Omit<Entity, 'id'>,
  UpdateVariables extends EntityWithApproveConstraint = Entity,
>(
  baseKey: unknown[],
  url: string,
  params: Signal<unknown>,
): DraftedCollectionMutations<Entity, AddVariables, UpdateVariables> {
  const queryClient = inject(QueryClientService);
  const http = inject(HttpClient);

  const getKey = () => [...baseKey, params()];

  const pageAddDraftContentEntity = createPipe(
    addEntity<Entity>,
    mapPageWithDraftsContent,
  );

  const pageAddDraftDraftsEntity = createPipe(
    addEntity<Entity>,
    mapPageWithDraftsDrafts,
  );

  const pageAddDraft = (ent: Entity) => {
    return ent.approveFor
      ? pageAddDraftDraftsEntity(ent)
      : pageAddDraftContentEntity(ent);
  };

  const pageRemoveEntity = (ent: Entity) => {
    const updater = removeEntity<Entity>(ent);
    return mapPageWithDraftsData(updater, updater);
  };

  const pageMergeEntity = (ent: Entity) => {
    const updater = updateEntity<Entity>(true)(ent);
    return mapPageWithDraftsData(updater, updater);
  };

  const pageSetEntity = (ent: Entity) => {
    const updater = updateEntity<Entity>(false)(ent);
    return mapPageWithDraftsData(updater, updater);
  };

  const add = createMutation<Entity, AddVariables>(
    (ent) => http.post<Entity>(url, ent),
    {
      onSuccess: (data: Entity) => {
        queryClient.setQueryData<PageWithDraftsResponse<Entity>>(
          getKey(),
          pageAddDraft(data),
        );
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown) => {
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const update = createMutation<Entity, UpdateVariables>(
    (ent) => http.put<Entity>(url + ent.id, ent),
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData<
          PageWithDraftsResponse<Entity>
        >(getKey());

        queryClient.setQueryData<PageWithDraftsResponse<Entity>>(
          getKey(),
          pageMergeEntity(variable as unknown as Entity),
        );

        return prevData;
      },
      onSuccess: (data: Entity) => {
        queryClient.setQueryData<PageWithDraftsResponse<Entity>>(
          getKey(),
          pageSetEntity(data),
        );
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, prevData: any) => {
        if (prevData) {
          queryClient.setQueryData<PageWithDraftsResponse<Entity>>(
            getKey(),
            prevData,
          );
        }
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const approve = createMutation<unknown, Entity>(
    (ent) => {
      return http.put(url + ent.id + '/approve', ent);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, context: any) => {
        queryClient.setQueryData(getKey(), context);
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const remove = createMutation<unknown, Entity>(
    (ent) => {
      return http.delete(url + ent.id);
    },
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData(getKey());

        queryClient.setQueriesData(getKey(), pageRemoveEntity(variable));

        return prevData;
      },
      onSuccess() {
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, context: any) => {
        queryClient.setQueryData(getKey(), context);
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const isUpdating$ = combineLatest([
    add.result$,
    update.result$,
    approve.result$,
    remove.result$,
  ]).pipe(map((values) => values.some((v) => v.state === 'loading')));

  const currentStatus$ = merge(add.result$, update.result$, approve.result$, remove.result$);

  return {
    add,
    update,
    approve,
    remove,
    isUpdating$,
    currentStatus$,
  };
}

export function pagedEntityCollectionMutations<
  Entity extends EntityConstraint,
  AddVariables = Omit<Entity, 'id'>,
  UpdateVariables extends EntityConstraint = Entity,
>(
  baseKey: unknown[],
  url: string,
  params: Signal<unknown>,
): CollectionMutations<Entity, AddVariables, UpdateVariables> {
  const queryClient = inject(QueryClientService);
  const http = inject(HttpClient);

  const getKey = () => [...baseKey, params()];

  const pageAddEntity = createPipe(addEntity<Entity>, mapPageContent);
  const pageRemoveEntity = createPipe(removeEntity<Entity>, mapPageContent);
  const pageMergeEntity = createPipe(
    updateEntity<Entity>(true),
    mapPageContent,
  );
  const pageSetEntity = createPipe(updateEntity<Entity>(false), mapPageContent);

  const add = createMutation<Entity, AddVariables>(
    (ent) => http.post<Entity>(url, ent),
    {
      onSuccess: (data: Entity) => {
        queryClient.setQueryData<Page<Entity[]>>(getKey(), pageAddEntity(data));
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown) => {
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const update = createMutation<Entity, UpdateVariables>(
    (ent) => http.put<Entity>(url + ent.id, ent),
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData<Page<Entity[]>>(getKey());

        const prevEntity = prevData
          ? findPageContentItem<Entity>((item) => item.id === variable.id)
          : undefined;

        queryClient.setQueryData<Page<Entity[]>>(
          getKey(),
          pageMergeEntity(variable as unknown as Entity),
        );

        return prevEntity;
      },
      onSuccess: (data: Entity) => {
        queryClient.setQueryData<Page<Entity[]>>(getKey(), pageSetEntity(data));
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, prevEntity: any) => {
        if (prevEntity) {
          queryClient.setQueryData<Page<Entity[]>>(
            getKey(),
            pageSetEntity(prevEntity),
          );
        }
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const remove = createMutation<unknown, Entity>(
    (ent) => {
      return http.delete(url + ent.id);
    },
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData(getKey());

        queryClient.setQueriesData(getKey(), pageRemoveEntity(variable));

        return prevData;
      },
      onSuccess() {
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, context: any) => {
        queryClient.setQueryData(getKey(), context);
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const isUpdating$ = combineLatest([
    add.result$,
    update.result$,
    remove.result$,
  ]).pipe(map((values) => values.some((v) => v.state === 'loading')));

  const currentStatus$ = merge(add.result$, update.result$, remove.result$);

  return {
    add,
    update,
    remove,
    isUpdating$,
    currentStatus$
  };
}

export function entityCollectionMutations<
  Entity extends EntityConstraint,
  AddVariables = Omit<Entity, 'id'>,
  UpdateVariables extends EntityConstraint = Entity,
>(
  baseKey: unknown[],
  url: string,
  params?: Signal<unknown>,
): CollectionMutations<Entity, AddVariables, UpdateVariables> {
  const queryClient = inject(QueryClientService);
  const http = inject(HttpClient);

  const getKey = () => (params ? [...baseKey, params()] : baseKey);

  const wrapWithUndefined =
    <T>(itemsFn: (v: T) => (items: Entity[]) => Entity[]) =>
      (v: T) =>
        (items: Entity[] | undefined) =>
          items ? itemsFn(v)(items) : items;

  const wrappedAddEntity = wrapWithUndefined(addEntity<Entity>);
  const wrappedRemoveEntity = wrapWithUndefined(removeEntity<Entity>);
  const wrappedMergeEntity = wrapWithUndefined(updateEntity<Entity>(true));
  const wrappedSetEntity = wrapWithUndefined(updateEntity<Entity>(false));

  const add = createMutation<Entity, AddVariables>(
    (ent) => http.post<Entity>(url, ent),
    {
      onSuccess: (data: Entity) => {
        queryClient.setQueryData(getKey(), wrappedAddEntity(data));
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown) => {
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const update = createMutation<Entity, UpdateVariables>(
    (ent) => http.put<Entity>(url + ent.id, ent),
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData<Page<Entity[]>>(getKey());

        const prevEntity = prevData
          ? findPageContentItem<Entity>((item) => item.id === variable.id)
          : undefined;

        queryClient.setQueryData<Entity[]>(
          getKey(),
          wrappedMergeEntity(variable as unknown as Entity),
        );

        return prevEntity;
      },
      onSuccess: (data: Entity) => {
        queryClient.setQueryData<Entity[]>(getKey(), wrappedSetEntity(data));
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, prevEntity: any) => {
        if (prevEntity) {
          queryClient.setQueryData<Entity[]>(
            getKey(),
            wrappedSetEntity(prevEntity),
          );
        }
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const remove = createMutation<unknown, Entity>(
    (ent) => http.delete(url + ent.id),
    {
      onMutate: (variable) => {
        const prevData = queryClient.getQueryData(getKey());

        queryClient.setQueriesData(getKey(), wrappedRemoveEntity(variable));

        return prevData;
      },
      onSuccess() {
        queryClient.invalidateQueries(baseKey);
      },
      onError: (_err: unknown, _vars: unknown, context: any) => {
        queryClient.setQueryData(getKey(), context);
        queryClient.invalidateQueries(baseKey);
      },
    },
  );

  const isUpdating$ = combineLatest([
    add.result$,
    update.result$,
    remove.result$,
  ]).pipe(map((values) => values.some((v) => v.state === 'loading')));

  const currentStatus$ = merge(add.result$, update.result$, remove.result$);

  return {
    add,
    update,
    remove,
    isUpdating$,
    currentStatus$
  };
}
