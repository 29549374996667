import { FormArray, FormGroup, FormGroupDirective } from "@angular/forms";

export function getFieldFullPath(field: string, nestedFormDirective: FormGroupDirective) {
  const path = field.split('.');

  let control: FormGroup | FormArray | null = nestedFormDirective.control;
  while (control) {
    const parent: FormGroup | FormArray | null = control.parent;
    if (parent) {
      if (parent instanceof FormGroup) {
        const field = Object.keys(parent.controls).find(
          (k) => parent.controls[k] === control,
        );
        if (field) {
          path.unshift(field);
        } else {
          break;
        }
      } else if (parent instanceof FormArray) {
        const index = parent.controls.indexOf(control);
        if (index !== -1) {
          path.unshift(String(index));
        } else {
          break;
        }
      }
    }
    control = parent;
  }
  return path;
}

export function getFieldFullPathStr(field: string, nestedFormDirective: FormGroupDirective) {

  return getFieldFullPath(field, nestedFormDirective).join('.');
}