import { createSelector } from '@ngrx/store';

import {
  UiSettingsSlice,
  UiSettingsState,
  uiSettingsFeatureKey,
} from './ui-settings.state';
import {
  defaultColumnsProfile,
  defaultFilterProfile,
  initialGridState,
} from './grids.state';

export const selectUiSettingsState = (feature: UiSettingsSlice) =>
  feature[uiSettingsFeatureKey];

export const selectGridsStateFromUiState = (state: UiSettingsState) =>
  state.grids;

export const selectGridStateFromUiState = (gridName: string) =>
  createSelector(
    selectGridsStateFromUiState,
    (grids) => grids.data[gridName] ?? initialGridState,
  );

export const selectGridCurrentColumnProfileNameFromUiState = (
  gridName: string,
) =>
  createSelector(
    selectGridStateFromUiState(gridName),
    (gridState) => gridState.currentColumnProfile,
  );

export const selectGridColumnProfilesFromUiState = (gridName: string) =>
  createSelector(
    selectGridStateFromUiState(gridName),
    (gridState) => gridState.columnProfiles,
  );

export const selectGridCurrentColumnProfileFromUiState = (gridName: string) =>
  createSelector(
    selectGridStateFromUiState(gridName),
    (gridState) =>
      gridState.columnProfiles.find(
        (p) => p.name === gridState.currentColumnProfile,
      ) ?? {
        ...defaultColumnsProfile,
        name: gridState.currentColumnProfile,
      },
  );

export const selectCurrentColumnProfileSettingsFromUiState = (
  gridName: string,
) =>
  createSelector(
    selectGridCurrentColumnProfileFromUiState(gridName),
    (profileState) => profileState.settings,
  );

////

export const selectGridState = (gridName: string) =>
  createSelector(selectUiSettingsState, selectGridStateFromUiState(gridName));

export const selectCurrentColumnProfileSettings = (gridName: string) =>
  createSelector(
    selectUiSettingsState,
    selectCurrentColumnProfileSettingsFromUiState(gridName),
  );

export const selectGridColumnSettings = (gridName: string) =>
  createSelector(
    selectCurrentColumnProfileSettings(gridName),
    (state) => state?.columnSettings ?? {},
  );

export const selectGridColumns = (gridName: string) =>
  createSelector(
    selectCurrentColumnProfileSettings(gridName),
    (state) => state.columns,
  );

export const selectGridSort = (gridName: string) =>
  createSelector(
    selectCurrentColumnProfileSettings(gridName),
    (state) => state.sort ?? [],
  );

export const selectGridColumnProfiles = (gridName: string) =>
  createSelector(
    selectUiSettingsState,
    selectGridColumnProfilesFromUiState(gridName),
  );

export const selectGridCurrentColumnProfileName = (gridName: string) =>
  createSelector(
    selectUiSettingsState,
    selectGridCurrentColumnProfileNameFromUiState(gridName),
  );

export const selectGridCurrentFilterProfile = (gridName: string) =>
  createSelector(
    selectGridState(gridName),
    (gridState) =>
      gridState.filterProfiles.find(
        (p) => p.name === gridState.currentFilterProfile,
      ) ?? {
        filter: defaultFilterProfile.filter,
        name: gridState.currentColumnProfile,
      },
  );

export const selectCurrentFilter = (gridName: string) =>
  createSelector(
    selectGridCurrentFilterProfile(gridName),
    (state) => state.filter,
  );

export const selectCurrentFilterProfileName = (gridName: string) =>
  createSelector(
    selectGridCurrentFilterProfile(gridName),
    (state) => state.name,
  );

export const selectGridFilterProfiles = (gridName: string) =>
  createSelector(selectGridState(gridName), (state) => state.filterProfiles);
