<div class="layout-authorized" *ngIf="isAuthorizedLayout()">
  <kendo-appbar position="top">
    <kendo-appbar-section>
      <div kendoTypography fontSize="xl">Nebula</div>
    </kendo-appbar-section>

    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      <button
        #userMenu
        kendoButton
        fillMode="flat"
        rounded="full"
        class="user-menu-button"
        (click)="userMenuOpen = !userMenuOpen">
        <kendo-avatar [initials]="currentUser()" />
      </button>
      <kendo-popup
        *ngIf="userMenuOpen"
        (clickOutside)="userMenuOpen = false"
        [delayClickOutsideInit]="true"
        [anchor]="userMenu.element"
        [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"
        [popupAlign]="{ horizontal: 'right', vertical: 'top' }">
        <div class="user-menu-content">
          <div class="user-menu-user">{{ currentUser() }}</div>
          <button kendoButton fillMode="flat" (click)="userMenuOpen = false; logout()">Logout</button>
        </div>
      </kendo-popup>
    </kendo-appbar-section>
  </kendo-appbar>

  <div class="content-wrapper">
    <div class="sidebar">
      <mat-accordion class="sidebar-accordion" multi displayMode="flat">
        <mat-expansion-panel *appHasPermission="crmPermissions">
          <mat-expansion-panel-header>
            <mat-panel-title> CRM </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list>
            <a
              *appHasPermission="crmClientPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/crm/individual-clients">
              Individual clients
            </a>
            <a
              *appHasPermission="crmLegalsPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/crm/legal-entities">
              Legal entities
            </a>
            <a
              *appHasPermission="bankPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/crm/counterparty-banks">
              Banks
            </a>
            <mat-accordion multi displayMode="flat">
              <mat-expansion-panel *appHasPermission="crmPermissions" class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title>Settings</mat-panel-title>
                </mat-expansion-panel-header>
                <mat-nav-list>
                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/legal-entity-types">
                    Legal entity types
                  </a>
                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/legal-forms">
                    Legal forms
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/regulatory-categories-ic">
                    Regulatory categories - individual clients
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/regulatory-categories-le">
                    Regulatory categories - legal entities
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/client-groups">
                    Client groups
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/portfolio-groups">
                    Portfolio groups
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/identifier-types">
                    Individual client ID types
                  </a>

                  <a
                    *appHasPermission="crmPermissions"
                    mat-list-item
                    routerLinkActive="mat-list-item-focus"
                    routerLink="/crm/counterparty-locations">
                    Counterparty locations
                  </a>
                </mat-nav-list>
              </mat-expansion-panel>
            </mat-accordion>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appHasPermission="accountingPermissions">
          <mat-expansion-panel-header>
            <mat-panel-title> Accounting </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list role="list">
            <a
              *appHasPermission="accountingPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/accounting/portfolios">
              Portfolios
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appHasPermission="instrumentsPermissions">
          <mat-expansion-panel-header>
            <mat-panel-title> Instruments </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list role="list">
            <a
              *appHasPermission="instrumentsPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/instruments/cash-accounts">
              Cash Accounts
            </a>
            <a
              *appHasPermission="instrumentsPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/instruments/symbols">
              Symbols
            </a>
            <a
              *appHasPermission="instrumentsPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/instruments/asset-class-properties">
              Asset class properties
            </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appHasPermission="tradingPermissions" class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Trading</mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list role="list">
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/trading-rules">
              Trading rules
            </a>
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/trading-rules-test">
              Trading rules test
            </a>
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/trading-data">
              Trading data
            </a>
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/open-positions">
              Open positions
            </a>
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/cash-ledgers">
              Cash ledgers
            </a>
            <a mat-list-item routerLinkActive="mat-list-item-focus" routerLink="/trading/reports"> Reports </a>
          </mat-nav-list>
        </mat-expansion-panel>

        <mat-expansion-panel *appHasPermission="administrationPermissions">
          <mat-expansion-panel-header>
            <mat-panel-title> Administration </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-nav-list role="list">
            <a
              *appHasPermission="administrationStaticPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/viewing-entities">
              Viewing entities
            </a>
            <a
              *appHasPermission="[Permission.USERS_VIEW, Permission.USERS_EDIT]"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/users">
              Users
            </a>
            <a
              *appHasPermission="[Permission.USER_ROLES_VIEW, Permission.USER_ROLES_EDIT]"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/user-roles">
              User roles
            </a>
            <a
              *appHasPermission="administrationStaticPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/currencies">
              Currencies
            </a>
            <a
              *appHasPermission="administrationStaticPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/regions">
              Regions
            </a>
            <a
              *appHasPermission="administrationStaticPermissions"
              mat-list-item
              routerLinkActive="mat-list-item-focus"
              routerLink="/administration/countries">
              Countries
            </a>
          </mat-nav-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="content-authorized">
      <div class="outlet-authorized">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class="layout-unauthorized" *ngIf="!isAuthorizedLayout()">
  <mat-toolbar>
    <span>Nebula</span>
  </mat-toolbar>

  <div class="content-unauthorized">
    <router-outlet></router-outlet>
  </div>
</div>
