import { createFeature, createReducer, on } from '@ngrx/store';
import { mapValues } from 'remeda';
import { mergeDeepRight } from 'ramda';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { UiSettingsService } from './ui-settings.service';

import {
  initialGridsState,
  initialGridState,
  ColumnSettings,
  GridColumnSettings,
  GridState,
  defaultColumnsProfile,
  initialGridColumnsSettings,
  defaultFilterProfile,
} from './grids.state';
import { UiSettingsState, uiSettingsFeatureKey } from './ui-settings.state';
import { UiSettingsActions } from './ui-settings.actions';

function cloneProfile<T extends { name: string }>(
  profiles: T[],
  profileName: string,
  newProfileName: string,
  defaultProfile: T,
) {
  const currentIdx = profiles.findIndex((p) => p.name === profileName);
  if (currentIdx >= 0) {
    return profiles.flatMap((p) =>
      p.name === profileName
        ? [
            {
              ...p,
              name: newProfileName,
            },
            p,
          ]
        : [p],
    );
  } else {
    return [
      {
        ...defaultProfile,
        name: newProfileName,
      },
      ...profiles,
    ];
  }
}

function removeProfile<T extends { name: string }>(
  profiles: T[],
  profileName: string,
) {
  const currentIdx = profiles.findIndex((p) => p.name === profileName);
  if (currentIdx >= 0 && profiles.length > 1) {
    const newProfiles = profiles.filter((p) => p.name !== profileName);
    const newIdx = currentIdx > 0 ? currentIdx - 1 : currentIdx + 1;
    const newProfileName = profiles[newIdx].name;
    return {
      newProfiles,
      newProfileName,
    };
  } else {
    return undefined;
  }
}

function renameProfile<T extends { name: string }>(
  profiles: T[],
  profileName: string,
  newProfileName: string,
) {
  return profiles.map((p) =>
    p.name === profileName ? { ...p, name: newProfileName } : p,
  );
}

function getInitialSettings(): UiSettingsState {
  const settings = UiSettingsService.getFromLocalStorage();

  return settings
    ? {
        ...settings,
        grids:
          settings.grids.version === initialGridsState.version
            ? settings.grids
            : initialGridsState,
      }
    : {
        grids: initialGridsState,
      };
}
const initialState = getInitialSettings();

const updateGridState =
  (gridName: string) =>
  (updater: (state: GridState) => GridState) =>
  (state: UiSettingsState): UiSettingsState => {
    const grids = state.grids;
    const grid = grids.data[gridName] ?? initialGridState;

    return {
      ...state,
      grids: {
        ...grids,
        data: {
          ...grids.data,
          [gridName]: updater(grid),
        },
      },
    };
  };

const updateCurrentColumnProfileSettings =
  (gridName: string) =>
  (updater: (gridColumnSettings: GridColumnSettings) => GridColumnSettings) =>
    updateGridState(gridName)((gridState) => ({
      ...gridState,
      columnProfiles: gridState.columnProfiles.map((p) =>
        p.name === gridState.currentColumnProfile
          ? {
              ...p,
              settings: updater(p.settings),
            }
          : p,
      ),
    }));

const updateCurrentFilterProfileSettings =
  (gridName: string) =>
  (updater: (filter: CompositeFilterDescriptor) => CompositeFilterDescriptor) =>
    updateGridState(gridName)((gridState) => ({
      ...gridState,
      filterProfiles: gridState.filterProfiles.map((p) =>
        p.name === gridState.currentFilterProfile
          ? {
              ...p,
              filter: updater(p.filter),
            }
          : p,
      ),
    }));

const updateGridColumnSetting =
  <K extends keyof GridColumnSettings>(
    setting: K,
    updater: (v: GridColumnSettings[K]) => GridColumnSettings[K],
  ) =>
  (gridSettings: GridColumnSettings): GridColumnSettings => ({
    ...gridSettings,
    [setting]: updater(gridSettings[setting]),
  });

export const uiSettingsFeature = createFeature({
  name: uiSettingsFeatureKey,
  reducer: createReducer<UiSettingsState>(
    initialState,
    on(UiSettingsActions.resizeColumns, (state, { grid, settings }) => {
      const columnUpdater = updateGridColumnSetting('columnSettings', (state) =>
        settings.reduce(
          (newState, col) => ({
            ...newState,
            [col.column]: {
              ...newState[col.column],
              width: col.width,
            },
          }),
          state,
        ),
      );

      return updateCurrentColumnProfileSettings(grid)(columnUpdater)(state);
    }),
    on(UiSettingsActions.setColumnsOrder, (state, { columns, grid }) => {
      const columnsUpdater = updateGridColumnSetting('columns', () => columns);
      return updateCurrentColumnProfileSettings(grid)(columnsUpdater)(state);
    }),
    on(UiSettingsActions.setColumnsVisibility, (state, { columns, grid }) => {
      const newSettings = mapValues(
        columns,
        (visible): ColumnSettings => ({
          visible,
        }),
      );

      const columnsUpdater = updateGridColumnSetting(
        'columnSettings',
        (columnSettings) => mergeDeepRight(columnSettings, newSettings),
      );

      return updateCurrentColumnProfileSettings(grid)(columnsUpdater)(state);
    }),
    on(UiSettingsActions.sort, (state, { grid, sort }) => {
      const sortUpdater = updateGridColumnSetting('sort', () => sort);
      return updateCurrentColumnProfileSettings(grid)(sortUpdater)(state);
    }),
    on(
      UiSettingsActions.cloneCurrentColumnsProfile,
      (state, { grid, newProfileName }) => {
        const updater = updateGridState(grid)(({
          currentColumnProfile,
          columnProfiles,
          ...rest
        }): GridState => {
          const newProfiles = cloneProfile(
            columnProfiles,
            currentColumnProfile,
            newProfileName,
            defaultColumnsProfile,
          );

          return {
            ...rest,
            columnProfiles: newProfiles,
            currentColumnProfile: newProfileName,
          };
        });

        return updater(state);
      },
    ),
    on(
      UiSettingsActions.renameCurrentColumnsProfile,
      (state, { grid, newProfileName }) => {
        const updater = updateGridState(grid)((state) => ({
          ...state,
          columnProfiles: renameProfile(
            state.columnProfiles,
            state.currentColumnProfile,
            newProfileName,
          ),
          currentColumnProfile: newProfileName,
        }));

        return updater(state);
      },
    ),
    on(UiSettingsActions.removeCurrentColumnsProfile, (state, { grid }) => {
      const updater = updateGridState(grid)(({
        currentColumnProfile,
        columnProfiles,
        ...rest
      }): GridState => {
        const newState = removeProfile(columnProfiles, currentColumnProfile);
        if (newState) {
          return {
            ...rest,
            currentColumnProfile: newState.newProfileName,
            columnProfiles: newState.newProfiles,
          };
        } else {
          return {
            ...rest,
            columnProfiles: initialGridState.columnProfiles,
            currentColumnProfile: initialGridState.currentColumnProfile,
          };
        }
      });

      return updater(state);
    }),
    on(UiSettingsActions.resetCurrentColumnsProfile, (state, { grid }) => {
      const updater = updateCurrentColumnProfileSettings(grid)(
        () => initialGridColumnsSettings,
      );

      return updater(state);
    }),
    on(
      UiSettingsActions.setCurrentColumnsProfile,
      (state, { grid, profileName }) => {
        const updater = updateGridState(grid)((state) => ({
          ...state,
          currentColumnProfile: profileName,
        }));
        return updater(state);
      },
    ),

    /////

    on(
      UiSettingsActions.cloneCurrentFilterProfile,
      (state, { grid, newProfileName }) => {
        const updater = updateGridState(grid)(({
          filterProfiles,
          currentFilterProfile,
          ...rest
        }): GridState => {
          const newProfiles = cloneProfile(
            filterProfiles,
            currentFilterProfile,
            newProfileName,
            defaultFilterProfile,
          );

          return {
            ...rest,
            filterProfiles: newProfiles,
            currentFilterProfile: newProfileName,
          };
        });

        return updater(state);
      },
    ),
    on(
      UiSettingsActions.renameCurrentFilterProfile,
      (state, { grid, newProfileName }) => {
        const updater = updateGridState(grid)((state) => ({
          ...state,
          filterProfiles: renameProfile(
            state.filterProfiles,
            state.currentFilterProfile,
            newProfileName,
          ),
          currentFilterProfile: newProfileName,
        }));

        return updater(state);
      },
    ),
    on(UiSettingsActions.removeCurrentFilterProfile, (state, { grid }) => {
      const updater = updateGridState(grid)(({
        filterProfiles,
        currentFilterProfile,
        ...rest
      }): GridState => {
        const newState = removeProfile(filterProfiles, currentFilterProfile);
        if (newState) {
          return {
            ...rest,
            currentFilterProfile: newState.newProfileName,
            filterProfiles: newState.newProfiles,
          };
        } else {
          return {
            ...rest,
            filterProfiles: initialGridState.filterProfiles,
            currentFilterProfile: initialGridState.currentFilterProfile,
          };
        }
      });

      return updater(state);
    }),

    on(UiSettingsActions.resetCurrentFilterProfile, (state, { grid }) => {
      const updater = updateCurrentFilterProfileSettings(grid)(
        () => defaultFilterProfile.filter,
      );

      return updater(state);
    }),

    on(
      UiSettingsActions.setCurrentFilterProfile,
      (state, { grid, profileName }) => {
        const updater = updateGridState(grid)((state) => ({
          ...state,
          currentFilterProfile: profileName,
        }));
        return updater(state);
      },
    ),

    on(UiSettingsActions.updateCurrentFilter, (state, { grid, filter }) => {
      return updateCurrentFilterProfileSettings(grid)(() => filter)(state);
    }),
  ),
});
