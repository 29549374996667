import { Observable } from "rxjs";
import { FormGroup } from "@angular/forms";

import { isServerValidationError } from "@/api/types/api-error";
import { CollectionMutationStatus } from "@/common/utils";
import { DestroyRef, inject } from "@angular/core";

export function formHandleMutationStatus(status$: Observable<CollectionMutationStatus>, form: FormGroup, successHandler?: () => void) {
  const destroyRef = inject(DestroyRef);

  const sub = status$.subscribe(status => {
    if (status.state === 'loading') {
      form.markAsPending();
    } else if (status.state === 'error') {
      if (isServerValidationError(status.error)) {
        const fieldErrors = status.error.errors.reduce(
          (res, e, i) => e.field ? ({
            ...res,
            [e.field]: {
              ...res[e.field],
              [i]: e.defaultMessage
            }
          }) : res, {} as Record<string, Record<number, string>>
        );

        Object.entries(fieldErrors).forEach(([field, errors]) => {
          const control = form.get(field);
          if (control) {
            control.markAsTouched();
            control.setErrors(errors, {
              emitEvent: true
            });
          }
        });
      }
    } else {
      //clear form "pending" status when mutation if finished
      form.enable();
      if (successHandler) {
        successHandler();
      }
    }
  });

  const destroyUnsub = destroyRef.onDestroy(() => sub.unsubscribe());

  return () => {
    destroyUnsub();
    sub.unsubscribe();
  };
}