import { map } from 'rxjs';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

import { BaseFieldComponent } from '../base-field';
import { LookupColumnData, emptyLookupColumnData, isFieldMetaLookup } from '../field-meta-provider';

@Component({
  selector: 'app-lookup-multiselect-field',
  template: `
    <kendo-formfield orientation="horizontal">
      <kendo-label [for]="field">
        <app-label-change-tooltip [title]="getTitle()" [field]="getField()" />
      </kendo-label>

      <kendo-multiselect
        #field
        [valuePrimitive]="true"
        [formControl]="control()"
        [autofocus]="autofocus ?? false"
        [data]="(getData() | async) ?? []"
        [textField]="getDescriptionField()"
        [valueField]="getValueField()"
        [kendoMultiSelectSummaryTag]="10"
      ></kendo-multiselect>
      <kendo-formerror *ngFor="let err of errors()">
        {{ err }}
      </kendo-formerror>
    </kendo-formfield>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LookupMultiSelectFieldComponent,
    },
  ],
})
export class LookupMultiSelectFieldComponent
  extends BaseFieldComponent
  implements AfterViewInit, OnInit {
  @ViewChild(MultiSelectComponent, { static: false })
  public select!: MultiSelectComponent;

  @Input()
  lookupData?: LookupColumnData;

  @Input()
  valuePrimitive = true;

  metaLookupData: LookupColumnData | undefined;

  emptyLookupColumnData = emptyLookupColumnData;

  getLookupData() {
    return this.metaLookupData ?? this.lookupData ?? emptyLookupColumnData;
  }

  getData() {
    return this.getLookupData().pipe(map((r) => r.data ?? []));
  }

  getDescriptionField() {
    const descriptionField = this.meta && isFieldMetaLookup(this.meta) ? this.meta.descriptionField : null;
    return descriptionField ?? 'title';
  }

  getValueField() {
    const valueField = this.meta && isFieldMetaLookup(this.meta) ? this.meta.valueField : null;
    return valueField ?? 'value';
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.meta) {
      this.metaLookupData = isFieldMetaLookup(this.meta)
        ? this.meta.lookupData
        : undefined;
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.select.focus();
    }
  }
}
