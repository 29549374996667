import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { capitalize } from '@/common';

@Component({
  selector: 'app-create-remove-draft-dialog',
  template: ` <kendo-dialog
    [title]="
      'Create a draft for remove (close) this ' + entityNameUpperCased() + '?'
    "
    (close)="closeDialog()"
    autoFocusedElement="#cancel-button"
  >
    <div class="content k-form-horizontal">
      <kendo-formfield orientation="horizontal">
        <kendo-label [for]="reason" text="Remove (close) reason" />
        <kendo-textarea [(ngModel)]="removeReason" #reason />
      </kendo-formfield>
    </div>
    <kendo-dialog-actions>
      <button kendoButton id="cancel-button" (click)="closeDialog()">
        Cancel
      </button>
      <button kendoButton themeColor="primary" (click)="commitDialog()">
        Create close draft
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateRemoveDraftDialogComponent {
  @Input()
  entityName = '';

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  commit = new EventEmitter<string>();

  removeReason = '';

  closeDialog() {
    this.cancel.emit();
  }

  commitDialog() {
    this.commit.emit(this.removeReason);
  }

  entityNameUpperCased() {
    return capitalize(this.entityName);
  }
}
